/* Theme20(ModernCard)-START */
.themeStart20#wallCardStylePreview .tb_mc_post_in {
    cursor: pointer;
    display: block;
    position: relative;
}

.themeStart20#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    visibility: hidden;
    z-index: 7;
}

.themeStart20#wallCardStylePreview .tb_share_button_,
.themeStart20#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 3px #a9a7a780;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}

.themeStart20#wallCardStylePreview .tb_share_icon_list {
    align-items: center;
    display: flex;
    left: 0;
    opacity: 0;
    padding: 0 3px;
    position: absolute;
    top: 0;
    transition: all .4s ease-in-out;
    z-index: -1;
}

.themeStart20#wallCardStylePreview .tb_share_icon_list svg {
    overflow: hidden;
    vertical-align: middle;
}

.themeStart20#wallCardStylePreview .tb_share_ico__ {
    transition: all .3s ease-in-out;
}

.themeStart20#wallCardStylePreview .tb_mc_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}

.themeStart20#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list {
    opacity: 1;
    transform: rotate(1turn);
}

.themeStart20#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:first-child {
    left: 30px;
}

.themeStart20#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(2) {
    left: 64px;
}

.themeStart20#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(3) {
    left: 98px;
}

.themeStart20#wallCardStylePreview .tb_mc_image_wrap_ {
    padding-bottom: 100%;
    position: relative;
}

.themeStart20#wallCardStylePreview .tb_mc_image_wrap_ img {
    height: 100%;
    margin: 0 !important;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.themeStart20#wallCardStylePreview .tb_mc_image,
.themeStart20#wallCardStylePreview .tb_mc_video {
    display: block;
    height: 100% !important;
    left: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
}

.themeStart20#wallCardStylePreview .tb_mc_author_container,
.themeStart20#wallCardStylePreview .tb_mc_contant_wrapper {
    padding: 10px;
}

#wallCardStylePreview .tb_mc_post_cta {
    display: block;
    margin-bottom: 10px;
    text-align: center;
}

#wallCardStylePreview .tb_mc_post_cta_btn {
    align-items: center;
    border-color: #000000;
    border-radius: 3px;
    display: flex;
    font-family: Inter, sans-serif;
    font-size: 14px;
    justify-content: center;
    line-height: normal;
}

.themeStart20#wallCardStylePreview .tb_mc_content,
.themeStart20#wallCardStylePreview .tb_mc_rating_content {
    margin-bottom: 10px;
    white-space: pre-line;
    word-break: break-word;
}

.themeStart20#wallCardStylePreview .tb_mc_author,
.themeStart20#wallCardStylePreview .tb_mc_author_wrapper {
    align-items: center;
    display: flex;
}

.themeStart20#wallCardStylePreview .tb_mc_author {
    width: calc(100% - 30px);
}

.themeStart20#wallCardStylePreview .tb_mc_author {
    flex-grow: 1;
}

.themeStart20#wallCardStylePreview .tb_mc_author_profile {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 44px;
    margin-right: 10px;
    overflow: hidden;
    width: 44px;
}

.themeStart20#wallCardStylePreview .tb_mc_author_info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
    padding-right: 10px;
    width: calc(100% - 54px);
}

.themeStart20#wallCardStylePreview .tb_mc_authorname {
    color: var(--tb-author-color);
    display: block;
    font-family: var(--tb-author-font);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.themeStart20#wallCardStylePreview .tb_mc_post_info {
    align-items: center;
    display: flex;
    width: 100%;
}

.themeStart20#wallCardStylePreview .tb_mc_time,
.themeStart20#wallCardStylePreview .tb_mc_username {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.5;
    line-height: normal;
    opacity: .8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.themeStart20#wallCardStylePreview .tb_mc_username {
    max-width: 65%;
}

.themeStart20#wallCardStylePreview .tb_mc_seprator {
    border-radius: 50%;
    display: flex;
    flex-shrink: 1;
    height: 4px;
    margin: 0 6px;
    min-width: 4px;
    opacity: .8;
    width: 4px;
}

.themeStart20#wallCardStylePreview .tb_mc_time {
    max-width: 35%;
}

.themeStart20#wallCardStylePreview .tb_mc_social_ {
    font-size: 24px;
    margin: 5px 0;
}

.themeStart20#wallCardStylePreview .tb__icon {
    speak-as: never;
    font-feature-settings: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
    font-family: WebSocialFonts !important;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-transform: none;
}

.themeStart20#wallCardStylePreview .tb__icon,
.themeStart20#wallCardStylePreview .tb__icon div,
.themeStart20#wallCardStylePreview .tb_colr_ico {
    letter-spacing: normal !important;
    line-height: 1;
    overflow-wrap: normal !important;
}

.themeStart20#wallCardStylePreview .tb_social_action__ {
    align-items: stretch;
    border-top: 1px solid #0000001a;
    display: flex;
    justify-content: space-around;
    padding: 6px;
}

.themeStart20#wallCardStylePreview .tb_social_action__list {
    border-right: 1px solid #0000001a;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    max-width: 100%;
    position: relative;
}

.themeStart20#wallCardStylePreview .tb_social_action__ico_wrap {
    align-items: center;
    display: flex;
}

.themeStart20#wallCardStylePreview .tb_social_action_ico__ {
    font-size: 16px;
}

/* Theme20(ModernCard)-END */
/* Theme5(ClassicCard)-START */
.themeStart5#wallCardStylePreview .tb_cc_post_in {
    background-color: var(--tb-bg-color);
    border-radius: var(--tb-border-radius);
    box-shadow: 1px 1px 5px #00000021;
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
}

.themeStart5#wallCardStylePreview .tb_cc_contant_wrapper {
    padding: 10px;
}

.themeStart5#wallCardStylePreview .tb_cc_author,
.themeStart5#wallCardStylePreview .tb_cc_author_wrapper {
    align-items: center;
    display: flex;
}

.themeStart5#wallCardStylePreview .tb_cc_author {
    width: calc(100% - 30px);
}

.themeStart5#wallCardStylePreview .tb_cc_author {
    flex-grow: 1;
}

.themeStart5#wallCardStylePreview .tb_cc_author_profile {
    background-color: #0003;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 44px;
    margin-right: 10px;
    overflow: hidden;
    width: 44px;
}

.themeStart5#wallCardStylePreview .tb_cc_author_info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
    padding-right: 10px;
    width: calc(100% - 54px);
}

.themeStart5#wallCardStylePreview .tb_cc_authorname {
    color: var(--tb-author-color);
    display: block;
    font-family: var(--tb-author-font);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.themeStart5#wallCardStylePreview .tb_cc_post_info {
    align-items: center;
    display: flex;
}

.themeStart5#wallCardStylePreview .tb_cc_time,
.themeStart5#wallCardStylePreview .tb_cc_username {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    opacity: .8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.themeStart5#wallCardStylePreview .tb_cc_username {
    max-width: 65%;
}

.themeStart5#wallCardStylePreview .tb_cc_seprator {
    border-radius: 50%;
    display: flex;
    flex-shrink: 1;
    height: 4px;
    margin: 0 6px;
    min-width: 4px;
    opacity: .8;
    width: 4px;
}

.themeStart5#wallCardStylePreview .tb_cc_time {
    max-width: 35%;
}

.themeStart5#wallCardStylePreview .tb_cc_social_ {
    display: inline-block;
    font-size: 24px;
    height: 24px;
    margin: 6px 0;
}

.themeStart5#wallCardStylePreview .tb__icon,
.themeStart5#wallCardStylePreview .tb__icon div,
.themeStart5#wallCardStylePreview .tb_colr_ico {
    letter-spacing: normal !important;
    line-height: 1;
    overflow-wrap: normal !important;
}

.themeStart5#wallCardStylePreview svg {
    overflow: hidden;
    vertical-align: middle;
}

.themeStart5#wallCardStylePreview .tb_cc_content,
.themeStart5#wallCardStylePreview .tb_cc_rating_content {
    margin-top: 10px;
    white-space: pre-line;
    word-break: break-word;
}

.themeStart5#wallCardStylePreview .tb_cc_post_media_wrapp {
    position: relative;
}

.themeStart5#wallCardStylePreview .tb_cc_image_wrap_,
.themeStart5#wallCardStylePreview .tb_cc_video_wrap_ {
    background-color: #00000008;
    padding-bottom: 100%;
    position: relative;
}

.themeStart5#wallCardStylePreview .tb_cc_image,
.themeStart5#wallCardStylePreview .tb_cc_video {
    display: block;
    height: 100% !important;
    left: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
}

.themeStart5#wallCardStylePreview .tb_cc_image_wrap_ img {
    height: 100%;
    margin: 0 !important;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.themeStart5#wallCardStylePreview .tb_cc_post_actions {
    padding: 10px;
}

.themeStart5#wallCardStylePreview .tb_cc_post_cta {
    display: block;
    margin-bottom: 10px;
    text-align: center;
}

.themeStart5#wallCardStylePreview .tb_cc_social_actions_ {
    align-items: center;
    border-top: 1px solid #0000001a;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 10px;
}

.themeStart5#wallCardStylePreview .tb_cc_social_action__list {
    display: flex;
    justify-content: center;
    position: relative;
}

.themeStart5#wallCardStylePreview .tb_social_action__ico_wrap {
    align-items: center;
    display: flex;
}

.themeStart5#wallCardStylePreview .tb_cc_social_action_ico__ {
    font-size: 14px;
}

.themeStart5#wallCardStylePreview .tb_cc_social_action_counts__ {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
}

.themeStart5#wallCardStylePreview .tb_cc_action_counts_wrap {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 3px;
    position: relative;
    text-decoration: none;
}

.themeStart5#wallCardStylePreview .tb_cc_action_counts {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
}

.themeStart5#wallCardStylePreview .tb_cc_share_wrapper_dropdown {
    bottom: 100%;
    opacity: 0;
    padding-bottom: 5px;
    position: absolute;
    right: 0;
    transform: translateY(5px);
    transition: all .2s ease-in-out;
    visibility: hidden;
    width: 120px;
    z-index: 1;
}

.themeStart5#wallCardStylePreview .tb_cc_share_icon_list {
    background-color: #2b2b2b;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.themeStart5#wallCardStylePreview .tb_cc_share_list_in {
    align-items: center;
    border-bottom: 1px solid #545454;
    display: flex;
    padding: 8px 14px;
    transition: all .3s ease-in-out;
}

.themeStart5#wallCardStylePreview .tb_cc_share_ico__ {
    color: #ccc;
    font-size: 13px;
    margin-right: 5px;
}

.themeStart5#wallCardStylePreview .tb_cc_share_ico_txt {
    color: #ccc;
    font-size: 12px;
}

.themeStart5#wallCardStylePreview .tb_cc_share_icon_list:after {
    background-color: #2b2b2b;
    border-radius: 1px;
    bottom: -5px;
    content: "";
    height: 10px;
    position: absolute;
    right: 12px;
    transform: rotate(45deg);
    width: 10px;
    z-index: 0;
}

.themeStart5#wallCardStylePreview .tb_cc_action_counts_wrap:focus .tb_cc_share_wrapper_dropdown,
.themeStart5#wallCardStylePreview .tb_cc_action_counts_wrap:hover .tb_cc_share_wrapper_dropdown {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}
.themeStart5#wallCardStylePreview .tb_cc_post_cta {
    display: block;
    margin-bottom: 10px;
    text-align: center;
}
.themeStart5#wallCardStylePreview .tb_cc_post_cta_btn {
    align-items: center;
    background-color: var(--tb-cta-bg-color);
    border-color: var(--tb-cta-border-color);
    border-radius: var(--tb-cta-border-radius);
    color: var(--tb-cta-color);
    display: flex;
    font-family: var(--tb-font);
    font-size: 14px;
    font-weight: var(--tb-cta-font-weight);
    justify-content: center;
    line-height: normal;
    min-height: var(--tb-cta-height);
    padding: var(--tb-cta-padding);
    transition: var(--tb-cta-transition);
}
/* Theme5(ClassicCard)-END */
/* Theme19(NewsCard)-START */
.themeStart19#wallCardStylePreview .tb_nc_post_in {
    cursor: pointer;
    display: block;
    position: relative;
}

.themeStart19#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    visibility: hidden;
    z-index: 7;
}

.themeStart19#wallCardStylePreview .tb_nc_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}

/* Theme3(ClassicPhoto)-START */
.themeStart3#wallCardStylePreview .tb_cp_post_in {
    box-shadow: 0 1px 4px #0003;
    cursor: pointer;
    display: block;
    overflow: hidden;
}
.themeStart3#wallCardStylePreview .tb_cp_post_media_wrapper {
    position: relative;
}
.themeStart3#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    visibility: hidden;
    z-index: 7;
}
.themeStart3#wallCardStylePreview .tb_share_button_ {
    color: #2b2b2b;
    left: 0;
    padding-right: 3px;
    position: relative;
    top: 0;
    transition: all .3s ease;
    z-index: 2;
}
.themeStart3#wallCardStylePreview .tb_share_icon_list {
    align-items: center;
    display: flex;
    left: 0;
    opacity: 0;
    padding: 0 3px;
    position: absolute;
    top: 0;
    transition: all .4s ease-in-out;
    z-index: -1;
}
.themeStart3#wallCardStylePreview .tb_share_button_,
.themeStart3#wallCardStylePreview .tb_share_button_ .tb_share_ico__ {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 3px #a9a7a780;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
.themeStart3#wallCardStylePreview .tb_share_ico__ {
    transition: all .3s ease-in-out;
}
.themeStart3#wallCardStylePreview .tb_cp_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}
.themeStart3#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list {
    opacity: 1;
    transform: rotate(1turn);
}
.themeStart3#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:first-child {
    left: 30px;
}
.themeStart3#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(2) {
    left: 64px;
}
.themeStart3#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(3) {
    left: 98px;
}
.themeStart3#wallCardStylePreview .tb_cp_image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain !important;
    margin: 0;
}
.themeStart3#wallCardStylePreview .tb_cp_image,
.themeStart3#wallCardStylePreview .tb_cp_video {
    display: block;
    height: 100% !important;
    left: 0;
    margin: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
}
.themeStart3#wallCardStylePreview .tb_cp_author_wrapper {
    padding: 10px;
}
.themeStart3#wallCardStylePreview .tb_cp_author,
.themeStart3#wallCardStylePreview .tb_cp_author_wrapper {
    align-items: center;
    display: flex;
}
.themeStart3#wallCardStylePreview .tb_cp_author {
    width: calc(100% - 30px);
}
.themeStart3#wallCardStylePreview .tb_cp_author {
    flex-grow: 1;
}
.themeStart3#wallCardStylePreview .tb_cp_author,
.themeStart3#wallCardStylePreview .tb_cp_author_wrapper {
    align-items: center;
    display: flex;
}
.themeStart3#wallCardStylePreview .tb_cp_author_profile {
    background-color: #0003;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 44px;
    margin-right: 10px;
    overflow: hidden;
    width: 44px;
}
.themeStart3#wallCardStylePreview .tb_cp_author_info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
    padding-right: 10px;
    width: calc(100% - 54px);
}
.themeStart3#wallCardStylePreview .tb_cp_authorname {
    display: block;
    font-family: var(--tb-author-font);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    overflow: hidden;
    text-align: left;
    text-align: left !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart3#wallCardStylePreview .tb_cp_post_info {
    align-items: center;
    display: flex;
}
.themeStart3#wallCardStylePreview .tb_cp_time,
.themeStart3#wallCardStylePreview .tb_cp_username {
    font-size: 10px;
    line-height: normal;
    opacity: .8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart3#wallCardStylePreview .tb_cp_username {
    max-width: 65%;
}
.themeStart3#wallCardStylePreview .tb_cp_seprator {
    border-radius: 50%;
    display: flex;
    flex-shrink: 1;
    height: 4px;
    margin: 0 6px;
    min-width: 4px;
    opacity: .8;
    width: 4px;
}
.themeStart3#wallCardStylePreview .tb_cp_time {
    max-width: 35%;
}
.themeStart3#wallCardStylePreview .tb_social_action__ {
    align-items: stretch;
    border-top: 1px solid #0000001a;
    display: flex;
    justify-content: space-around;
    padding: 6px;
}
.themeStart3#wallCardStylePreview .tb_social_action__list {
    border-right: 1px solid #0000001a;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    max-width: 100%;
    position: relative;
}
.themeStart3#wallCardStylePreview .tb_social_action__ico_wrap {
    align-items: center;
    display: flex;
}
.themeStart3#wallCardStylePreview .tb_social_action_ico__ {
    font-size: 16px;
}
.themeStart3#wallCardStylePreview .tb_social_action__list:last-child {
    border-right: none;
}
.themeStart3#wallCardStylePreview .tb_cp_post_cta {
    padding: 0 10px;
    display: block;
    margin-top: 10px;
    text-align: center;
    bottom: 5px;
    position: relative;
    z-index: 9;
}
.themeStart3#wallCardStylePreview .tb_cp_post_cta_btn {
    align-items: center;
    background-color: var(--tb-btn-bg-color);
    border-color: var(--tb-btn-border-color);
    border-radius: var(--tb-btn-border-radius);
    color: var(--tb-btn-color);
    display: flex;
    font-family: var(--tb-font);
    font-size: 14px;
    font-weight: var(--tb-btn-font-weight);
    justify-content: center;
    line-height: normal;
    padding: 12px;
    transition: var(--tb-btn-transition);
}
/* Theme3(ClassicPhoto)-END */
/* Theme4(SquarePhoto)-END */
.themeStart4#wallCardStylePreview.wallCardStylePreview,
.themeStart4#wallCardStylePreview .tb_sp_post_in,
.themeStart4#wallCardStylePreview .tb_sp_post_media_wrapp {
    position: relative;
}
.themeStart4#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    visibility: hidden;
    z-index: 7;
}
.themeStart4#wallCardStylePreview .tb_share_button_ {
    color: #2b2b2b;
    left: 0;
    padding-right: 3px;
    position: relative;
    top: 0;
    transition: all .3s ease;
    z-index: 2;
}
.themeStart4#wallCardStylePreview .tb_share_button_,
.themeStart4#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 3px #a9a7a780;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
.themeStart4#wallCardStylePreview .tb_share_ico__ {
    transition: all .3s ease-in-out;
}
.themeStart4#wallCardStylePreview .tb_sp_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}
.themeStart4#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list {
    opacity: 1;
    transform: rotate(1turn);
}
.themeStart4#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:first-child {
    left: 30px;
}
.themeStart4#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(2) {
    left: 64px;
}
.themeStart4#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(3) {
    left: 98px;
}

.themeStart4#wallCardStylePreview .tb_sp_image,
.themeStart4#wallCardStylePreview .tb_sp_video {
    background-color: #000;
    display: block;
    height: 100% !important;
    left: 0;
    margin: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
}
.themeStart4#wallCardStylePreview #wallCardStylePreview .tb_hs_image,
.themeStart4#wallCardStylePreview #wallCardStylePreview .tb_sp_image {
    position: inherit;
}
.themeStart4#wallCardStylePreview .tb_sp_post_details,
.themeStart4#wallCardStylePreview .tb_sp_post_overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .3s ease, visibility .3s ease;
    visibility: hidden;
}
.themeStart4#wallCardStylePreview .tb_sp_post_details {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 4;
}
.themeStart4#wallCardStylePreview .tb_sp_contant_wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    left: 0;
    margin: 0 auto;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 0;
}
.themeStart4#wallCardStylePreview .tb_sp_content {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: pre-line;
    width: 100%;
    word-break: break-word;
}
.themeStart4#wallCardStylePreview .tb_sp_author_wrapper {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    padding: 10px;
    position: absolute;
    width: 100%;
}
.themeStart4#wallCardStylePreview .tb_sp_author {
    width: calc(100% - 30px);
}
.themeStart4#wallCardStylePreview .tb_sp_author {
    align-items: center;
    display: flex;
    flex-grow: 1;
}
.themeStart4#wallCardStylePreview .tb_sp_author_profile {
    background-color: #0003;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 44px;
    margin-right: 10px;
    overflow: hidden;
    width: 44px;
}
.themeStart4#wallCardStylePreview .tb_sp_author_info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
    padding-right: 10px;
    width: calc(100% - 54px);
}
.themeStart4#wallCardStylePreview .tb_sp_authorname {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart4#wallCardStylePreview .tb_sp_post_info {
    align-items: center;
    display: flex;
}
.themeStart4#wallCardStylePreview .tb_sp_time,
.themeStart4#wallCardStylePreview .tb_sp_username {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    opacity: .8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart4#wallCardStylePreview .tb_sp_username {
    max-width: 65%;
}
.themeStart4#wallCardStylePreview .tb_sp_seprator {
    border-radius: 50%;
    display: flex;
    flex-shrink: 1;
    height: 4px;
    margin: 0 6px;
    min-width: 4px;
    opacity: .8;
    width: 4px;
}
.themeStart4#wallCardStylePreview .tb_sp_time {
    max-width: 35%;
}
.themeStart4#wallCardStylePreview .tb_sp_social_ {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    margin: 15px 0;
    text-align: center;
}
.themeStart4#wallCardStylePreview .tb_hs_image,
.themeStart4#wallCardStylePreview .tb_sp_image {
    position: inherit;
    border-radius: inherit;
}
/* Theme4(SquarePhoto)-END */
/* Theme16(HorizontalSlider)-START */
.themeStart16#wallCardStylePreview .tb_hs_post_in,
.themeStart16#wallCardStylePreview .tb_hs_post_media_wrapp {
    position: relative;
    border-radius: inherit;
}
.themeStart16#wallCardStylePreview .tb_hs_media_wrap,
.themeStart16#wallCardStylePreview .tb_hs_media_wrap_ {
    border-radius: inherit;
}
.themeStart16#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    visibility: hidden;
    z-index: 7;
}
.themeStart16#wallCardStylePreview .tb_share_button_ {
    color: #2b2b2b;
    left: 0;
    padding-right: 3px;
    position: relative;
    top: 0;
    transition: all .3s ease;
    z-index: 2;
}
.themeStart16#wallCardStylePreview .tb_share_button_,
.themeStart16#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 3px #a9a7a780;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
.themeStart16#wallCardStylePreview .tb_share_icon_list {
    align-items: center;
    display: flex;
    left: 0;
    opacity: 0;
    padding: 0 3px;
    position: absolute;
    top: 0;
    transition: all .4s ease-in-out;
    z-index: -1;
}
.themeStart16#wallCardStylePreview .tb_share_ico__ {
    transition: all .3s ease-in-out;
}
.themeStart16#wallCardStylePreview .tb_hs_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}
.themeStart16#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list {
    opacity: 1;
    transform: rotate(1turn);
}
.themeStart16#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:first-child {
    left: 30px;
}
.themeStart16#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(2) {
    left: 64px;
}
.themeStart16#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(3) {
    left: 98px;
}
.themeStart16#wallCardStylePreview .tb_hs_image,
.themeStart16#wallCardStylePreview .tb_hs_video {
    background-color: #000;
    display: block;
    height: 100% !important;
    left: 0;
    margin: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
}
.themeStart16#wallCardStylePreview .tb_hs_image {
    position: inherit;
    border-radius: inherit;
}
.themeStart16#wallCardStylePreview .tb_hs_post_details,
.themeStart16#wallCardStylePreview .tb_hs_post_overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
    visibility: hidden;
    z-index: 2;
}
.themeStart16#wallCardStylePreview .tb_hs_post_details {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 4;
}
.themeStart16#wallCardStylePreview .tb_hs_contant_wrapper {
    height: 100%;
    left: 0;
    margin: 0 auto;
    padding: 15px;
    position: absolute;
    right: 0;
    top: 0;
}
.themeStart16#wallCardStylePreview .tb_hs_contant_wrapper,
.themeStart16#wallCardStylePreview .tb_hs_social_ {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.themeStart16#wallCardStylePreview .tb_hs_content {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    display: -webkit-box;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: pre-line;
    width: 100%;
    word-break: break-word;
}
.themeStart16#wallCardStylePreview .tb_hs_author_wrapper {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    padding: 10px;
    position: absolute;
    width: 100%;
}
.themeStart16#wallCardStylePreview .tb_hs_author {
    align-items: center;
    display: flex;
    flex-grow: 1;
    width: calc(100% - 30px);
}
.themeStart16#wallCardStylePreview .tb_hs_author_profile {
    background-color: #0003;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 44px;
    margin-right: 10px;
    overflow: hidden;
    width: 44px;
}
.themeStart16#wallCardStylePreview .tb_hs_author_info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
    padding-right: 10px;
    width: calc(100% - 54px);
}
.themeStart16#wallCardStylePreview .tb_hs_post_info {
    align-items: center;
    display: flex;
}
.themeStart16#wallCardStylePreview .tb_hs_time,
.themeStart16#wallCardStylePreview .tb_hs_username {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    opacity: .8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart16#wallCardStylePreview .tb_hs_username {
    max-width: 65%;
}
.themeStart16#wallCardStylePreview .tb_hs_seprator {
    border-radius: 50%;
    display: flex;
    flex-shrink: 1;
    height: 4px;
    margin: 0 6px;
    min-width: 4px;
    opacity: .8;
    width: 4px;
}
.themeStart16#wallCardStylePreview .tb_hs_time {
    max-width: 35%;
    min-width: 65px;
}
.themeStart16#wallCardStylePreview .tb_hs_social_ {
    margin: 15px 0;
    text-align: center;
    width: 100%;
}
/* Theme16(HorizontalSlider)-END */
/* Theme47(HorizontalColumns)-END */
.themeStart47#wallCardStylePreview .tb_hc_post_in {
    background-color: var(--tb-bg-color);
    border-radius: 10px;
    border-radius: var(--tb-border-radius);
    box-shadow: 0 1px 3px #00000038;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}
.themeStart47#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    visibility: hidden;
    z-index: 7;
}
.themeStart47#wallCardStylePreview .tb_share_button_ {
    color: #2b2b2b;
    left: 0;
    padding-right: 3px;
    position: relative;
    top: 0;
    transition: all .3s ease;
    z-index: 2;
}
.themeStart47#wallCardStylePreview .tb_share_button_,
.themeStart47#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 3px #a9a7a780;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
.themeStart47#wallCardStylePreview .tb_share_icon_list {
    align-items: center;
    display: flex;
    left: 0;
    opacity: 0;
    padding: 0 3px;
    position: absolute;
    top: 0;
    transition: all .4s ease-in-out;
    z-index: -1;
}
.themeStart47#wallCardStylePreview .tb_share_button_,
.themeStart47#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 3px #a9a7a780;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
.themeStart47#wallCardStylePreview .tb_share_ico__ {
    transition: all .3s ease-in-out;
}
.themeStart47#wallCardStylePreview .tb_hc_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}
.themeStart47#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list {
    opacity: 1;
    transform: rotate(1turn);
}
.themeStart47#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:first-child {
    left: 30px;
}
.themeStart47#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(2) {
    left: 64px;
}
.themeStart47#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(3) {
    left: 98px;
}
.themeStart47#wallCardStylePreview .tb_hc_media_wrap {
    overflow: hidden;
    position: relative;
}
.themeStart47#wallCardStylePreview .tb_hc_image_wrap_ {
    align-items: center;
    background-color: #00000008;
    display: flex;
    justify-content: center;
    padding-bottom: 100%;
    position: relative;
}
.themeStart47#wallCardStylePreview .tb_hc_image_wrap_in {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.themeStart47#wallCardStylePreview .tb_hc_image,
.themeStart47#wallCardStylePreview .tb_hc_video {
    background-color: #000;
    display: block;
    height: 100% !important;
    left: 0;
    margin: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
}
.themeStart47#wallCardStylePreview .tb_hc_image, .tb_hc_image_wrap_ img {
    object-fit: cover;
    object-position: center;
}
.themeStart47#wallCardStylePreview .tb_hc_image_wrap_ img {
    height: 100%;
    margin: 0 !important;
    max-width: 100%;
    width: 100%;
}
.themeStart47#wallCardStylePreview .tb_hc_contant_wrapper {
    padding: 15px;
}
.themeStart47#wallCardStylePreview .tb_hc_contant_wrapper,
.themeStart47#wallCardStylePreview .tb_hc_content_wrapper__ {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}
.themeStart47#wallCardStylePreview .tb_hc_author_wrapper {
    margin-bottom: 10px;
}
.themeStart47#wallCardStylePreview .tb_hc_author {
    margin-bottom: 15px;
}
.themeStart47#wallCardStylePreview .tb_hc_author_profile {
    background-color: #f7f7f7;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 1px 2px #0006;
    display: flex;
    flex-shrink: 0;
    height: 50px;
    margin: -38px auto 15px;
    overflow: hidden;
    position: relative;
    width: 50px;
    z-index: 1;
}
.themeStart47#wallCardStylePreview .tb_hc_author_info {
    margin-bottom: 15px;
    overflow: hidden;
}
.themeStart47#wallCardStylePreview .tb_hc_authorname {
    color: var(--tb-author-color);
    display: block;
    font-family: var(--tb-author-font);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart47#wallCardStylePreview .tb_hc_post_info {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.themeStart47#wallCardStylePreview .tb_hc_time,
.themeStart47#wallCardStylePreview .tb_hc_username {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    opacity: .8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart47#wallCardStylePreview .tb_hc_username {
    max-width: 65%;
}
.themeStart47#wallCardStylePreview .tb_hc_seprator {
    border-radius: 50%;
    display: flex;
    flex-shrink: 1;
    height: 4px;
    margin: 0 6px;
    min-width: 4px;
    opacity: .8;
    width: 4px;
}
.themeStart47#wallCardStylePreview .tb_hc_time {
    max-width: 35%;
}
.themeStart47#wallCardStylePreview .tb_hc_social_ {
    display: flex;
    font-size: 26px;
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
}
.themeStart47#wallCardStylePreview .tb_hc_social_ico {
    color: var(--tb-icon-color);
    font-size: 24px;
}
.themeStart47#wallCardStylePreview .tb_hc_content,
.themeStart47#wallCardStylePreview .tb_hc_rating_content {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
}
/* Theme47(HorizontalColumns)-END */
/* Theme49(WidgetTheme)-START */
.themeStart49#wallCardStylePreview .tb_wt_content {
    white-space: pre-line;
    word-break: break-word;
}
.themeStart49#wallCardStylePreview .tb_wt_content,
.themeStart49#wallCardStylePreview .tb_wt_rating_content {
    margin-bottom: 15px;
}
.themeStart49#wallCardStylePreview .tb_wt_post_share_container {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.themeStart49#wallCardStylePreview .tb_wt_social_action__ {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
}
.themeStart49#wallCardStylePreview .tb_wt_social_action__list {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 3px;
    padding: 3px;
    text-decoration: none;
}
.themeStart49#wallCardStylePreview .tb_wt_social_action__ico_wrap {
    align-items: center;
    display: flex;
    text-decoration: none !important;
}
.themeStart49#wallCardStylePreview .tb_social_action_ico__ {
    font-size: 16px;
}
.themeStart49#wallCardStylePreview .tb_wt_share_wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.themeStart49#wallCardStylePreview .tb_wt_share_list {
    padding-left: 15px;
}
.themeStart49#wallCardStylePreview .tb_wt_share_icon {
    display: flex;
    font-size: 18px;
    transition: all .3s ease-in-out;
}
.themeStart49#wallCardStylePreview .tb_wt_post_cta {
    display: block;
    margin-bottom: 10px;
    text-align: center;
}
.themeStart49#wallCardStylePreview .tb_wt_post_cta_btn {
    align-items: center;
    background-color: var(--tb-cta-bg-color);
    border-color: var(--tb-cta-border-color);
    border-radius: var(--tb-cta-border-radius);
    color: var(--tb-cta-color);
    display: flex;
    font-family: var(--tb-font);
    font-size: 14px;
    font-weight: var(--tb-cta-font-weight);
    justify-content: center;
    line-height: normal;
    min-height: var(--tb-cta-height);
    padding: var(--tb-cta-padding);
    transition: var(--tb-cta-transition);
}
/* Theme49(HorizontalColumns)-END */
/* Theme50(GalleryPhoto)-START */
.themeStart50#wallCardStylePreview .tb_gp_post_in,
.themeStart50#wallCardStylePreview .tb_gp_post_media_wrapp {
    position: relative;
}
.themeStart50#wallCardStylePreview .tb_gp_post_in {
    background-color: var(--tb-bg-color);
    border-radius: var(--tb-border-radius);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1.5px;
    cursor: pointer;
    display: block;
    overflow: hidden;
}
.themeStart50#wallCardStylePreview .tb_gp_contant_ {
    inset: 0px;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    padding: 8px;
    position: absolute;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: 3;
}
.themeStart50#wallCardStylePreview .tb_gp_contant__in {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 15px;
    position: relative;
    width: 100%;
}
.themeStart50#wallCardStylePreview .tb_gp_content__ {
    position: relative;
    z-index: 1;
}
.themeStart50#wallCardStylePreview .tb_gp_author_profile {
    background-color: rgba(0, 0, 0, 0.2);
    background-position: 50% center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 46px;
    margin: 0px auto 12px;
    overflow: hidden;
    position: relative;
    width: 46px;
}
.themeStart50#wallCardStylePreview .tb_gp_author_info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-bottom: 15px;
    overflow: hidden;
    width: 100%;
}
.themeStart50#wallCardStylePreview .tb_gp_authorname {
    color: var(--tb-author-color);
    display: block;
    font-family: var(--tb-author-font);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart50#wallCardStylePreview .tb_gp_post_info {
    align-items: center;
    display: flex;
    justify-content: center;
}
.themeStart50#wallCardStylePreview .tb_gp_time,
.themeStart50#wallCardStylePreview .tb_gp_username {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart50#wallCardStylePreview .tb_gp_username {
    max-width: 65%;
}
.themeStart50#wallCardStylePreview .tb_gp_seprator {
    border-radius: 50%;
    display: flex;
    flex-shrink: 1;
    height: 4px;
    margin: 0px 6px;
    min-width: 4px;
    opacity: 0.8;
    width: 4px;
}
.themeStart50#wallCardStylePreview .tb_gp_time {
    max-width: 35%;
}
.themeStart50#wallCardStylePreview .tb_gp_content {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: pre-line;
    width: 100%;
}
.themeStart50#wallCardStylePreview .tb_gp_post_overlay {
    background-color: rgb(0, 0, 0);
    height: 100%;
    left: 0px;
    opacity: 0.8;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    z-index: 0;
}
.themeStart50#wallCardStylePreview .tb_gp_social_ {
    opacity: 0.4;
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 3;
}
.themeStart50#wallCardStylePreview .tb_gp_social__ico {
    font-size: 24px;
}
.themeStart50#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    z-index: 7;
}
.themeStart50#wallCardStylePreview .tb_share_button_ {
    color: rgb(43, 43, 43);
    left: 0px;
    padding-right: 3px;
    position: relative;
    top: 0px;
    transition: 0.3s;
    z-index: 2;
}
.themeStart50#wallCardStylePreview .tb_share_button_,
.themeStart50#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: rgba(169, 167, 167, 0.5) 0px 1px 3px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
.themeStart50#wallCardStylePreview .tb_share_icon_list {
    align-items: center;
    display: flex;
    left: 0px;
    opacity: 0;
    padding: 0px 3px;
    position: absolute;
    top: 0px;
    transition: 0.4s ease-in-out;
    z-index: -1;
}
.themeStart50#wallCardStylePreview .tb_share_button_,
.themeStart50#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: rgba(169, 167, 167, 0.5) 0px 1px 3px;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
.themeStart50#wallCardStylePreview .tb_share_ico__ {
    transition: 0.3s ease-in-out;
}
.themeStart50#wallCardStylePreview .tb_gp_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}
.themeStart50#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list {
    opacity: 1;
    transform: rotate(1turn);
}
.themeStart50#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:first-child {
    left: 30px;
}
.themeStart50#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(2) {
    left: 64px;
}
.themeStart50#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(3) {
    left: 98px;
}
/* Theme50(GalleryPhoto)-END */
/* Theme52(SinglePost)-START */
.themeStart52#wallCardStylePreview .tb_spt_post_in {
    box-shadow: 0 0 1.5px #0003;
    cursor: pointer;
    display: block;
}
.themeStart52#wallCardStylePreview .tb_spt_post_in,
.themeStart52#wallCardStylePreview .tb_spt_post_media_wrapp {
    position: relative;
}
.themeStart52#wallCardStylePreview .tb_spt_image_wrap_,
.themeStart52#wallCardStylePreview .tb_spt_video_wrap_ {
    padding-bottom: 130%;
    position: relative;
}
.themeStart52#wallCardStylePreview .tb_spt_image,
.themeStart52#wallCardStylePreview .tb_spt_video {
    display: block;
    height: 100% !important;
    left: 0;
    margin: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
}
.themeStart52#wallCardStylePreview .tb_spt_image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
.themeStart52#wallCardStylePreview .tb_spt_post_details,
.themeStart52#wallCardStylePreview .tb_spt_post_overlay {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .4s ease-in-out;
    visibility: hidden;
    z-index: 4;
}
.themeStart52#wallCardStylePreview .tb_spt_post_details {
    z-index: 2;
}
.themeStart52#wallCardStylePreview .tb_spt_contant_wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
}
.themeStart52#wallCardStylePreview .tb_spt_author_wrapper {
    align-items: center;
    display: flex;
    padding: 15px;
    width: 100%;
}
.themeStart52#wallCardStylePreview .tb_spt_author {
    width: calc(100% - 30px);
}
.themeStart52#wallCardStylePreview .tb_spt_author {
    align-items: center;
    display: flex;
    flex-grow: 1;
}
.themeStart52#wallCardStylePreview .tb_spt_author_profile {
    background-color: #fff;
    background-color: #0003;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    height: 44px;
    margin-right: 10px;
    overflow: hidden;
    width: 44px;
}
.themeStart52#wallCardStylePreview .tb_spt_author_info {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow: hidden;
    padding-right: 10px;
    width: calc(100% - 54px);
}
.themeStart52#wallCardStylePreview .tb_spt_authorname {
    color: var(--tb-author-color);
    display: block;
    font-family: var(--tb-author-font);
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart52#wallCardStylePreview .tb_spt_post_info {
    align-items: center;
    display: flex;
}
.themeStart52#wallCardStylePreview .tb_spt_time,
.themeStart52#wallCardStylePreview .tb_spt_username {
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    opacity: .8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.themeStart52#wallCardStylePreview .tb_spt_username {
    max-width: 65%;
}
.themeStart52#wallCardStylePreview .tb_spt_seprator {
    border-radius: 50%;
    display: flex;
    flex-shrink: 1;
    height: 4px;
    margin: 0 6px;
    min-width: 4px;
    opacity: .8;
    width: 4px;
}
.themeStart52#wallCardStylePreview .tb_spt_time {
    max-width: 35%;
}
.themeStart52#wallCardStylePreview .tb_spt_social_ico {
    color: var(--tb-icon-color);
    display: inline-block;
    font-size: 24px;
    margin: 6px 0;
}
.themeStart52#wallCardStylePreview .tb_spt_content_wrap {
    width: 100%;
}
.themeStart52#wallCardStylePreview .tb_spt_content_wrap_in {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
}
.themeStart52#wallCardStylePreview .tb_spt_content {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: pre-line;
    width: 100%;
    word-break: break-word;
}
.themeStart52#wallCardStylePreview .tb_spt_social_actions_container {
    align-items: center;
    border-top: 1px solid #404040;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    width: 100%;
}
.themeStart52#wallCardStylePreview .tb_spt_share_wrapper {
    position: relative;
}
.themeStart52#wallCardStylePreview .tb_spt_share_wrapper {
    position: relative;
}
.themeStart52#wallCardStylePreview .tb_spt_share_ico {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 16px;
    margin-right: 10px;
}
.themeStart52#wallCardStylePreview .tb_spt_share_btn_txt {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.themeStart52#wallCardStylePreview .tb_spt_share_wrapper_dropdown {
    bottom: 100%;
    left: 0;
    opacity: 0;
    padding-bottom: 5px;
    position: absolute;
    transform: translateY(10px);
    transition: all .3s ease-in-out;
    visibility: hidden;
    width: 145px;
    z-index: 1;
}
.themeStart52#wallCardStylePreview .tb_spt_share_icon_list {
    background-color: #2b2b2b;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.themeStart52#wallCardStylePreview .tb_spt_share_list_in {
    align-items: center;
    border-bottom: 1px solid #545454;
    display: flex;
    padding: 8px 14px;
}
.themeStart52#wallCardStylePreview .tb_spt_share_ico__ {
    color: #ccc;
    font-size: 14px;
    margin-right: 8px;
    transition: all .3s ease-in-out;
}
.themeStart52#wallCardStylePreview .tb_spt_share_ico_txt {
    color: #ccc;
    font-size: 12px;
    transition: all .3s ease-in-out;
}
.themeStart52#wallCardStylePreview .tb_spt_share_icon_list:after {
    background-color: #2b2b2b;
    border-radius: 1px;
    bottom: -5px;
    content: "";
    height: 10px;
    left: 12px;
    position: absolute;
    transform: rotate(45deg);
    width: 10px;
    z-index: 0;
}
.themeStart52#wallCardStylePreview .tb_spt_share_wrapper:focus .tb_spt_share_wrapper_dropdown, .tb_spt_share_wrapper:hover .tb_spt_share_wrapper_dropdown {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}
.themeStart52#wallCardStylePreview .tb_spt_social_action__ {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
}
.themeStart52#wallCardStylePreview .tb_spt_social_action__list {
    cursor: pointer;
    margin: 3px;
    padding: 5px;
    text-decoration: none;
}
.themeStart52#wallCardStylePreview .tb_spt_social_action__ico_wrap,
.themeStart52#wallCardStylePreview .tb_spt_social_action__list {
    align-items: center;
    display: flex;
}
.themeStart52#wallCardStylePreview .tb_spt_social_action_ico__ {
    font-size: 16px;
}
.themeStart52#wallCardStylePreview .tb_spt_post_overlay {
    z-index: 1;
}

.themeStart52#wallCardStylePreview .tb_spt_share_wrapper {
    position: relative;
}
.themeStart52#wallCardStylePreview .tb_spt_share_button_ {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 5px;
}
.themeStart52#wallCardStylePreview .tb_spt_share_ico {
    align-items: center;
    color: rgb(255, 255, 255);
    display: flex;
    font-size: 16px;
    margin-right: 10px;
}
.themeStart52#wallCardStylePreview .tb_spt_share_btn_txt {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.themeStart52#wallCardStylePreview .tb_spt_share_wrapper_dropdown {
    bottom: 100%;
    left: 0px;
    opacity: 0;
    padding-bottom: 5px;
    position: absolute;
    transform: translateY(10px);
    transition: 0.3s ease-in-out;
    visibility: hidden;
    width: 145px;
    z-index: 1;
}
.themeStart52#wallCardStylePreview .tb_spt_share_icon_list {
    background-color: rgb(43, 43, 43);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.themeStart52#wallCardStylePreview .tb_spt_share_list_in {
    align-items: center;
    border-bottom: 1px solid rgb(84, 84, 84);
    display: flex;
    padding: 8px 14px;
}
.themeStart52#wallCardStylePreview .tb_spt_share_ico__ {
    color: rgb(204, 204, 204);
    font-size: 14px;
    margin-right: 8px;
    transition: 0.3s ease-in-out;
}
.themeStart52#wallCardStylePreview .tb_spt_share_ico_txt {
    color: rgb(204, 204, 204);
    font-size: 12px;
    transition: 0.3s ease-in-out;
}
.themeStart52#wallCardStylePreview .tb_spt_share_icon_list::after {
    background-color: rgb(43, 43, 43);
    border-radius: 1px;
    bottom: -5px;
    content: "";
    height: 10px;
    left: 12px;
    position: absolute;
    transform: rotate(45deg);
    width: 10px;
    z-index: 0;
}
.themeStart52#wallCardStylePreview .tb_spt_share_wrapper:focus .tb_spt_share_wrapper_dropdown,
.themeStart52#wallCardStylePreview .tb_spt_share_wrapper:hover .tb_spt_share_wrapper_dropdown {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
}
.themeStart52#wallCardStylePreview .tb_spt_share_list_in:hover .tb_spt_share_ico__,
.themeStart52#wallCardStylePreview .tb_spt_share_list_in:hover .tb_spt_share_ico_txt {
    color: rgb(255, 255, 255);
}
.themeStart52#wallCardStylePreview .tb_spt_post_cta {
    display: block;
    margin-bottom: 10px;
    text-align: center;
}
.themeStart52#wallCardStylePreview .tb_spt_post_cta_btn {
    background-color: rgb(0, 0, 0);
    border-radius: 3px;
    color: rgb(255, 255, 255);
    display: block;
    font-size: 14px;
    line-height: normal;
    padding: 8px;
}
/* Theme52(SinglePost)-END */
/* Theme55(HighlightTheme)-START */
.themeStart55#wallCardStylePreview .tb_ht_post_in {
    background-color: #00000005;
    border-radius: var(--tb-border-radius);
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
}
.themeStart55#wallCardStylePreview .tb_ht_post_media_wrapp {
    position: relative;
}
.themeStart55#wallCardStylePreview .tb_ht_image,
.themeStart55#wallCardStylePreview .tb_ht_video {
    background-color: #000;
    display: block;
    height: 100% !important;
    left: 0;
    margin: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
    transition: all 1s ease-in-out;
}
.themeStart55#wallCardStylePreview .tb_ht_post_details,
.themeStart55#wallCardStylePreview .tb_ht_post_overlay {
    bottom: 0;
    height: calc(100% + 1px);
    left: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: calc(100% + 1px);
    /* z-index: 1; */
}
.themeStart55#wallCardStylePreview .tb_ht_post_details {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 4;
}
.themeStart55#wallCardStylePreview .tb_ht_social_ {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
}
.themeStart55#wallCardStylePreview .tb_ht_social_ico_ {
    color: var(--tb-icon-color);
    font-size: 22px;
    margin: 10px 0;
}
.themeStart55#wallCardStylePreview .tb_ht_post_details,
.themeStart55#wallCardStylePreview .tb_ht_post_overlay {
    bottom: 0;
    height: calc(100% + 1px);
    left: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: calc(100% + 1px);
    z-index: 1;
}
.themeStart55#wallCardStylePreview .tb_ht_post_in:hover .tb_ht_image {
    transform: scale(1.07);
}
.themeStart55#wallCardStylePreview .tb_ht_post_in:hover .tb_ht_post_details,
.themeStart55#wallCardStylePreview .tb_ht_post_in:hover .tb_ht_post_overlay {
    opacity: 1;
    visibility: visible;
}
.themeStart55#wallCardStylePreview .tb_ht_post_in:hover .tb_ht_post_details {
    animation: TbSlideUpAnimation_ .65s ease forwards;
    transform: translateY(10px);
}
@keyframes TbSlideUpAnimation_ {
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}
/* Theme55(HighlightTheme)-END */
/* Theme61(ReelsTheme)-END */
.themeStart61#wallCardStylePreview .tb_rt_post_slider,
.themeStart61#wallCardStylePreview .tb_rt_post_wrapper {
    overflow: hidden;
    width: 100%;
}
.themeStart61#wallCardStylePreview .tb_rt_post_wrapper {
    padding: 30px 10px;
}
.themeStart61#wallCardStylePreview .tb_rt_post_in {
    border-radius: var(--tb-border-radius);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}
.themeStart61#wallCardStylePreview .tb_rt_post_in,
.themeStart61#wallCardStylePreview .tb_rt_post_in_animate:hover {
    transition: box-shadow .3s ease-in, transform .3s ease-in;
}
.themeStart61#wallCardStylePreview .tb_rt_image,
.themeStart61#wallCardStylePreview .tb_rt_post_media_wrapp {
    display: block;
}
.themeStart61#wallCardStylePreview .tb_rt_post_media_wrapp {
    position: relative;
}
.themeStart61#wallCardStylePreview .tb_rt_image_wrap_,
.themeStart61#wallCardStylePreview .tb_rt_video_wrap_ {
    background-color: #00000008;
    padding-bottom: 178%;
    position: relative;
}
.themeStart61#wallCardStylePreview .tb_rt_image,
.themeStart61#wallCardStylePreview .tb_rt_video {
    background-color: #000;
    display: block;
    height: 100% !important;
    left: 0;
    margin: 0;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100% !important;
    z-index: 1;
}
.themeStart61#wallCardStylePreview .tb_rt_play_ico {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 70px;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition-delay: .5s;
    transition-duration: .3s;
    transition-property: opacity;
    transition-timing-function: ease-in;
    width: 100%;
    z-index: 1;
}
.themeStart61#wallCardStylePreview .tb-play {
    transform: scale(1.3);
}
.themeStart61#wallCardStylePreview .tb_rt_post_details,
.themeStart61#wallCardStylePreview .tb_rt_post_overlay {
    bottom: 0;
    height: calc(100% + 1px);
    left: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: calc(100% + 1px);
    z-index: 1;
}
.themeStart61#wallCardStylePreview .tb_rt_post_details {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 4;
}
.themeStart61#wallCardStylePreview .tb_rt_social_ico_ {
    color: var(--tb-icon-color);
    font-size: 22px;
    margin: 15px 0;
}
.themeStart61#wallCardStylePreview .tb_rt_post_details,
.themeStart61#wallCardStylePreview .tb_rt_post_overlay {
    bottom: 0;
    height: calc(100% + 1px);
    left: 0;
    opacity: 0;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    width: calc(100% + 1px);
    z-index: 1;
}
.themeStart61#wallCardStylePreview .tb_rt_post_overlay-dark {
    background-color: #0003;
    background-image: linear-gradient(135deg, #0000, #0006);
}
.themeStart61#wallCardStylePreview .tb_rt_post_in,
.themeStart61#wallCardStylePreview .tb_rt_post_in_animate:hover {
    transition: box-shadow .3s ease-in, transform .3s ease-in;
}
.themeStart61#wallCardStylePreview .tb_rt_post_in_animate:hover {
    transform: scale(1.04);
}
.themeStart61#wallCardStylePreview .tb_rt_post_in:hover .tb_rt_play_ico {
    opacity: .5;
    transition-duration: .3s;
    transition-property: opacity;
    transition-timing-function: ease-in;
}
.themeStart61#wallCardStylePreview .tb_rt_post_in:hover .tb_rt_post_details,
.themeStart61#wallCardStylePreview .tb_rt_post_in:hover .tb_rt_post_overlay {
    opacity: 1;
    visibility: visible;
}
.themeStart61#wallCardStylePreview .tb_rt_post_in:hover .tb_rt_post_details {
    animation: TbSlideUpAnimation_ .65s ease forwards;
    transform: translateY(10px);
}
.themeStart61#wallCardStylePreview .tb_rt_post_in:hover .tb_rt_post_details,
.themeStart61#wallCardStylePreview .tb_rt_post_in:hover .tb_rt_post_overlay {
    opacity: 1;
    visibility: visible;
}
/* Theme61(ReelsTheme)-END */
/* Theme83(Vivid)-START */
.themeStart83#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    visibility: hidden;
}
.tb_nc_share_container.tb_share_wrapper {
    z-index: 4;
}
.themeStart83#wallCardStylePreview .tb_share_button_ {
    color: #2b2b2b;
    left: 0;
    padding-right: 3px;
    position: relative;
    top: 0;
    transition: all .3s ease;
    z-index: 2;
}
.themeStart83#wallCardStylePreview .tb_share_button_,
.themeStart83#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 3px #a9a7a780;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
.themeStart83#wallCardStylePreview .tb_share_icon_list {
    align-items: center;
    display: flex;
    left: 0;
    opacity: 0;
    padding: 0 3px;
    position: absolute;
    top: 0;
    transition: all .4s ease-in-out;
    z-index: -1;
}
.themeStart83#wallCardStylePreview .tb_share_ico__ {
    transition: all .3s ease-in-out;
}
.themeStart83#wallCardStylePreview .tb_nc_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}
.themeStart83#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list {
    opacity: 1;
    transform: rotate(1turn);
}
.themeStart83#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:first-child {
    left: 30px;
}
.themeStart83#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(2) {
    left: 64px;
}
.themeStart83#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(3) {
    left: 98px;
}
/* Theme83(Vivid)-END */
/* Share-Actions-START */
#wallCardStylePreview .tb_spt_post_in
#wallCardStylePreview .tb_share_wrapper {
    align-items: center;
    display: flex;
    left: 10px;
    opacity: 0;
    padding-right: 3px;
    position: absolute;
    top: 10px;
    transition: all .3s ease-in-out;
    visibility: hidden;
    z-index: 7;
}
#wallCardStylePreview .tb_share_icon_list {
    align-items: center;
    display: flex;
    left: 0;
    opacity: 0;
    padding: 0 3px;
    position: absolute;
    top: 0;
    transition: all .4s ease-in-out;
    z-index: -1;
}
#wallCardStylePreview .tb_share_button_,
#wallCardStylePreview .tb_share_ico__ {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 3px #a9a7a780;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 28px;
    justify-content: center;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    width: 28px;
}
#wallCardStylePreview .tb_share_ico__ {
    transition: all .3s ease-in-out;
}
#wallCardStylePreview .tb_wt_post_in:hover .tb_share_wrapper {
    opacity: 1;
    visibility: visible;
}
#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list {
    opacity: 1;
    transform: rotate(1turn);
}
#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:first-child {
    left: 30px;
}
#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(2) {
    left: 64px;
}
#wallCardStylePreview .tb_share_wrapper:hover .tb_share_icon_list:nth-child(3) {
    left: 98px;
}
/* Share-Actions-END */
/* CTA-START */
#wallCardStylePreview .tb_nc_post_cta {
    display: block;
    margin-bottom: 10px;
    text-align: center;
}
#wallCardStylePreview .tb_nc_post_cta_btn {
    align-items: center;
    background-color: var(--tb-cta-bg-color);
    border-color: var(--tb-cta-border-color);
    border-radius: var(--tb-cta-border-radius);
    color: var(--tb-cta-color);
    display: flex;
    font-family: var(--tb-font);
    font-size: 14px;
    font-weight: var(--tb-cta-font-weight);
    justify-content: center;
    line-height: normal;
    min-height: var(--tb-cta-height);
    padding: var(--tb-cta-padding);
    transition: var(--tb-cta-transition);
}
/* CTA-END */
/* Share-Options-START */

/* #wallCardStylePreview */
/* Share-Options-END */