.moderation_modal_main {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    z-index: 95;
}
.moderation_modal_main .moderation_action{
    height: calc(100vh - 96px);
    overflow: auto;
}
.moderation_modal_main .moderation-right{
    height: calc(100vh - 96px);
    overflow: auto;
}
.moderation_modal_main .moderation-right table tbody tr:hover{
    box-shadow: 0px 0px 10px #ccc;
    position: relative !important;
    cursor: move;
    background: #fff !important;
    z-index: 1;
}
.moderation_modal_main .moderation-right table tbody tr .pointer{
    opacity: 0;
}
.moderation_modal_main .moderation-right table tbody tr .pointer span{
    display: block;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    margin: 2px 1px;
    background-color: #2B2B2B;
}
.moderation_modal_main .moderation-right table tbody tr:hover .pointer,
.moderation_modal_main .moderation-right table tbody tr:focus .pointer,
.moderation_modal_main .moderation-right table tbody tr:visited .pointer,
.moderation_modal_main .moderation-right table tbody tr:active .pointer{
    opacity: 1;
}
.trigger-main{
    max-width: 320px;
    margin: 80px auto;
}
.trigger-main .form-group input{
    font-size: 16px;
    font-weight: 500;
}
.trigger-main .toggleOnBut .custom-toggle{
    margin-right: 5px;
}
.trigger-main .toggleOnBut .custom-toggle label{
    color: #60c463;
}
.switch .checkbox span{
    margin-right: 5px !important;
}
.trigger-box{
    border: 1px dashed #3F4254 !important;
    padding: 18px 20px !important;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 8px !important;
}
.trigger-box::after{
    content: none !important;
}
.trigger-box.disable{
    cursor: default !important;
    opacity: 0.6;
}
.trigger-box-card{
    border-style: solid !important;
}
.trigger-list{
    position: absolute;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 5px;
    z-index: 1;
}
.trigger-handle-box{
    border: 1px solid #3F4254;
    padding: 25px 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
}
.trigger-list a + a{
    border-top: 1px solid #E1E3EA;
}
.trigger-list a .disable{
    opacity: 1;
}
.trigger-list a .disable h6,
.trigger-list a .disable p{
    color: #B4B4B4;
}
.trigger-list a h6{
    margin-bottom: 0px;
    font-size: 12px;
}
.trigger-list a p{
    font-size: 12px;
}
.rules-card{
    border: 1px dashed #3F4254;
    padding: 10px;
    border-radius: 10px;
}
.custom-toggle label{
    margin-right: 0px !important;
}
.custom-toggle label span,
.custom-toggle label span:after{
    border: 0px !important;
}
.custom-toggle input~span{
    width: 36px !important;
}
.custom-toggle input~span:before{
    height: 18px !important;
    width: 34px !important;
}
.custom-toggle input:checked~span:after{
    margin-left: 18px !important;
    top: 6px !important;
}
.custom-toggle input:empty~span:after{
    width: 10px !important;
    height: 10px !important;
    top: 6px !important;
}
.custom-toggle input:empty{
    margin-left: 0px;
}
.add-rule{
    margin-bottom: 70px;
}
.add-rule::before{
    background-color: #444;
    content: "";
    height: 40px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: calc(100% + 26px);
    width: 1px;
}
.add-rule::after{
    content: "\f107";
    display: block;
    font-family: "Font Awesome 6 Pro";
    position: absolute;
    left: calc(50% + 0.5px);
    transform: translate(-50%, 0px);
    bottom: -76px;
    color: #444;
}
.action_gap{
    background-color: #444;
    height: 40px;
    left: 0;
    margin: 0 auto;
    right: 0;
    width: 1px;
}
.trigger-main .btn-and{
    position: absolute !important;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1px 12px !important;
    top: 15px;
    border: 1px solid #1a2029 !important;
    background: #fff;
    color: #000 !important;
}
.action_gap .btn-and{
    color: white !important;
    background-color: #000 !important;
}
.trigger-main .btn-and:after,
.trigger-main .btn-and::before{
    content: none;
}
.btn-none{
    border: 0px !important;
    padding: 0px !important;
    height: 0px !important;
    line-height: 0px !important;
    position: absolute;
    width: 100%;
}
.btn-none:after{
    content: none !important;
}
.symbol-30{
    height: 30px;
    width: 30px;
}
.symbol-20{
    height: 20px;
    width: 20px;
}
.symbol-16{
    height: 16px;
    width: 16px;
}
.symbol-16 .symbol-label{
    height: auto !important;
    width: auto !important;
}
.h-34px{
    height: 34px;
}
.w-34px{
    width: 34px;
}
.h-32px{
    height: 32px;
}
.w-32px{
    width: 32px;
}
.trigger-box .options-dropdown .form-check-input{
    position: inherit;
    padding: 0px;
    opacity: 1;
    height: 16px;
    width: 16px;
    z-index: 1;
    border-radius: 0px;
}
.fs-9{
    font-size: calc(9px + .1vw + .1vh)!important;
}
.trigger-box .options-dropdown .form-check-input:checked{
    background-color: #313a53;
    border-color: #313a53;
}
.trigger-box .selectpicker__placeholder{
    text-align: left;
}
.trigger-box .badge .btn{
    line-height: 1;
    font-size: 12px;
    border:0px;
}
.trigger-box .badge .btn i{
    font-size: 12px;
}
.moderation_modal_action_left{
    flex: 0 0 auto;
    width: 65%;
}
.moderation_modal_action_right{
    flex: 0 0 auto;
    width: 35%;
}
.disable{
    cursor: default !important;
    opacity: 0.6;
}

@media screen and (max-width: 1400px) {
    .moderation_modal_action_left{
        width: 60%;
    }
    .moderation_modal_action_right{
        width: 40%;
    }
}

@media screen and (max-width: 991px) {
    .moderation_modal_action_left{
        width: 100%;
    }
    .moderation_modal_action_right{
        width: 100%;
    }
    .moderation_modal_main .moderation_action,
    .moderation_modal_main .moderation-right{
        height: auto;
    }
}
@media screen and (max-width: 767px) {
    .trigger-main{
        margin: 40px auto;
    }
}
.rating-options .dropdown-item {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.options-dropdown .m_rating {    
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.options-dropdown .m_post_rating{
    color: #000;
}
.options-dropdown .fas.star-outline::before {
    content: "\f005";
    font-weight: 400;
    color:#000;
}
.options-dropdown .m_rating span {
    font-size:14px;
}
.options-dropdown .m_rating+span {
    opacity:0.55;
    font-weight:500;
}
.add-action h3,
.add-rule h3,
.custom-rulename h3 {
    position: absolute;
    left: -70px;
    top: 49%;
    transform: translateY(-49%);
    font-size: 14px;
    margin-bottom: 0;
}
.admorebtn {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius:50%;
    color:#000;
    background: #fff;
    border: 1px solid #000;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 0.7px;
    right: 0;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
}
.admorebtn.admorebtn-active:hover {
    background: #000;
    color: #fff;
    cursor: pointer;
}
.trigger-main .btn-and {
    top:21px;
}
.down-arrow-rule::before {
    height: 66px;
    top: calc(100% + 20px);
    background-color: #444;
    top: 100%;
}
.custom-rulename h3 {
    left: -91px;
}
.sidebar-addbtn {
    border: 1px solid #000;
    width: 19px;
    height: 19px;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    line-height: 14px;
    color: #000;
    border-radius: 50%;
}