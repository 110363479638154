.moderation_loading {
    position: fixed;
    top: 150px;
    bottom: 0;
    right: 0;
    left: 260px;
    background-color: #EFF3F6;
    height: calc(100vh - 150px);
    width: calc(100% - 260px);
    z-index: 10;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.noMobPosts-block {
    background-color: #fff;
    min-height: calc(100vh - 220px);
    width: 100%;
    z-index: 12;
    padding-top: 15%;
    padding-bottom: 12%;
}

.aside-minimize .moderation_loading {
    left: 70px;
    width: calc(100% - 70px);
}

.carousel-image-section svg {
    width: 18px;
    height: 18px;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
}

.carousel-image-section svg g [fill] {
    fill: #fff;
}

.m_post_icon_ {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    display: flex;
    align-items: center;
}

.navi.navi-hover .navi-item.active .navi-link,
.navi.navi-hover .navi-item .navi-link:hover {
    background-color: #F3F6F9;
}

.carousel-image-section,
.video_post_ico {
    padding: 10px;
}

.m_mod_card {
    -webkit-box-shadow: 0 0 30px 0 rgba(82, 63, 105, .05);
    box-shadow: 0 0 30px 0 rgba(82, 63, 105, .05);
    border-radius: 8px;
    border: none;
    width: 100%
}

.m_top_tools {
    border-bottom: 1px solid #ebedf3;
    padding: 0px 4px;
}

.m_action_icons {
    margin-right: -12px
}

.m_top_tools ul li {
    display: inline-block
}

.m_top_tools ul li a {
    display: block;
    padding: 5px
}

.pin.done svg path {
    fill: #3699ff
}

.highlight.done svg path {
    fill: #f5c802
}

.m_top_tools li .btn {
    padding: 10px 6px;
    border: 0;
}

.m_tagged_pro ul li {
    padding: 0 4px
}

.m_grid_card {
    float: left;
    width: 16.6666%;
}

.m_grid_card.grid-2x {
    width: calc(16.6666% * 2)
}

.m_tagged_pro ul li div {
    display: block;
    height: 42px;
    width: 42px;
    background-size: cover;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center
}

.m_card_img img {
    margin: 0 auto;
    display: block;
    width: 100%
}

.m_card_img {
    width: 100%;
    overflow: hidden;
    position: relative
}

.m_card_img.image.m_network-28 .m_media_file {
    /* pointer-events: none; */
}

.m_social_icon img {
    width: 22px
}

.m_blur_bg {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    filter: blur(15px);
    -webkit-filter: blur(15px)
}

.m_card_vid {
    width: 100%;
    background-color: #000;
    overflow: hidden;
    position: relative;
    padding: 12% 0
}

.m_list_media_holder,
.m_media_holder {
    position: relative;
    z-index: 2;
    width: 100%
}

.m_media_holder video,
.m_media_holder iframe {
    border: none;
    height: 100%;
    width: 100%;
    display: block;
}

.m_list_media_holder {
    height: 100%
}

.m_list_media_holder img {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
}

.m_list_userinfo span {
    font-size: 12px;
    color: #959595;
}

.m_ugc_post {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2
}

.modal .m_media_holder .img-fluid {
    height: 250px;
    object-fit: contain;
    object-position: center;
}

.m_getugc_post {
    background-color: #1a2029;
    color: #fff !important;
    text-align: center;
    padding: 3px;
    width: 100%;
    display: block;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.m_getugc_post:hover {
    text-decoration: none;
    color: #fff
}

.m_getugc_post.ugc_status_moderation_panel_pending {
    background-color: #ff8100
}

.m_getugc_post.ugc_status_moderation_panel_approved {
    background-color: #6ac259
}

.m_getugc_post.ugc_status_moderation_panel_rejected {
    background-color: #db4b20
}

.m_getugc_post.ugc_status_moderation_panel_expired {
    background-color: #545454
}

.m_getugc_post .m_status i {
    padding-right: 5px;
    vertical-align: middle;
    color: #fff;
    font-size: 14px
}

.m_getugc_post .m_status {
    font-size: 12px;
    font-weight: 600
}

.m_getugc_post span.ugcCreated {
    font-size: 10px;
    padding-left: 5px
}

.add_m-pro {
    width: 50px;
    height: 50px;
    display: block;
    background-color: #f7f7f7;
    border-radius: 0;
    text-align: center;
    padding: 0
}

.m_list_action_btn .add_m-pro {
    width: 42px;
    height: 42px;
    display: block;
    background-color: #f7f7f7;
    border-radius: 4px;
    text-align: center;
    padding: 0
}

.m_grid_cta {
    padding: 5px 10px
}

.m_grid_cta a {
    width: 100%;
    margin: 0 auto;
    padding: 9px 30px;
    border-radius: 2px;
    overflow: hidden;
    text-overflow: ellipsis
}

.m_tagged_pro_action {
    display: none
}

.m_tagged_pro ul {
    padding-left: 6px
}

.m_card_style {
    position: relative;
    overflow: hidden
}

.m_tagged_pro_action {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 7;
    border-bottom: 1px solid #f7f7f7
}

.m_card_cont {
    padding: 10px
}

.card.card-custom.card-stretch.m_mod_card.gutter-b {
    height: calc(100% - 28px)
}

.m_filters .dropdown-menu.dropdown-menu-sm {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.m_list_post_title,
.m_post_title {
    font-size: 14px;
    color: #2b2b2b;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.m_grid_userinfo {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.m_user_content {
    max-width: calc(100% - 50px)
}

.m_list_post_title:hover,
.m_post_title:hover {
    color: #2b2b2b
}

.m_grid_author span {
    line-height: 14px;
    font-size: 12px;
    color: #959595;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis
}

span.sepratedot {
    height: 4px;
    width: 4px;
    background-color: #959595;
    border-radius: 50%;
    margin: 0 5px;
    display: table;
    align-self: center
}

.m_card_cont .symbol.symbol-circle {
    height: 34px;
    width: 34px;
    text-align: center;
    font-size: 22px;
    padding: 5px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #535353;
    color: #fff;
    display: block;
}

.m_bottom_actions {
    border-top: 1px solid #ebedf3
}

.m_bottom_actions ul li {
    width: 33.33%;
    text-align: center
}

.m_checkbox.checkbox>input:checked~span:after {
    border-color: #545454
}

.m_checkbox.checkbox>input:checked~span {
    background-color: transparent;
    border: 1.5px solid #545454
}

.m_checkbox.checkbox>input:focus~span,
.m_checkbox.checkbox>span {
    background-color: #fff;
    border: 1.5px solid #545454;
    border-radius: 2px;
    margin-right: 5px;
}

.m_bottom_actions .m_status {
    padding: 8px;
    display: block;
    background-color: #f7f7f7
}

.m_bottom_actions .m_status svg {
    display: block;
    margin: 0 auto
}

.m_bottom_actions ul li button {
    width: 100%;
    font-weight: 600;
    height: 100%;
    border-radius: 4px;
    padding: 7px 4px;
    font-size: 13px;
    min-height: 34px;
    background-color: #ecf1f4;
    font-weight: normal;
    border: 0px;
}

.m_bottom_actions ul li button.m_active svg path,
.m_bottom_actions ul li button:hover svg path {
    fill: #fff
}

.m_bottom_actions ul li button.m_grid_check.m_active,
.m_bottom_actions ul li button.m_grid_check:hover {
    background-color: #6ac259;
    color: #fff;
}

.m_bottom_actions ul li button.m_grid_non_check.m_active,
.m_bottom_actions ul li button.m_grid_non_check:hover {
    background-color: #db4b20;
    color: #fff;
    border-color: #db4b20;
}

.m_mod_card .svg-icon svg g [fill] {
    fill: #545454
}

.m_filters button i,
.m_toggle_actions button i {
    color: #545454;
    font-size: 16px;
    padding: 0
}

.m_social_icon i {
    font-size: 22px
}

.m_mod_card .svg-icon svg {
    height: 1.3rem;
    width: 1.3rem
}

.m_list_profile {
    height: 36px;
    width: 36px;
    text-align: center;
    font-size: 22px;
    padding: 5px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #535353;
    color: #fff;
    display: block
}

.m_list_author {
    margin-right: 30px
}

.m_list_media_wrap {
    position: relative;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 4px;
}

.m_list_post_img {
    height: 150px;
    width: 150px;
    background-color: #000;
    text-align: center;
    position: relative
}

.m_list_post_img .m_pending_post p {
    margin: 0 auto;
    font-size: 12px
}

.m_list_post_img .m_pending_post p i {
    padding-left: 5px;
    font-size: 14px
}

.m_list_author a,
.m_list_author p {
    line-height: 18px
}

.m_card_select {
    margin-right: 15px
}

.m_list_wrap {
    padding: 15px
}

.m_list_card {
    min-width: 991px
}

.m_list_action_wrap {
    display: none
}

.m_list_action_btn {
    border-top: 1px solid #ebedf3;
    padding: 5px 15px
}

.m_list_action_btn ul {
    margin: 0
}

.m_list_card {
    border-radius: 0
}

.m_list_status {
    padding: .65rem 1rem
}

.m_list_actions .btn .svg-icon svg {
    height: 12px;
    width: 14px
}

.m_list_actions ul li .btn {
    background-color: #ecf1f4;
    font-weight: normal;
    padding: 6px 10px;
    min-width: 70px;
}

.m_list_actions ul li .btn.m_check_btn:hover,
.m_list_actions ul li .m_active.m_check_btn {
    background-color: #6ac259;
    color: #fff;
    border-color: #6ac259;
}

.m_list_actions ul li .m_active.m_uncheck_btn,
.m_list_actions ul li .m_uncheck_btn:hover {
    background-color: #db4b20;
    border-color: #db4b20;
    color: #fff;
}

.m_list_actions .btn .svg-icon {
    margin-right: 0;
    display: block;
    line-height: 12px
}

.m_active.m_check_btn svg path,
.m_active.m_uncheck_btn svg path,
.m_check_btn:hover svg path,
.m_uncheck_btn:hover svg path {
    fill: #fff
}

.m_check_btn svg path,
.m_uncheck_btn svg path {
    fill: #545454
}

.m_list_actions ul li {
    padding: 0 5px
}

.m_list_cta {
    padding-right: 5%
}

.list_conent_wrap {
    padding-right: 5%
}

.m_card_list {
    overflow-y: auto
}

.m_list_action_btn ul li button span {
    display: inline-block;
    vertical-align: middle;
    color: #545454;
    margin-left: 3px;
}

.m_list_cta a {
    padding: 9px 30px;
    border-radius: 2px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis
}

.m_bottom_actions .btn .svg-icon {
    margin-right: 0
}

.subheader_main .nav.nav-tabs .nav-link .nav-text small {
    margin-left: 5px;
    background-color: #545454;
    border-radius: 30px;
    padding: 5px 8px;
    line-height: 10px;
    display: inline-block;
    color: #fff
}

.m_list_action_btn .m_tagged_pro {
    padding-left: 32px
}

.quick-search {
    width: 320px;
    transition: all .3s;
    position: absolute;
    z-index: 1;
}

.search_btn .close_btn_svg {
    display: none;
    transform: scale(1.4);
}

.search_btn .search_btn_svg {
    display: inline-block;
}

.quick-search.show~.search_btn .search_btn_svg {
    display: none;
}

.quick-search.show~.search_btn .close_btn_svg {
    display: inline-block;
}

.m_action_icons li .btn.btn-icon {
    padding: 4px;
    height: 30px;
    width: 30px;
    position: relative;
    border: none;
}

.m_action_icons li .btn.btn-icon:hover {
    background-color: #f6f6f6;
}

.m_action_icons>li {
    padding: 0 3px
}

.view_type li:first-child a.btn.btn-icon:after {
    content: '';
    position: absolute;
    right: -3px;
    top: 6px;
    height: 18px;
    width: 1px;
    background-color: #c9c9c9
}

.view_type li {
    padding: 0 3px
}

.view_type li a.active svg {
    opacity: .3
}

.view_type li asvg {
    opacity: 1
}

.sel_post_type .btn {
    width: 100%;
    border: 1px solid #535353;
    text-align: left
}

.sel_post_type .dropdown-toggle.btn:after {
    right: 15px;
    position: absolute;
    top: 44%
}

.sel_post_type .dropdown-menu {
    width: 100%;
    border-radius: 0;
    margin-top: 0
}

.offcanvas-content .accordion.accordion-toggle-arrow .card .card-header .card-title {
    padding: 0;
    color: #2b2b2b
}

.offcanvas-content .accordion.accordion-toggle-arrow .card .card-header .card-title:after {
    color: #2b2b2b
}

.m_reset_btn {
    text-decoration: underline
}

.accordion .m_checkbox {
    font-size: 13px;
    color: #545454
}

.m_refresh .btn {
    padding: 0
}

.m-col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    padding: 0 15px
}

.m-col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px
}

.reset_btn {
    padding: 3px 20px;
}


.m_list_actions_wrap {
    width: 100%;
    max-width: 350px
}

.filter_tags span .btn i {
    color: #545454
}

.m_checkbox p {
    margin: 0;
    white-space: nowrap;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis
}

.moderation_action .btn .svg-icon svg {
    width: 14px;
    height: 10px
}

.moderation_action .btn .svg-icon svg path {
    fill: #fff !important;
}

.mod_pagination .bootstrap-select .dropdown-menu.inner>li>a {
    padding: 6px 15px
}

.mod_pagination .bootstrap-select>.dropdown-toggle {
    padding: .4rem 1rem
}

.list_highlight_post {
    background-color: #fffbe9
}

.cancel_all_checked {
    height: 20px
}

.m_bottom_actions .btn.disabled,
.m_bottom_actions .btn:disabled,
.m_list_actions .btn.disabled,
.m_list_actions .btn:disabled {
    opacity: 1
}

.iframeVideo {
    /* width: 100%;
    display: block;
    height: auto;
    padding: 8% 0; */
    width: 100%;
    display: block;
    height: auto;
    min-height: 220px;
    padding: 0;
}

.m_video_post_wrap {
    width: 100%;
    height: 100%;
    display: block;
}

.m_video_post_wrap img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.tb_post_modal_media_holder video {
    width: 100% !important;
    height: 100% !important;
}

iframe.vimeoVideo.iframeVideo+iframe.vimeoVideo.iframeVideo {
    display: none;
}

.m_media_file {
    background-color: #000;
}

.m_media_file,
.m_image_media {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
}

.m_image_media img {
    object-fit: cover;
}

.m_list_content .ng-binding {
    word-break: break-all
}

.refreshImage span {
    margin: 5px auto;
    cursor: pointer
}

.m_refresh_grid_card {
    border-bottom: 1px solid #ebedf3
}

span#new_postNo {
    border-radius: 30px;
    background-color: #ff4051;
    color: #fff;
    padding: 3px 5px;
    font-size: 10px;
    font-weight: 700;
    display: block;
    margin: 3px 0
}

#refresh_posts.animated span#new_postNo {
    -webkit-animation-name: wobble;
    animation-name: wobble;
    -webkit-animation-duration: .8s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-transform-origin: 50% 100%
}

@-webkit-keyframes wobble {
    0% {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }

    100% {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes wobble {
    0% {
        -webkit-transform: none;
        transform: none
    }

    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg)
    }

    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg)
    }

    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg)
    }

    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg)
    }

    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg)
    }

    100% {
        -webkit-transform: none;
        transform: none
    }
}

.modrtionPostColmNew span.timePost {
    white-space: nowrap
}

.videoIcon+video {
    top: 0;
    position: absolute;
    bottom: inherit;
    height: 100%
}

.m_list_media_holder iframe,
.m_card_img iframe {
    width: 100%;
    height: 100%;
    background-color: #000;
}

.modrtionPost .m_card_cont .m_grid_author {
    width: calc(100% - 25px);
}

.modrtionPost .m_card_cont .m_social_icon.modTextPost {
    width: 25px;
}

#upcmgPostModalBg.fade {
    opacity: 1;
}

#upcmgPostModalBg {
    background: rgba(0, 0, 0, 0.5);
}

.upcmgPostModal .timerSec {
    margin: 20px 0 35px 0;
}

.upcmgPostModal .timerSec div {
    display: inline-block;
    margin: 5px;
    font-size: 11px;
    font-weight: 600;
}

.upcmgPostModal .timerSec div span {
    font-family: oswald;
    display: block;
    background: #000;
    line-height: 60px;
    width: 60px;
    height: 60px;
    color: #fff;
    margin-bottom: 7px;
    font-size: 36px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}

.upcmgPostModal .learnMore {
    float: right;
    font-size: 11px;
    text-decoration: none;
    transition: all ease 0.3s;
}

.upcmgPostModal .learnMore {
    float: right;
    font-size: 14px;
    text-decoration: none;
    transition: all ease 0.3s;
}

.upcmgPostModal .learnMore i {
    font-size: 10px;
}

.upcmgPostContnt {
    display: none;
}

.pagination {
    display: flex;
    align-items: center;
    margin: 0;
}

.pagination li {
    color: black;
    float: left;
    text-decoration: none;
    margin-left: .4rem;
    cursor: pointer;
}

.pagination li a {
    outline: none !important;
    cursor: pointer;
    display: flex;
    height: 30px;
    min-width: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    position: relative;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 500;
    border: 0;
    -webkit-transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, -webkit-box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
    transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
    color: #545454;
    background-color: transparent;
    background: #f3f6f9;
}

li.ng-scope.active a {
    background: #ff4051;
}

.sw_theme li.ng-scope.active a {
    background: #2B2B2B;
}

li.ng-scope.disabled a i {
    color: #b5b5c3;
}

li.ng-scope.active a {
    color: #fff !important;
}

.pagination li a .ki {
    font-size: .7rem;
    color: #545454;
}

@media (min-width: 992px) {
    .moderation_loading {
        position: fixed;
        top: 185px;
        bottom: 0;
        right: 0;
        left: auto;
        height: calc(100vh - 185px);
        width: calc(100% - 265px);
        z-index: 10;
        transition: all .3s;
    }

    #upcmgPostModalBg {
        overflow-x: hidden;
        overflow-y: auto;
        padding-left: 250px;
        padding-top: 135px;
    }

    .aside-minimize .moderation_loading,
    .aside-minimize-hover .moderation_loading {
        width: calc(100% - 70px);
    }
}

@media only screen and (max-width: 1600px) and (min-width: 991px) {
    .moderation_loading {
        left: 230px;
        width: calc(100% - 230px);
    }

    .aside-minimize .moderation_loading {
        left: 70px;
        width: calc(100% - 70px);
    }
}

@media (max-width: 1600px) {
    .m_list_actions_wrap {
        max-width: 200px;
    }

    .m_grid_card {
        padding-right: 5px;
        padding-left: 5px;
    }

    .m_mod_card.gutter-b {
        margin-bottom: 10px;
    }

    .row.grid-view-content {
        margin-right: -5px;
        margin-left: -5px;
    }

    .m_grid_card {
        width: 20%;
    }

    .m_grid_card.grid-2x {
        width: calc(20% * 2)
    }
}

@media (max-width:1400px) {
    .m_grid_card {
        width: 20%
    }

    .m_grid_card.grid-2x {
        width: calc(20% * 2)
    }
}

@media (max-width:1366px) {

    .m_card_select,
    .m_list_post_img {
        margin-right: 10px
    }

    .m_list_action_btn .m_tagged_pro {
        padding-left: 24px
    }

    .m_list_action_btn ul li .btn {
        padding: 10px
    }

    .m-col-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 68%;
        flex: 0 0 68%;
        max-width: 68%
    }

    .m-col-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 32%;
        flex: 0 0 32%;
        max-width: 32%
    }

    .list_conent_wrap,
    .m_list_cta {
        padding-right: 0
    }
}

@media (max-width:1200px) {
    .m_grid_card {
        width: 33.33%
    }

    .m_grid_card.grid-2x {
        width: calc(33.33% * 2)
    }
}

@media (max-width:991px) {
    .quick-search {
        width: 280px;
    }

    .m_filters .btn {
        padding: 10px 12px
    }

    .m_bottom_actions li .btn {
        padding: 10px 12px
    }

    .m_action_icons li .btn.btn-icon svg {
        width: 18px;
        height: 18px
    }

    .m_action_icons li .btn.btn-icon {
        height: 32px;
        width: 32px
    }

    .view_type li:first-child a.btn.btn-icon:after {
        height: 16px
    }

    .view_type li:first-child a.btn.btn-icon:after {
        top: 9px
    }

    .m_action_icons>li {
        padding: 0 0
    }

    .moderation_loading {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

@media (max-width:767px) {
    .m_grid_card {
        width: 50%
    }

    .m_grid_card.grid-2x {
        width: calc(50% * 2)
    }
}

@media (max-width:580px) {
    .m_grid_card {
        width: 100%
    }

    .subheader_main .nav.nav-tabs .nav-link .nav-text small {
        display: none;
    }

    .quick-search {
        width: 94%;
        right: 50%;
        bottom: 9px;
        left: 50%;
        transform: translate(-50%, 0%);
        margin-right: 0px !important;
    }
}

.m_rating span {
    font-size: 18px;
    margin: 0 2px;
    color: #f5c900;
}

.audioPlayer {
    width: 400px;
    height: 400px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.audioPlayer audio {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #f0f3f4;
    left: 0;
    right: 0
}

.m_list_media_holder .audioPlayer audio {
    height: 40px
}

.video_post_ico svg {
    width: 20px;
    height: 20px;
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .4));
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .4))
}

.trimPostContent {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.gridpostContent a {
    color: blue !important;
}

.listpostContent a {
    color: blue !important;
}

.m_des {
    position: relative;
    font-size: 13px;
}

.m_des .readMore_btn {
    position: absolute;
    right: 6px;
    bottom: 0px;
    background: #fff;
    padding: 0 10px;
    cursor: pointer;
}

.moderateTblWrap.list-view-content .tb_post_modal_media_holder {
    width: 100%;
    height: 100%;
}

.moderateTblWrap.list-view-content .tb_post_modal_media_holder iframe {
    width: 100% !important;
    min-width: 100% !important;
    height: 100% !important;
}

.moderateTblWrap.list-view-content .tb_post_modal_media_holder .fb_iframe_widget {
    display: flex;
    align-items: center;
}

#moderationinnerposts .grid-item .tb_post_modal_media_holder {
    position: initial;
    display: initial;
    height: auto;
    width: auto;
    align-items: inherit;
    justify-content: inherit;
}

@media (max-width: 1366px) {

    .offcanvas-title,
    .filter_wrapper .accordion .card-header h6 {
        font-size: 13px;
    }

    .m_post_title,
    .m_list_post_title {
        font-size: 12px;
    }

    .m_des,
    .m_checkbox p,
    .accordion .m_checkbox {
        font-size: 11px;
    }

    .m_card_cont .symbol.symbol-circle {
        height: 30px;
        width: 30px;
    }

    .m_bottom_actions ul li button {
        padding: 5px 4px;
        font-size: 11px;
        min-height: 30px;
    }
}

/*SW moderation css*/
.modrationPostNew .m_media_holder {
    overflow: hidden;
    margin-bottom: 10px;
}

.modrationPostNew .m_card_cont {
    padding-top: 0px;
}

.sw_theme .tags_all .img-filter-tags {
    border-color: transparent;
}

.sw_theme .m_card_cont .image-tags-section span.active,
.sw_theme .tags_all .img-filter-tags {
    background: linear-gradient(109deg, #f5a267, #d43e8e, #5364d3);
    box-shadow: none;
}

.sw_theme .m_card_cont .image-tags-section span.active:before,
.sw_theme .tags_all .img-filter-tags span:before {
    background-color: #fff;
    border-radius: 20px;
    content: "";
    display: block;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
}

.sw_theme .border-bottom span.active {
    border-bottom-width: 1px;
}

.modrationPostNew .sentiments {
    background-color: #21252966;
    justify-content: start;
    padding: 4px 5px;
    z-index: 3;
    display: flex;
    align-items: center;
    border-radius: 30px;
    margin: 7px;
}

.modrationPostNew .sentiments img {
    width: 12px;
    height: 12px;
}

.modrationPostNew .sentiments .img_quality_status {
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    visibility: hidden;
    width: 0;
    line-height: 12px;
}

.modrationPostNew .sentiments span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calc(8px + .1vw + .1vh) !important;
    padding: 0px 5px;
    color: #fff;
}

.modrationPostNew .m_card_img:hover .img_quality_status {
    width: 90px;
    opacity: 1;
    visibility: visible;
}

.modrationPostNew .m_card_img:hover .not-recommended {
    width: 110px;
}

.recomm_type_3:before {
    content: '';
    position: absolute;
    background-color: #ffffff8a;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.likes-count {
    padding: 2px 6px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
}