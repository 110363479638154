.modal-overlay--blur {
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
}

.modal-content{
    display: block !important;
    width: 100% !important;
}


.spinnerWidth{
    width:255px
}
