.exceed-section {
    font-weight: 400;
    font-size: 14px;
    color: #637079;

    .page-title {
        margin-bottom: 20px;

        h3 {
            color: #000;
            font-size: 20px;
        }

    }

    .exceed-box {
        border: 1px solid #DCE0E3;
        background: #fff;
        border-radius: 8px;
        padding: 40px 30px;

        >strong {
            // font-size: 16px;
            font-size: 20px;
            font-weight: 500;
            color: #FF0000;
            margin-bottom: 5px;
            display: block;
        }

        .exceed-inn {
            margin-top: 25px;
            margin-bottom: 25px;

            >div {
                border: 1px solid #FF4050;
                background-color: #FFF5F6;
                border-radius: 4px;
                padding: 10px 20px;
                min-width: 176px;

                strong {
                    font-size: 18px;
                    font-weight: 500;
                    display: block;
                    text-transform: capitalize;
                    color: #1A232F;
                    vertical-align: middle;
                }
            }

            >span {
                font-size: 12px;
                font-weight: 300;
                color: #637079;
                display: block;
                margin-top: 15px;
            }
        }

        .unlock-actions {
            .btn {
                font-size: 14px;
                font-weight: 500;
                height: 40px;
                padding-top: 7.5px;
                // padding-left: 24px;
                // padding-right: 24px;
                // padding-top: 9px;
                // padding-bottom: 11px;
            }

            .btn.btn-light {
                background-color: #DCE0E3;
                color: #1A232F;
            }
        }
    }
}

.te-theme {
    .exceed-section {
        .exceed-box {
            >strong {
                // color: #4179ff;
            }

            .exceed-inn {
                >div {
                    // border-color: #4179ff;
                    // background: #f1f5ff;
                }
            }

            .unlock-actions {
                .btn-primary {

                    // background-color: #4179ff;
                    // border-color: #4179ff;
                    &:hover,
                    :active {
                        color: #fff;
                        background-color: #e23442 !important;
                        border-color: #e23442 !important;
                    }
                }
            }
        }
    }
}

.exceed-accordion {
    border: 1px solid #DCE0E3;
    background: #fff;
    border-radius: 8px;
    padding: 40px 30px;

    .exceed-accordion-title {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #DCE0E3;

        strong {
            font-size: 18px;
            font-weight: 500;
            color: #1A232F;
            display: block;
            margin-bottom: 5px;
        }

        p {
            color: #637079;
            font-size: 14px;
            font-weight: 400;

            span {
                font-weight: 700;
            }
        }
    }

    #planAccordion {
        .accordion {
            .accordion-item {
                border: 0;

                .accordion-header {
                    >button {
                        padding: 15px 20px;
                        background-color: #F6F9FB;

                        &::after {
                            left: 20px;
                            top: 23px;
                        }

                        .btn-clean {
                            background-color: #DCE0E3;
                            width: 38px;
                            height: 38px;
                            border-radius: 4px;

                            &:hover {
                                background-color: #DCE0E3;
                                opacity: 0.9;
                            }
                        }
                    }

                    h6,
                    span {
                        font-size: 16px !important;
                        color: #1A232F;
                        font-weight: 500;
                    }
                }

                .planfeed-list-head,
                .card-body {
                    background-color: #F4F4F6;
                }

                .planfeed-list-head {
                    .col-sm-8 {
                        p {
                            padding-left: 15px;
                        }
                    }

                    .col-sm-4 {
                        p {
                            padding-right: 20px;
                        }
                    }
                }

                .planfeed-list-body {
                    .planfeed-list-row {
                        padding-top: 15px;
                        padding-bottom: 15px;

                        .btn-clean {
                            margin-right: 10px;
                            opacity: 0.6;
                            transition: all 0.3s ease-in-out;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    .planfeed-list-row.unlock-net-highlighter {
                        background: rgba(255, 64, 80, 0.06);
                        position: relative;

                        &::before {
                            content: '';
                            background: rgba(255, 64, 80, 0.06);
                            width: 15px;
                            position: absolute;
                            left: -15px;
                            top: 0;
                            height: 100%;
                        }

                        &::after {
                            content: '';
                            background: rgba(255, 64, 80, 0.06);
                            width: 15px;
                            position: absolute;
                            right: -15px;
                            top: 0;
                            height: 100%;
                        }

                        .symbol {
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 13px;
                                background: url(https://cloud.tagbox.com/react-app/media/exceed-limit/information-red.svg) no-repeat;
                                height: 13px;
                                background-size: contain;
                                border: 1px solid #fff;
                                border-radius: 50%;
                            }
                        }
                    }

                    p,
                    a,
                    .totol-post {
                        font-size: 13px;
                        font-weight: 600;
                        text-align: left;
                        color: #1A232F;
                    }

                    .widget-name {
                        span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .planfeed-list-row+.planfeed-list-row {
                    border-color: #DCE0E3;
                }
            }
        }

        .plan_restrict_count {
            strong {
                font-size: 16px;
                font-weight: 500;
                color: #000000;

                span {
                    border: 1px solid #DCE0E3;
                    background-color: #F6F9FB;
                    border-radius: 4px;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    vertical-align: middle;
                    line-height: 38px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
}

@media(max-width:1299px) {
    .exceed-section {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media(max-width:1199px) {
    .exceed-section .page-title h3 {
        font-size: 18px;
    }

    .exceed-accordion #planAccordion .plan_restrict_count strong span {
        width: 36px;
        height: 36px;
        line-height: 34px;
        margin-left: 7px;
        margin-right: 7px;
    }
}

@media(max-width:991px) {

    .exceed-section .exceed-box,
    .exceed-accordion {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .exceed-section .exceed-box .exceed-inn>div strong {
        font-size: 16px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header>button .btn-clean {
        width: 32px;
        height: 32px;
    }
}

@media(max-width:767px) {
    .exceed-section .page-title {
        margin-bottom: 0;
        margin-top: 5px;
    }

    .exceed-section .exceed-box,
    .exceed-accordion {
        padding: 20px;
    }

    .exceed-section .exceed-box .exceed-inn {
        margin-top: 20px;
        margin-bottom: 25px;
    }

    .exceed-accordion .exceed-accordion-title {
        padding-bottom: 10px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header h6,
    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header span {
        font-size: 14px !important;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header>button .btn-clean {
        width: 28px;
        height: 28px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header>button .btn-clean img {
        width: 16px;
        height: 16px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .planfeed-list-body .planfeed-list-row .btn-clean {
        margin-right: 6px;
    }

    .exceed-accordion #planAccordion .plan_restrict_count strong {
        font-size: 14px;
    }

    .exceed-accordion #planAccordion .plan_restrict_count strong span {
        width: 30px;
        height: 30px;
        line-height: 28px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media(max-width:575px) {
    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header>button .px-0 .d-flex {
        flex-wrap: wrap;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header span {
        margin-bottom: 2px;
        display: block;
    }
}

@media(max-width:479px) {

    .exceed-section,
    .exceed-accordion .exceed-accordion-title p {
        font-size: 13px;
    }

    .exceed-section .exceed-box>strong,
    .exceed-accordion .exceed-accordion-title strong {
        font-size: 14px;
    }

    .exceed-section .exceed-box .exceed-inn {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .exceed-section .exceed-box .exceed-inn>div {
        padding: 6px 20px;
        min-width: inherit;
    }

    .exceed-section .exceed-box .exceed-inn>div strong {
        font-size: 15px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header>button {
        padding: 10px 15px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .planfeed-list-body .planfeed-list-row {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .accordion-header>button::after {
        left: 15px;
        top: 16px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .planfeed-list-body .planfeed-list-row .btn-clean {
        margin-right: 0;
    }

    .exceed-accordion #planAccordion .plan_restrict_count strong span {
        width: 26px;
        height: 26px;
        line-height: 24px;
    }

    .exceed-section .exceed-box,
    .exceed-accordion {
        padding: 15px;
    }

    .exceed-section {
        padding-left: 0;
        padding-right: 0;
    }

    .exceed-accordion .accordion .accordion-item .accordion-cust-col {
        width: 100%;
    }

    .exceed-accordion .accordion .accordion-item .accordion-cust-del {
        position: absolute;
        right: 5px;
        top: 15px;
    }

    // #planAccordion .card .planfeed-list-head,
    .exceed-accordion #planAccordion .card .planfeed-list-body {
        // padding: 5px 0;
        padding: 0;
    }

    .exceed-accordion #planAccordion .card .planfeed-list-body .planfeed-list-row>div>div {
        padding-left: 0;
        padding-right: 0;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .planfeed-list-body .totol-post {
        font-size: 12px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .planfeed-list-body .totol-post>span {
        display: block;
    }

    .exceed-accordion #planAccordion .plan_restrict_count strong {
        font-size: 13px;
    }

    .exceed-accordion #planAccordion .accordion .accordion-item .planfeed-list-body .planfeed-list-row.unlock-net-highlighter::before,
    .exceed-accordion #planAccordion .accordion .accordion-item .planfeed-list-body .planfeed-list-row.unlock-net-highlighter::after {
        display: none;
    }
}

@media(max-width:374px) {
    .exceed-section .exceed-box .unlock-actions .btn {
        font-size: 12px;
    }
}