ul{
    padding: 0;
}

:-moz-autofill, :-moz-autofill-preview {
filter: grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%);
}

.mb-0{
    margin-bottom: 0!important;
}
  
.g2_badge img {
    margin: 0 auto;
    width: 100%;
    max-width: 356px;
}
.g2_badge {
    width: 100%;
    text-align: center;
    margin-bottom: 6vh;
}
.checkbox-squre {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    color: #545454;
    
}
.checkbox-squre > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.sourceSection.init_sec .loginInnSec h3 {
    font-weight: 700;
    text-align: left;
    font-size: 28px;
    color: #2b2b2b;
}

.sourceSection.init_sec .loginBlock.freeSocialWall .loginHead{
    margin-top:30px;
}
.checkbox-squre > span {
    background-color: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 3px;
    height: 18px;
    width: 18px;
    border-width: 2px !important;
    border: 1px solid transparent;
    margin-right: 0.75rem;
    background-color: transparent;
    border-color: #D1D3E0;
}

.checkbox-squre > span:after {
    content: '';
    border-color: transparent;
    border-style: solid;
    border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
    -webkit-transform: rotate(45de)/*rtl:ignore*/;
    transform: rotate(45deg)/*rtl:ignore*/;
    margin-top: -2px;
    width: 5px;
    height: 10px;
}

.checkbox-squre > input:checked ~ span:after {
    border-color: #2b2b2b;
}
.checkbox-squre > input:checked ~ span {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: none;
}

.checkbox-squre > input:checked ~ span {
    background-color: transparent;
    border-color: #2b2b2b;
}

.font14px {
    font-size: 14px
}

.clear {
    clear: both
}

.spacer5px {
    height: 5px;
    clear: both
}

.spacer10px {
    height: 10px;
    clear: both
}

.spacer15px {
    height: 15px;
    clear: both
}

.spacer20px {
    height: 20px;
    clear: both
}

.loginFormSec .ladda-button[data-style=expand-left][data-loading] {
    padding-left: 56px!important
}
.login-container {
    position: relative;
    padding: 0;
    overflow: hidden
}
.loginInnSec {
    position: relative
}

.loginInnSec h3 {
    text-align: center;
}

.loginHead {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
    position: relative;
}

.signup_wrapper .loginHead:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -16px;
    height: 1px;
    width: 160px;
    background-color: #ebedf3;
    margin: 0 auto;
}

.loginHead p {
    margin-top: 10px;
    margin-bottom: 26px;
    font-size: 14px;
    color: #545454;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loginHead p span svg{
    margin-right: 5px;
}
span.seprate_dot {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #545454;
    display: inline-block;
    margin: 5px 5px;
}
.loginBlock p {
    color: #6e7073
}

.loginBlock .form-group {
    margin-bottom: 20px;
    clear: both;
    position: relative;
}

.loginBlock .form-group.mb0 {
    margin-bottom: 15px
}

.loginBlock .form-group label {
    font-size: 14px;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.loginBlock label.loggedinIco:hover {
    cursor: pointer
}

.loginBlock input[type=checkbox] {
    margin-right: 10px
}
.login-container .form-control{
    border-radius: 4px;
}
.login-container .form-control:focus {
    color: #3F4254;
    background-color: #ffffff;
    border-color: #959595;
    outline: 0;
    box-shadow: none;
}

.show_password_icon {
    position: absolute;
    right: 8px;
    top: 7%;
    display: flex;
    height: 86%;
    align-items: center;
    border: none;
    background-color: #fff;
    padding: 0 8px;
    align-items: center;
    justify-content: center;
}
.show_password_icon:hover svg path {
    fill: #2b2b2b;
}
#filled_path, .view_pass #blank_path{
    display: none;
}

.view_pass #filled_path {
    display: block;
}

.view_pass svg path{
    fill:#545454;
    transition: all .3s;
}

.passwordSecion .password_wrap {
    position: relative;
}
.loginBlock a {
    color: #ff4051;
    font-size: 14px;
    text-decoration: none;
}

.loginBlock a.pull-right:hover {
    color: #ff4051;
}

.loginBlock input.btn.ladda-button {
    background: #ca2b4f;
    padding: 8px 25px;
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .5px;
    transition: all ease .3s;
    -webkit-transition: all ease .3s;
    margin-bottom: 10px
}

.loginBlock input.btn:hover {
    background: #323232
}

.loginBlock .btn:focus,
.loginBlock .btn:hover {
    color: #fff;
    outline: 0
}

.loginBlock .btnSignUp {
    color: #ff4051;
    font-weight: 600;
    text-decoration: none
}

.loginBlock .btnSignUp:hover {
    color: #ff4051;
}

.loginWithSocials {
    position: relative
}

.loginSocials {
    text-align: center;
}

.loginSocials h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 24px;
    width: 100%;
    display: block;
    color: #EBEDF3;
    position: relative;
    margin-top: 20px;
}

.loginSocials h2 span {
    background: #fff;
    padding: 0 10px;
    position: relative;
    z-index: 10;
    font-size: 18px;
    color: #545454;
    margin-top: 4px;
    display: inline-block;
}
.loginSocials h2::after {
    content: '';
    width: 100%;
    height: 1px;
    background: #EBEDF3;
    position: absolute;
    bottom: 0;
    top: 15px;
    left: 0;
}
.loginSocials p {
    font-size: 14px;
    margin-bottom: 20px;
}

.loginFormSec .loginform button {
    margin-top: 7px!important;
    margin-bottom: 15px;
    width: auto;
    line-height: 22px;
    color: #fff
}

.loginFormSec .loginform button:hover {
    color: #fff
}
.formControlMin .loginBlock .alert {
    padding: 5px 10px
}
.flashNotification{
    margin: 12px 0px;
}
.flashNotification:before{
    border: 10px solid;
    border-color: #0000 #0000 #f2f6f8;
    bottom: 100%;
    content: " ";
    height: 0;
    left: 26px;
    pointer-events: none;
    position: absolute;
    width: 0;
}

.formControlMin .loginBlock .alert p {
    font-size: 12px
}

.formControlMin .loginBlock .alert.alert-success {
    background-color: #abedb9;
    border-color: #abedb9
}

.formControlMin .loginBlock .alert.alert-danger p {
    color: #e73d4a
}

.formControlMin .loginBlock .alert.alert-success p {
    color: #1f562a
}

.formControlMin .loginBlock .alert.alert-info p {
    color: #304865
}

.formControlMin .loginBlock .alert.alert-warning p {
    color: #304865
}

.loginBlock.recoverPass {
    border-right: 1px solid #ccc!important;
    margin: 0 auto!important;
    float: none;
    width: 380px;
    max-width: 100%
}

.login-container .login-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.login-container .login-footer .login-copyright p {
    margin: 0;
    font-size: 13px;
    color: #6e7073;
    text-align: center;
}

.error-message,
.errorMessage {
    color: #e02222;
}

.formControlMin .form-control.form-error {
    border: 1px solid #e02222;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.bgNone {
    background: 0 0!important
}

.signUpForm.extemail .loginBlock .loginInnSec,
.signUpForm.resetPassword .loginBlock .loginInnSec {
    padding: 20px 30px
}

.signUpForm.extemail .loginHead.text-center {
    margin-top: 40px
}
.skip_btn_wrap p {
    text-align: center;
}

a.skip_btn {
    font-weight: 600;
    color: #6e7073;
    text-decoration: none;
}
.commanBtn.gredientBtn,
.commanBtn.gredientBtn:focus,
.commanBtn.gredientBtn:hover {
    background: #c83b77;
    background: -moz-linear-gradient(45deg, #c83b77 0, #e06a73 100%);
    background: -webkit-linear-gradient(45deg, #c83b77 0, #e06a73 100%);
    background: linear-gradient(45deg, #c83b77 0, #e06a73 100%)
}

.commanBtn.simplebckbtn,
.commanBtn.simplebckbtn:focus,
.commanBtn.simplebckbtn:hover {
    background: #fff;
    border: 1px solid #6e7073;
    color: #6e7073!important
}

.loginBlock .note {
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px
}

.loginBlock .note.note-success {
    padding: 5px 10px;
    background: #1f562a
}

.loginBlock .note p {
    color: #fff
}
.sourceSection .loginFormSec {
    width: 100%;
    max-width: 100%;
    padding:0;
}


.loginBlock.freeSocialWall .loginHead {
    margin: 0 0 40px 0;
}

.loginBlock.picASource h2 {
    font-size: 32px;
    text-transform: capitalize;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
    display: block;
    color: #323232;
}

div#networksList {
    margin: 0 auto;
    display: block;
    max-width: 880px;
}

.picASource .networks {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: center;
}

.picASource .networks li {
    position: relative;
    width: 50%;
    min-height: 180px;
    text-align: center;
    margin: 0px;
    padding-bottom: 30px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    max-height: 290px;
}

.picASource ul.networks li a span{
    padding-right: 10px;
}


.picASource ul.networks li a img {
    width: 50px;
    min-width: 50px;
}
.picASource .networks li a, .second_screen_init a {
    padding: 45px 30px 30px 30px;
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    border: 1px solid transparent;
    -webkit-transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    background-color: #fff;
    position: relative;
    min-height: 260px;
    height: 100%;
    text-decoration: none;
}

.picASource .networks li a:hover span{
    color: #2b2b2b;
}

.picASource  ul.networks li a {
    -webkit-transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    -moz-transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    -o-transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    transition: all 0.6s cubic-bezier(.165,.84,.44,1);
}

.picASource  ul.networks li a:hover {
    -webkit-transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    -moz-transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    -o-transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    transition: all 0.6s cubic-bezier(.165,.84,.44,1);
}
.page-header.navbar .page-logo {
    float: left;
    display: block;
    width: 265px;
    padding-left: 20px;
    padding-right: 20px;
}
.first_screen_init .fa-circle-thin, .first_screen_init .fa-check-circle, .second_box_bg .fa-check-circle {
    position: absolute;
    right: 10px;
    top: 10px;
}
.first_screen_init .fa-circle-thin {
    color: #afafaf;
    transition-duration: 0.5s;
}

.icon_wrap {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.loginFormSec .first_screen_init h3, .second_screen_init h3 {
    line-height: inherit;
    text-align: left;
    font-size: 26px;
    font-weight: bold;
    text-transform: capitalize;
    color: #2B2B2B;
    margin: 0;
}

.loginform h3 {
    padding: 8px 0px;
}

.password_wrap {
    position: relative;
    display: block;
}
 
.picASource .networks li p, .second_screen_init p {
    text-align: left;
    font-size: 16px;
    color: #2B2B2B;
}
span.pro_sub_title {
    background-color: #EAEAEA;
    padding: 5px 10px;
    border-radius: 30px;
    font-size: 12px;
    text-transform: uppercase;
    color: #2b2b2b;
    font-weight: 400;
}

.picASource .networks li a:hover {
    box-shadow: 0 5px 10px rgba(0,0,0,.14);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.new_login_init_display_class span.pro_sub_title {
    background-color: #ffdddf;
}
.new_login_init_Widget_class span.pro_sub_title {
    background-color: #e6e6ff;
}
.new_login_init_commerce_class span.pro_sub_title {
    background-color: #f7e8ff;
}
.new_login_init_ugc-rights_class span.pro_sub_title {
    background-color: #e3fbee;
}

.picASource .networks li a.new_login_init_social_class.active,
.picASource .networks li a.new_login_init_social_class:hover{
    border-color: #F83EB5;
}
.picASource .networks li a.new_login_init_display_class.active,
.picASource .networks li a.new_login_init_display_class:hover{
    border-color: #dc363f;
}
.picASource .networks li a.new_login_init_Widget_class.active,
.picASource .networks li a.new_login_init_Widget_class:hover{
    border-color: #6261F9;
}
.picASource .networks li a.new_login_init_signage_class.active,
.picASource .networks li a.new_login_init_signage_class:hover{
    border-color: #F25025;
}
.picASource .networks li a.new_login_init_commerce_class.active,
.picASource .networks li a.new_login_init_commerce_class:hover{
    border-color: #5354FC;
}
.picASource .networks li a.new_login_init_ugc-rights_class.active,
.picASource .networks li a.new_login_init_ugc-rights_class:hover{
    border-color: #00E17D;
}
.picASource .networks li a.new_login_init_smart-curation_class.active,
.picASource .networks li a.new_login_init_smart-curation_class:hover{
    border-color: #2593F1;
}

.choose_latr {
    margin: 15px;
}

.choose_latr a {
    text-decoration: underline;
    font-size: 16px;
    color: #545454;
}

.choose_latr a:hover{
    color: #2b2b2b;
}

.loginFormSec .loginform button span#typeIcon {
    font-size: 26px;
    color: #333;
    line-height: 18px
}

.loginFormSec .loginform button span#typeIcon i.fa.fa-caret-down {
    font-size: 22px;
    color: #b8b8b8;
    margin-left: 5px
}

.first_screen_init .fa-circle-thin {
    color: #afafaf;
    transition-duration: .5s
}

.first_screen_init .fa-check-circle {
    color: #6CC259;
    transition-duration: .5s;
}

.box_showingiton .fa-circle-thin {
    color: #afafaf;
    font-size: 22px;
    transition-duration: .5s;
    float: right
}

.box_showingiton .fa-check-circle {
    color: #44ca92;
    font-size: 22px;
    transition-duration: .5s;
    float: right
}

.box_showingiton_active .fa-circle-thin {
    color: #afafaf;
    font-size: 22px;
    transition-duration: .5s;
    float: right
}

.box_showingiton_active .fa-check-circle {
    color: #44ca92;
    font-size: 22px;
    transition-duration: .5s;
    float: right
}

.second_box_bg .fa-check-circle {
    color: #44ca92!important
}

.third_init_showingiton_individual {
    padding: 25px
}

.fa_third_init_div {
    padding-right: 21px
}
.loginBlock a.bckBtnSource {
    color: #bbb;
    font-size: 14px;
    text-decoration: none
}

.displywallpage .loginBlock a.bckBtnSource {
    display: none
}

.displywallpage .loginBlock a.bckBtnSource.skipBtn {
    display: inline
}

.testimonials {
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 5vh;
}

.form_content{
    min-height: 100vh;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    padding: 0 4vh;
}

.sourceSection {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 65px);
}

form#WallIndexForm {
    margin: 0;
}

.form_side_content:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: auto;
    top: 0;
    right: 0;
    background-position: left center;
    z-index: 5;
    background-size: 100%;
    background-repeat: no-repeat;
}

.form_cont_in {
    color: #545454;
    z-index: 99;
    max-width: 770px;
    padding: 6vh 0;
}

.form_cont_left {
    position: relative;
    z-index: 999;
}

.form_cont_left p {
    margin-bottom: 15px;
    line-height: 1.4;
    font-size: 14px;
    text-align: left;
}

.o_event{
    text-indent: 0px;
}

.o_event strong {
    /* text-transform: uppercase; */
    font-weight: 600;
    /* margin-bottom: 6px; */
    display: inline-block;
    color: #545454;
    text-indent: 0px;
}

.o_event p {
    font-size: 12px;
    color: #545454;
    text-indent: 0px;
    margin-bottom: 0;
}
 
.row.custom-row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
}

.col-sm-12.custom-col-12 {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: none;
}

.col-sm-6.custom-col-6 {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: none;
}

.col-sm-4.custom-col-4,
.col-sm-8.custom-col-8 {
    float: left;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}


.login-container .custom-row .custom-col-12 {
    min-height: 100vh;
    height: 100%;
}

@media (min-width: 1025px){
.col-sm-4.custom-col-4{
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

/* .custom-col-8.col-sm-8.form_screen {
    padding-right: 0;
} */

.col-sm-8.custom-col-8{
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
}

.trusted_by h4 {
    font-size: 1.1vw;
    margin-bottom: 2vh;
    font-weight: 600;
    text-align: center;
}

.trusted_by {
    margin:0 auto;
    display: block;
}

ul.clients_logo {
    position: relative;
    text-align: center;
}

ul.clients_logo li img {
    max-width: 70px;
    margin: 0 auto;
    max-height: 28px;
}
ul.clients_logo li:nth-child(2) img {
    max-width: 55px;
}

ul.clients_logo li {
    display: inline-block;
    padding: 10px;
    min-width: 100px;
    vertical-align: middle;
}

.o_event {
    text-align: left;
    display: block;
    margin-top: 10px;
}

.trusted_by h5 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-top: 2vh;
    text-align: center;
}

.loginHead h2 {
    font-size: 1.5vw;
    font-weight: 700;
    margin: 0;
    color: #2b2b2b;
}

.iti.iti--allow-dropdown {
    width: 100%;
}

.form_content_wrap {
    min-height: calc(100vh - 115px);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    padding:60px 15px;
}

.loginFormSec {
    max-width: 520px;
    margin: 0px auto;
    padding: 30px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 2px 4px rgb(0 0 0 / 4%), 0 5px 14px rgb(0 0 0 / 2%);
    border-radius: 3px;
    margin-bottom: 26px;
}

.loginSocials ul li {
    width: 28%;
    padding: 0 5px;
}

.loginSocials ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -5px;
    margin-left: -5px;
    list-style: none;
}

.loginSocials ul li button {
    display: block;
    background-color: #fff;
    width: 100%;
    border: 1px solid #EBEDF3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.loginSocials ul li button svg {
    margin-right: 6px;
    height: 20px;
    width: 20px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
}

.loginSocials ul li button span {
    font-weight: 500;
    font-size: 13px;
    color: #545454;
}

.loginSocials ul li button:hover {
    background-color: #F2F6F8;
}

.form-group.remember_check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.form-group p {
    color: #2b2b2b;
}

button:focus, button:active, button:hover{
    outline: none !important;
}
.loginFormSec .spinnerButton {
    background-color: #ff4051;
    padding: 12px 15px;
    margin-top: 0px;
    transition: all .3s;
    width: 100%;
    color: #fff;
    font-weight: 600;
}

.loginFormSec .spinnerButton:hover {
    background-color: #e23442;
}

.loginFormSec .form-group .error-message, .loginFormSec .form-group .errorMessage {
    line-height: 14px;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 0;

}

.form_side_content {
    background-color: #fff;
    overflow: hidden;
}

.logoLogin {
    /* padding: 15px 15px 0 15px; */
    text-align: center;
    margin-bottom: 3vh;
}

.g-recaptcha {
    transform: scale(.7);
    -webkit-transform: scale(.7);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    height: 55px;
}

.form_cont_in h3 {
    font-size: 1.5vw;
    margin-bottom: 4vh;
    font-weight: 600;
    text-align: center;
}

.client_img {
    /* width: 50px;
    height: 50px; */
    background-size: cover;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    transition: all .3s;
    position: relative;
    /* opacity: .6; */
    /* margin: 10px auto; */
}
.client_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.form_cont_left .carousel-indicators li {
    width: auto;
    height: auto;
    background-color: transparent;
    transition: all .3s;
    position: relative;
    border: none;
    display: none;
}

.form_cont_left .carousel-indicators li.active {
    display: block;
}

.client_img:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 2;
    border-radius: 50%;
    transition: all .3s;
}

.form_cont_left .carousel-indicators li.active .client_img:after {
    /* box-shadow: 0px 0px 0px 2px #fff;
    transition: all .3s; */
}

.form_cont_left .carousel-indicators li.active .client_img{
    /* -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    transition: all .3s;
    opacity: 1; */
}

.form_cont_left .carousel-indicators {
    /* position: relative;
    left: 0;
    width: auto;
    text-align: center;
    margin: 0;
    bottom: 0;
    padding: 15px 0; */
    width: 170px;
    padding-left: 15px;
    padding: 0;
}

ul.star-rating {
    margin-bottom: 10px;
    display: block;
    text-align: left;
}

ul.star-rating li {
    display: inline-block;
    color: #ffd501;
    padding-right: 4px;
    font-size: 22px;
}

ul.star-rating li i{
    font-size: 18px;
}

.loginFormSec .form-group p {
    position: relative;
    display: block;
    padding: 5px 0;
    margin: 0;
    text-align: center;
}

.logoLogin span {
    display: inline-block;
    line-height: 30px;
    font-family: 'Comfortaa', cursive;
    color: #404040;
    padding-left: 10px;
    font-size: 22px;
    vertical-align: middle;
}

.logoLogin img {
    display: inline-block;
    vertical-align: middle;
}

.logoLogin a:focus,
.logoLogin a:hover {
    text-decoration: inherit;
}

@media (max-width:1600px) {
.loginHead h2 {
    font-size: 26px;
}
.picASource ul.networks li a img {
    width: 48px;
    min-width: 48px;
}
.form_side_content:before {
    background-size: 115%;
}

.loginFormSec {
    max-width: 450px;
}

.loginSocials ul li button {
    padding: 8px 5px;
}
/* .client_img, .client_img:after {
    width: 56px;
    height: 56px;
} */

ul.clients_logo li img {
    max-width: 50px;
}
ul.clients_logo li:nth-child(2) img {
    max-width: 42px;
}
.loginSocials h2 {
    font-size: 16px
}
.loginHead {
    margin-bottom: 20px
}
.formControlMin .loginBlock input.form-control {
    height: 44px
}

.loginFormSec .spinnerButton {
    padding: 10px 15px;
}
.loginSocials ul li button svg {
    width: 18px;
}

.loginSocials ul li button {
    padding: 7px 5px;
}

ul.clients_logo li {
    min-width: 70px;
}

.loginSocials h2 {
    margin-bottom: 20px
}
.loginFormSec .ladda-button[data-style=expand-left][data-loading] {
    padding-left: 42px!important
}
.note-text {
    display: block
}

.loginFormSec .form-group p{
    font-size: 14px;
    padding: 0;
}

}

@media (max-width:1440px) {
.note-text {
    bottom: 95px!important
}
 
.loginSocials ul li button i {
    height: 40px;
    width: 40px;
    line-height: 38px;
    font-size: 20px;
}

.form_cont_left p {
    font-size: 12px;
}

.form_cont_in h3 {
    font-size: 24px;
}

.trusted_by h4 {
    font-size: 22px;
}

.o_event p,
.o_event strong{
    font-size: 12px;
}

}

@media (max-width:1366px) {
.picASource .networks {
    margin-left: -10px;
    margin-right: -10px;
}
.picASource .networks li {
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
}
.picASource .networks li {
    min-height: 255px;
}

.first_screen_init .fa-circle-thin, .first_screen_init .fa-check-circle, .second_box_bg .fa-check-circle {
    right: 8px;
    top: 8px;
}

.second_box_bg {
    padding: 40px!important
}
.form_cont_left div .d-flex{
    flex-direction: column;
    align-items: center;
}
.form_cont_left div .item{
    width: 100% !important;
}
.form_cont_left div .item ul.star-rating{
    text-align: center;
}
.form_cont_left p{
    text-align: center;
}
.form_cont_left div .item .o_event{
    text-align: center;
}
}

@media (max-width:1024px) {
.form_side_content{
    display: none;
}

.loginSocials ul li button svg {
    margin-right: 6px;
    width: 20px;
}
.logoLogin {
    text-align: center;
    margin-bottom: 0px;
}

.loginBlock.picASource h2{
    font-size: 28px;
}

.loginBlock.freeSocialWall .loginHead {
    margin: 0 0 15px 0;
}

.loginFormSec .first_screen_init h3 {
    font-size: 22px;
}

}

@media (max-width:991px) {
.loginSocials ul li {
    padding: 0 5px;
}

.loginSocials ul {
    margin-right: -5px;
    margin-left: -5px;
}

.loginSocials ul li button i {
    background-color: #2b2b2b;
    height: 40px;
    width: 40px;
    line-height: 38px;
    font-size: 20px;
}

.picASource .networks li a {
    padding: 40px 20px 20px 20px;
    max-width: 400px;
    margin: 0 auto;
}

.loginFormSec .loginform button span#typeIcon {
    font-size: 24px
}
.loginFormSec .loginform button span#typeIcon i.fa.fa-caret-down {
    font-size: 16px;
    margin-left: 2px
}
.second_box_bg {
    padding: 15px
}
.picASource ul.networks li a img {
    width: 44px;
    min-width: 44px;
}
}

@media (max-width:767px) {
.picASource .network.setNetwork i {
    font-size: 20px !important;
    right: 4px;
    top: 4px;
}
.picASource .networks li {
    width: 100%;
    flex: 1 0 100%;
    max-width: 100%;
}

.loginSocials h2 {
    margin-bottom: 15px!important
}

.loginHead p,
.loginSocials p{
    font-size: 12px
}
.loginHead {
    margin-bottom: 25px
}
.loginSocials .spacer30px {
    height: 1px;
    clear: both
}
.thanksPage {
    margin-bottom: 40px
}
.login-container {
    min-height: auto
}
.login-container .login-footer {
    position: relative;
    bottom: auto;
    overflow: hidden
}
.displayErrorpage .errorImageInit img {
    width: 100px
}
.displayErrorpage .errorImageInit {
    margin: 10px auto 20px auto
}
.displayErrorpage .loginFormSec {
    max-width: 100%
}
.displayErrorpage .errorTitleInit {
    font-size: 18px
}
.displayErrorpage .errorPeraInit {
    font-size: 16px;
    margin-bottom: 10px
}
.displayErrorpage .commanBtn.btn-md {
    padding: 10px 0;
    width: 100px!important;
    font-size: 14px
}

}

@media (max-width:640px) {
.loginBlock a.pull-right {
    margin-top: 0
}
.loginBlock.recoverPass {
    border-bottom: 1px solid #ccc!important
}
.picASource .networks li {
    width: 100%;
    flex: 1 0 100%;
    min-height: auto;
}

.picASource .networks li a{
    min-height: 215px;
}
.picASource .networks li a {
    padding: 20px 20px 20px 20px;
}

.loginSocials ul li button {
    font-size: 14px;
    padding: 8px 5px;
}
.loginSocials ul li button span {
    display: none;
}

}

@media (max-width:480px) {
.signUpForm {
    padding: 0
}
.loginHead.text-center {
    text-align: left
}

.logoLogin img {
    max-height: 46px;
}
.logoLogin span {
    line-height: 20px;
    padding-left: 5px;
    font-size: 16px;
}
.loginFormSec {
    padding: 15px;
}
}

.error-note{
    font-size: 12px;
    color: #9d9d9d;
    margin-top: 5px;
}
.error-note ul{
    padding-left: 30px;
}
.progress {
    height: 5px;
}
.loginInnSec .progress-bar-success {
    background-color: #02a100;
}
.text-success {
    color: #02a100;
}
.text-danger {
    color: #ff0000;
}
.loginInnSec .progress-bar-danger {
    background-color: #ff0000;
}
.loginInnSec .progress-bar {
    -webkit-box-shadow:none;
    box-shadow: none;
}

#popover-password {
    margin-top: 8px;
}
.verified_checkpoints {
    margin-top: 25px;
    margin-bottom: 25px;
}
.verified_checkpoints li span{
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e90404;
    font-size: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.verified_checkpoints li {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 12px;
    font-weight: 500;
    color: #545454;
    margin-bottom: 4px;
}


.loginBlock .progress {
    -webkit-box-shadow: none;
    box-shadow: none;
}
i.point_ver__ svg {
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
}
.point_ver__{
    background-image: url('https://cloud.tagbox.com/react-app/media/form-icons/close_icon.svg');
    height: 14px;
    width: 14px;
    background-size: cover;
    background-position: center;
}
.verified_checkpoints li.active span{
    background-color: #6ac259;
}
.verified_checkpoints li.active .point_ver__{
    background-image: url('https://cloud.tagbox.com/react-app/media/form-icons/check_icon.svg');
}

.loginInnSec .progress-bar-warning {
    background-color: #F6A71C;
}

.text-warning {
    color: #F6A71C;
}

.loginFormSec.position-relative{
    position: relative;
}
.terms-condition-link{
    margin-bottom: 10px;
}
.terms-condition-link p{
    margin: 0px;
}
.terms-condition-link p a{
    color: #545454;
    font-weight: 600;
}

.terms-condition-link p a:hover{
    text-decoration: underline;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .form_content_wrap{
        min-height: auto;
        padding-top: 30px;
        padding-bottom: 20px;
    }
    .login-footer{
        position: relative !important;
        margin-top: 10px !important;
    }
}
@media (max-width: 767px) {
    .form_content_wrap{
        min-height: auto;
        padding-top: 20px;
        padding-bottom: 30px;
    }
}
@media (max-width: 576px) {
    .form_content_wrap{
        margin: 0px auto;
        padding-bottom: 15px;
    }
    .terms-condition-link{
        padding: 0px 15px;
    }
    .login-container .login-footer .login-copyright p{
        font-size: 12px;
    }
}
#onboardingList .onboardings{
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
#onboardingList .onboarding-itme{
    display: flex;
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
}
#onboardingList .onboarding-itme input{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin: 0px;
    opacity: 0;
    z-index: 1;
}
#onboardingList .onboarding-itme .onbording-lable{
    display: flex;
    background-color: #fff;
    padding: 6px 15px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px #28324d1d;
    align-items: center;
    margin: 0px;
    -webkit-transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    transition: all 0.6s cubic-bezier(.165,.84,.44,1);
    border:1px solid #fff;
} 
#onboardingList .onboarding-itme .onbording-lable span{
    background-color: #F2F3F7;
    padding: 6px;
    border-radius: 4px;
    margin-right: 8px;
}
#onboardingList .onboarding-itme .onbording-lable label{
    margin: 0px;
    color: #2B2B2B;
    line-height: 1.4;
    font-weight: 400;
}
#onboardingList .onboarding-itme input:checked ~ .onbording-lable{
    background-color: #1A2029;
}
#onboardingList .onboarding-itme:hover .onbording-lable{
    box-shadow: 0 5px 10px rgb(0 0 0 / 14%);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    border-color: #1A2029;
}
#onboardingList .onboarding-itme input:checked ~ .onbording-lable span{
    background-color: #F2F3F7;
}
#onboardingList .onboarding-itme input:checked ~ .onbording-lable span svg path{
    fill:#fff;
}
#onboardingList .onboarding-itme input:checked ~ .onbording-lable label{
    color: #fff;
}

.start-button-box{
    margin-top: 120px;
}
.start-button-box p{
    text-align: center;
}
#onbordingInput ~ .start-button-box{
    margin-top:50px;
}
.calendlyAclick .btn-link{
    color: #ff4051;
    padding: 0px;
}
.calendlyAclick .btn-link:hover{
    color: #ff4051;
    text-decoration: underline;
}
.calendly-overlay .calendly-popup-close{
    background:url(https://assets.calendly.com/assets/external/close-icon.svg) !important;
    background-size: cover !important;
}
@media screen and (max-width: 767px) {
    .start-button-box{
        margin-top: 20px;
    }
}
@media screen and (max-width: 480px) {
    #onboardingList .onboarding-itme,
    #onboardingList .onboarding-itme .onbording-lable{
        width: 100%;
        margin-right: 0px;
    }
    #onboardingList .onboarding-itme{
        margin-bottom: 10px;
    }
    #onboardingList .onboarding-itme .onbording-lable{
        padding: 6px 15px;
    }
}