#root-editor{
    height: 100vh;
    width: 100%;
}

.btn-close {
    float: right;
}

.topbar .dropdown-toggle::after {
    content: none;
}

:not(.btn-check)+.btn.pro_head_icon:hover {
    background-color: #fff;
    border-color: #fff;
}

@media (max-width: 991px){
.header-menu-mobile .menu-submenu.menu-submenu-classic {
    display: block;
    box-shadow: none;
    position: relative !important;
    inset: auto !important;
    transform: none !important;
}
}

@media (min-width: 992px){
.header-menu .menu-nav {
    height: 100%;
}

}
@media screen and (max-width: 767px){
.mega_menu_inner {
    overflow-y: auto;
}
}