.publish-tab {
    .nav-tabs {
        border: 0px;
        padding: 6px 10px;
        border-radius: 4px;
        place-self: flex-start;
        background-color: #fff;

        li {
            .nav-link {
                padding: 8px 15px;
                border-radius: 4px;
                font-weight: 400;
                color: #637079;

                &.active {
                    background-color: #FF4051;
                }
            }

            &:last-child {
                button.active {
                    img {
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }
    }

    .more-options {
        >div>div {
            .card {
                transition: all 0.3s ease-in-out;

                p {
                    color: #545454;
                }
            }

            &:hover {
                .card {
                    border-color: transparent;
                    box-shadow: 0 0 10px #eeee;
                    cursor: pointer;
                }


            }
        }
    }

    .tab-content {
        .display_modal {
            height: 100%;
            position: relative;

            .publish_wrap {
                height: 100%;
                position: relative;
            }
        }

        .publish_sidebar {
            left: 0;
            padding: 0px;
            height: inherit;
            transform: none;
            position: relative;
            border-radius: 8px;
            border-right: 1px solid #DCE0E3;
            border-radius: 0;
            width: 230px;

            >strong {
                display: block;
                font-size: 14px;
                font-weight: 600;
                color: #1A232F;
                padding: 15px 30px 10px 10px;
            }

            .publish_toggle_icon {
                display: none;
            }

            .publish_sidebar_area {
                height: auto;
                margin-top: 0;
                padding: 15px 30px 15px 10px;
            }
        }

        .display_hashtag,
        .display_email {
            .publish_sidebar {
                width: inherit;
                border-radius: 8px 0 0 8px;

                .publish_sidebar_area {
                    padding: 20px 15px;

                    >h5 {
                        padding-bottom: 5px;
                    }
                }
            }
        }

        .display_hashtag {
            .tab-content {
                .full_container {
                    width: 100%;
                }
            }
        }

        .full_container,
        .hastag-tabcontent {
            padding: 0;
            width: 100%;
            max-width: 100%;
            box-shadow: none;
            border-radius: 8px;
            background-color: #fff;

            .tag-based-widget-toggle {
                padding: 10px 15px;
                border-radius: 4px;
                background-color: #f3f6f9;

                label,
                em {
                    font-size: 14px;
                    color: #2B2B2B;
                    font-weight: 600;
                    font-style: normal;

                    >input+span {
                        display: none;
                    }
                }

                .checkbox.checkbox-outline.checkbox-primary>input:checked~span,
                .checkbox.checkbox-outline.checkbox-primary>input:checked~span:after {
                    border-color: transparent;
                }
            }

            .card {
                box-shadow: 0px 0px 12px 0px #95A9C240;
            }
        }

        .full_container {
            .review-tab-main {
                padding: 3px 3px 4px;
                border: 1px solid #DCE0E3;
                display: table;
                margin-left: auto !important;
                margin-right: auto !important;

                li.nav-item {
                    margin: 0;
                    display: inline-block;

                    .nav-link {
                        width: 100%;
                        font-size: 14px;
                        font-weight: 500;
                    }

                    .nav-link.active {
                        color: #fff;
                        background-color: #1A232F;
                    }
                }

            }

            .embed-tab-main {
                border-top: 1px solid #DCE0E3;

                .card.card-custom {

                    .tab-content {
                        h2 {
                            font-size: 14px;
                        }



                        .embed_code_wrap {
                            border-radius: 4px;
                        }

                        .PreviewTagsWallBtn,
                        .embedTagsGenerate {
                            color: #fff;
                            font-size: 14px;
                            font-weight: 500;
                            padding: 7px 24px;
                            border-radius: 4px;
                            margin-left: 10px;
                            max-height: 37px;
                        }

                        .PreviewTagsWallBtn {
                            border-color: #1A232F;
                            background-color: #1A232F;
                        }

                        .PreviewTagsWallBtn.spinner,
                        .embedTagsGenerate.spinner {
                            font-size: 0;
                            width: 153px;

                            &::before {
                                left: 0;
                                right: 0;
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                    }
                }
            }

            .emebed_action {
                bottom: inherit;
                top: -15px;

                button {
                    background-color: #1A232F;
                    border-radius: 4px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 4px 12px !important;

                    &:active {
                        border-color: #1A232F;
                    }
                }
            }

            .following_steps {
                .count-badge {
                    background-color: #FF4051;
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    color: #fff;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 24px;
                    margin-right: 3px;
                }
            }

        }

        .rating-schema-main {
            max-width: 100%;
            background-color: #fff;
            border-top: 1px solid #DCE0E3;

            .rs-left {
                padding-top: 30px;
                padding-left: 10px;
                padding-right: 40px;
                border-right: 1px solid #DCE0E3;
                .behind-blur {
                    // background: yellow;
                    top: 0;
                    height: 100%;
                    transform: none;
                }

                .rs-select-feed {
                    .options-dropdown {
                        margin-top: 10px;
                        max-width: 80%;

                        button {
                            background-color: #F4F4F6;
                            border: 1px solid #DCE0E3;

                            label {
                                span {
                                    font-size: 14px;
                                    color: #1A232F;
                                    font-weight: 400;
                                }
                            }

                            &::after {
                                font-style: normal;
                                font-weight: 400;
                                font-variant: normal;
                                height: 16px;
                                border: 0;
                                transform: rotate(90deg);
                                content: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 width=%2718%27 height=%2718%27%3E%3Cpath fill=%27none%27 d=%27M0 0h24v24H0z%27/%3E%3Cpath d=%27M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z%27 fill=%27%23aaa%27 /%3E%3C/svg%3E");
                            }
                        }

                        >div>div:not(:last-child) {
                            border-bottom: 1px solid #DCE0E3;
                        }
                    }
                }

                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #1A232F;
                }

                .rs-code-section,
                .rs-serp-section {
                    margin-top: 30px;
                    margin-bottom: 15px;

                    strong {
                        display: block;
                        font-size: 14px;
                        font-weight: 600;
                        color: #1A232F;
                        margin-bottom: 15px;
                    }
                }

                .rs-code-section {
                    .embed_code_wrap {
                        padding: 0;
                        border-radius: 4px;
                        background-color: snow;

                        .tab-content {
                            border-radius: 4px;

                            textarea {
                                border-radius: 4px !important;
                                overflow-y: auto;
                            }

                            #copy-success-alert {
                                top: inherit;
                                bottom: 10px;
                                right: 15px;
                            }

                            .emebed_action {
                                bottom: inherit;
                                top: -15px;

                                button {
                                    background-color: #1A232F;
                                    border-radius: 4px;
                                    color: #fff;
                                    font-size: 12px;
                                    font-weight: 500;
                                    padding: 4px 12px !important;

                                    &:active {
                                        border-color: #1A232F;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .rs-right {
                padding-top: 30px;
                padding-left: 40px;
                padding-right: 10px;

                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #1A232F;
                    margin-bottom: 15px;

                    &:not(:first-child) {
                        margin-top: 40px;
                    }
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 10px;
                    color: #637079;
                }

                ul {
                    margin: 10px 0 20px;
                    padding: 0;

                    li {
                        font-size: 14px;
                        font-weight: 400;
                        margin-top: 10px;
                        color: #637079;
                        padding-left: 20px;
                        margin-bottom: 15px;
                        position: relative;

                        &::before {
                            content: "";
                            width: 10px;
                            z-index: 1;
                            top: 8px;
                            left: 4px;
                            height: 6px;
                            position: absolute;
                            background: url(https://cloud.tagbox.com/react-app/media/images/rating-schema-arrow.png);
                            background-size: contain;
                            background-repeat: no-repeat;
                        }

                        &::after {
                            content: "";
                            top: 5px;
                            left: 0;
                            width: 12px;
                            height: 12px;
                            position: absolute;
                            border-radius: 50%;
                            background-color: #FF4050;
                        }
                    }
                }
            }
        }

        .maintainPopUp {
            .autoSuggestionList {
                .dropdown-menu-list {
                    li {
                        padding-left: 10px;
                        padding-right: 10px;

                        a {
                            &:hover {
                                border-radius: 4px;
                            }
                        }
                    }
                }
            }
        }

        .accordion {
            .card {
                box-shadow: none;
                border-radius: 4px;
                border: 1px solid #f3f6f9 !important;

                &:not(:last-child) {
                    margin-bottom: 2px;
                }

                .card-header {
                    border-radius: 4px;

                    .card-title {
                        padding: 10px 15px;
                        background-color: #f3f6f9;

                        &::before {
                            content: "\f078";
                            right: 15px;
                            font-weight: 900;
                            position: absolute;
                            font-family: "font awesome 6 pro";
                        }

                        h4 {
                            font-size: 14px;
                        }
                    }

                    .card-title[aria-expanded="true"] {
                        &::before {
                            content: "\f077";
                        }
                    }
                }

                .card-body {
                    background-color: #fff;
                    border-top: 1px solid #f3f6f9;
                }
            }
        }
    }
}

.layout_box .layout_style img {
    max-height: 130px;
}

.publish_type {
    padding: 30px;
}

.publish-tab #uncontrolled-tab-example.nav-tabs li .nav-link.active {
    color: #fff;
}

.te-theme .layout_box .layout_style:hover,
.te-theme .layout_box .layout_style.active {
    border-color: #4179ff;
}

.te-theme .publish-tab .tab-content .rating-schema-main .rs-right ul li::after,
.te-theme .publish-tab #uncontrolled-tab-example.nav-tabs li .nav-link.active,
.te-theme .publish-tab .tab-content .full_container .following_steps .count-badge {
    background-color: #4179ff;
}

.te-theme .embed_code_wrap .nav-link.active svg path[fill],
.te-theme .embed_code_wrap .nav-link:hover svg path[fill] {
    fill: #4179ff;
}

.publish-tab .display_hashtag .tab-content .publish_sidebar .publish_sidebar_area,
.publish-tab .display_email .tab-content .publish_sidebar .publish_sidebar_area {
    padding-right: 15px;
}

.publish-tab .display_hashtag .tab-content .full_container,
.publish-tab .display_hashtag .tab-content .hastag-tabcontent {
    width: calc(100% - 230px);
}

.no-reivew-feed {
    .no-review-feed-content {
        left: 0;
        right: 0;
        top: 0px;
        background-color: rgba(255, 255, 255, 0.9);

        h3 {
            font-size: 18px;
            font-weight: 600;
            color: #1A232F;
            margin-bottom: 15px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: #637079;
            line-height: 23px;
            letter-spacing: 0.02em;
            text-align: center;
            max-width: 70%;
            margin: 0 auto 20px;
        }
    }
}

@media(max-width:1366px) {

    .publish-tab .tab-content .full_container .embed-tab-main .card.card-custom .tab-content .PreviewTagsWallBtn,
    .publish-tab .tab-content .full_container .embed-tab-main .card.card-custom .tab-content .embedTagsGenerate {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

@media(max-width:1279px) {
    .publish-tab .tab-content .rating-schema-main .rs-right h2 {
        font-size: 18px;
    }

    .publish-tab .tab-content .rating-schema-main .rs-left .rs-code-section .embed_code_wrap .tab-content .emebed_action button {
        font-size: 11px;
        padding: 3px 10px !important;
    }

    .publish-tab .tab-content .rating-schema-main .rs-left .rs-code-section,
    .publish-tab .tab-content .rating-schema-main .rs-left .rs-serp-section {
        margin-top: 20px;
    }

    .publish-tab .tab-content .rating-schema-main .rs-left {
        padding-top: 20px;
        padding-right: 30px;
    }

    .publish-tab .tab-content .rating-schema-main .rs-right {
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 15px;
    }

    .publish-tab .tab-content .rating-schema-main .rs-left .rs-code-section .embed_code_wrap .tab-content .emebed_action {
        top: -12px;
    }

    .helpWithAutopilot p,
    .helpWithAutopilot p span {
        font-size: 13px;
    }

    .layout_box .layout_style img {
        max-height: 90px;
    }

    .publish-tab .tab-content .full_container .card {
        max-width: 600px;
    }

    .publish-tab .tab-content .publish_sidebar .publish_sidebar_area {
        padding: 10px;
        padding-right: 15px;
    }

    .publish-tab .tab-content .publish_sidebar>strong {
        padding: 15px 15px 10px;
    }

    .publish-tab .tab-content .publish_sidebar+.embed-on-webpage {
        width: calc(100% - 230px);
    }
}

@media(max-width:1199px) {
    .publish-tab .tab-content .rating-schema-main .rs-left h3 {
        font-size: 16px;
    }
    .publish-tab .tab-content .rating-schema-main .rs-left .behind-blur button {
        line-height: 16px;
    }
    .publish-tab .tab-content .rating-schema-main .rs-left .behind-blur p {
        max-width: 94%;
        margin: 0 auto 15px;
        line-height: normal;
        font-size: 13px;
    }
}

@media(max-width:991px) {
    .publish-tab .tab-content .full_container .review-tab-main li.nav-item .nav-link {
        font-size: 13px;
        padding: 5px 15px;
    }

    .publish-tab .tab-content .rating-schema-main .rs-right h2,
    .publish-tab .tab-content .rating-schema-main .rs-left h3 {
        font-size: 14px;
    }
    .publish-tab .tab-content .rating-schema-main .rs-left h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .publish-tab .tab-content .rating-schema-main .rs-right ul li {
        font-size: 13px;
        color: inherit;
    }

    .publish-tab .tab-content .display_hashtag .publish_sidebar .publish_sidebar_area,
    .publish-tab .tab-content .display_email .publish_sidebar .publish_sidebar_area {
        width: 230px;
    }
}

@media(max-width:767px) {

    .publish-tab .tab-content .full_container .embed-tab-main,
    .publish_wrapper>div {
        flex-wrap: wrap;
    }

    .publish-tab .tab-content .publish_sidebar,
    .publish-tab .tab-content .display_hashtag .publish_sidebar {
        border-right: 0;
        border-radius: 8px 8px 0 0;
    }

    .publish-tab .tab-content .display_hashtag .publish_sidebar .publish_sidebar_area,
    .publish-tab .tab-content .display_email .publish_sidebar .publish_sidebar_area,
    .publish-tab .tab-content .publish_sidebar,
    .publish-tab .tab-content .display_hashtag .publish_sidebar,
    .publish-tab .tab-content .display_email .publish_sidebar,
    .publish_wrapper>div .publish_sidebar+.embed-on-webpage,
    .publish-tab .tab-content .publish_sidebar+.embed-on-webpage,
    .publish-tab .display_hashtag .tab-content .full_container,
    .publish-tab .display_hashtag .tab-content .hastag-tabcontent,
    .publish-tab .tab-content .rating-schema-main .rs-left,
    .publish-tab .tab-content .rating-schema-main .rs-right {
        width: 100%;
    }

    .publish-tab .tab-content .rating-schema-main .rs-left .rs-select-feed .options-dropdown {
        max-width: 100%;
    }

    .publish-tab .tab-content .rating-schema-main .rs-right {
        padding-left: 10px;
    }

    .publish-tab .tab-content .rating-schema-main .rs-left {
        padding-right: 10px;
    }

    .publish-tab .display_hashtag .tab-content .full_container,
    .publish-tab .display_hashtag .tab-content .hastag-tabcontent {
        border-radius: 0 0 8px 8px;
    }

    .publish-tab .tab-content .rating-schema-main .rs-left {
        border-right: 0;
    }

    .publish-tab .helpWithAutopilot p {
        font-size: 13px;
    }

    .publish_wrapper>div .publish_sidebar+.embed-on-webpage,
    .publish-tab .tab-content .publish_sidebar+.embed-on-webpage {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .publish-tab .nav-tabs li .nav-link {
        font-size: 13px;
    }

    .embed_code_wrap .tab-content {
        margin-top: 16px;
    }

    .publish-tab .tab-content .full_container .following_steps .count-badge {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }
}

@media(max-width:574px) {

    .publish-tab .tab-content .full_container .embed-tab-main .card.card-custom .tab-content .PreviewTagsWallBtn,
    .publish-tab .tab-content .full_container .embed-tab-main .card.card-custom .tab-content .embedTagsGenerate {
        width: 100%;
        margin-left: 0;
        margin-top: 1px;
        font-size: 13px;
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .publish-tab .tab-content .full_container .embed-tab-main .card.card-custom .tab-content .formtagarea {
        width: 100% !important;
    }
    .publish-tab .tab-content .rating-schema-main .rs-left h3 {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .publish-tab .tab-content .rating-schema-main .rs-left .behind-blur p {
        margin: 0 auto 10px;
    }
}

@media(max-width:400px) {

    .publish-tab .tab-content .full_container .tag-based-widget-toggle,
    .publish-tab .tab-content .hastag-tabcontent .tag-based-widget-toggle {
        padding: 10px 10px;
    }
    .publish-tab .tab-content .rating-schema-main .rs-left .behind-blur p {
        font-size: 11px;
    }
    .publish-tab .tab-content .rating-schema-main .rs-left .behind-blur button {
        line-height: 10px;
    }
}