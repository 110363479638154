.priceMainWrap {
    padding-top: 120px
}

.product_pricing {
    position: relative;
    display: block;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 50px
}

.product_pricing:before {
    content: '';
    position: absolute;
    left: -1350px;
    right: 0;
    top: 190px;
    height: 700px;
    width: 700px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    margin: 0 auto
}

body.te-theme .btn.btn-outline-primary {
    color: #2B2B2B;
}

body.te-theme .price_btn .btn.btn-primary {
    background-color: #DCE0E3;
    color: #637079;
    border-color: #DCE0E3;
}

.te-theme .enterprise_plan .enterprise_plan_inn .price_btn .btn.btn-primary {
    background-color: #4179ff;
    border-color: #4179ff;
    color: #fff;
}

.product_pricing:after {
    content: '';
    position: absolute;
    left: 0;
    right: -1350px;
    top: 30%;
    height: 700px;
    width: 700px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
    margin: 0 auto;
    transform: rotate(220deg)
}

.socialPagePricing .product_pricing:after,
.socialPagePricing .product_pricing:before {
    content: none
}

.main_title {
    margin-top: 50px;
    text-align: center;
    margin-bottom: 44px;
    display: block;
    position: relative;
    z-index: 1
}

.main_title h1 {
    text-align: center;
    color: #2b2b2b;
    font-size: 48px
}

.main_title p {
    color: #2b2b2b;
    font-size: 22px
}

.product_price_card {
    margin-bottom: 30px
}

.socialPagePricing .price_card {
    padding: 0;
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;
    height: 100%;
    box-shadow: 0px 4px 24px 0px #94AEC540;
    background: #fff;
    border-radius: 16px;
}

.pro_icon {
    margin-top: 30px;
    margin-bottom: 10px
}

.pro_icon img {
    width: 48px;
    margin: 0 auto;
    display: block
}

.starting_at span {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px
}

.starting_at {
    margin-top: 32px
}

.price_wrap h2 sup {
    top: -14px;
    font-size: 60%
}

.price_wrap h2 {
    margin: 0;
    font-size: 48px;
    line-height: 44px;
    margin-bottom: 8px;
}

.price_wrap h2 .valid_from {
    font-size: 16px;
    text-transform: lowercase;
    // color: #848484;
    color: #637079;
    font-weight: 600;
}

.price_btn label:hover {
    color: #fff;
    background-color: #e23442;
    border-color: #e23442;
}

.price_btn label {
    padding: 10px 15px;
    border-radius: 4px;
    display: block;
    cursor: pointer;
    color: #2B2B2B;
    border: 1px solid #545454;
    font-weight: 500;
}

.price_btn input[type=radio] {
    display: none
}

table .price_btn {
    margin: 0;
    padding: 2rem 0
}

table .price_btn .btn.colorPink {
    max-width: 14rem
}

.price_card.socialwall_cls:hover .btn {
    border: 1px solid #f83eb5;
    background-color: #f83eb5;
    color: #fff
}

.price_card.widget_cls:hover .btn {
    border: 1px solid #6464fa;
    background-color: #6464fa;
    color: #fff
}

.price_card.signage_cls:hover .btn {
    border: 1px solid #f25025;
    background-color: #f25025;
    color: #fff
}

.price_card.commerce_cls:hover .btn {
    border: 1px solid #4C32FC;
    background-color: #4C32FC;
    color: #fff
}

.price_card.rights_cls:hover .btn {
    border: 1px solid #00e17d;
    background-color: #00e17d;
    color: #fff
}

.price_card.curation_cls:hover .btn {
    border: 1px solid #2593f1;
    background-color: #2593f1;
    color: #fff
}

.price_card.socialwall_cls .top_text {
    background-color: #ffebf7
}

.price_card.widget_cls .top_text {
    background-color: #e6e6ff
}

.price_card.signage_cls .top_text {
    background-color: #ffe9e7
}

.price_card.commerce_cls .top_text {
    background-color: #f7e8ff
}

.price_card.rights_cls .top_text {
    background-color: #e3fbee
}

.price_card.curation_cls .top_text {
    background-color: #e5f1ff
}

.product_price_card .price_btn {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0 20px
}

.color1 {
    color: #2b2b2b
}

.color2 {
    color: #545454
}

.f1 {
    font-size: 14px
}

.f2 {
    font-size: 16px
}

.f3 {
    font-size: 18px
}

.text-uppercase {
    text-transform: uppercase
}

.bg-color1 {
    background-color: #2b2b2b
}

.bg-color2 {
    background-color: #545454
}

.text-center {
    text-align: center
}

.px-0 {
    padding-right: 0;
    padding-left: 0
}

.plans-page-active .content {
    position: relative
}

.payment_modal_panel {
    display: none
}

.payment_modal_panel {
    position: absolute;
    z-index: 95;
    width: 100%;
    top: 0;
    background-color: #F2F6F8;
    min-height: 100%;
    left: 0;
    right: 0
}

.pay_details {
    position: relative;
    overflow-x: hidden;
    height: calc(100vh - 96px);
    overflow-y: auto;
    padding-bottom: 30px;
}

.custom_pay_modal {
    position: relative;
    z-index: 3
}

button.closeWizard {
    position: absolute;
    background-color: transparent;
    border: none;
    z-index: 11;
    right: 30px;
    top: 30px;
    opacity: .5;
    transition: all .3s
}

button.closeWizard svg {
    width: 18px;
    height: 18px
}

button.closeWizard:hover {
    opacity: 1
}

.productIcon {
    width: 24px;
    height: 24px;
    margin-right: 10px
}

.pricingPlans {
    margin-top: 80px;
    margin-bottom: 50px
}

.pricingPlans .container {
    max-width: 1060px;
    margin: 0 auto;
    display: block
}

.graphicBgDots {
    position: absolute;
    left: -6%;
    top: -5%;
    z-index: -1
}

.pricingPlans .table-responsive {
    display: initial
}

.pricingPlans .table {
    border-color: #ebebeb;
    background: #fff;
    table-layout: fixed
}

.pricingPlans .table tr th {
    color: #2b2b2b;
    font-size: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 69px;
    z-index: 99;
    background: #fff;
    min-width: 230px;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    vertical-align: baseline;
    box-shadow: inset 0 -1px 1px #ebebeb;
    border-bottom-width: 0
}

.pricingPlans .table tr td {
    color: #2b2b2b;
    font-weight: 100
}

.pricingPlans .table tbody tr:first-child td {
    border-top: 0
}

.pricingPlans .table tr.secTH {
    display: none
}

.pricingPlans .table tr td,
.pricingPlans .table tr th {
    text-align: center;
    border-color: #ebebeb;
    padding: 1.5rem 2rem
}

.pricingPlans .table tr td:first-child,
.pricingPlans .table tr th:first-child {
    text-align: left
}

.pricingPlans .table thead .price_btn {
    display: none
}

.pricingPlans .table-hover>tbody>tr:hover {
    background-color: #f7f7f7
}

.showAllBtn {
    padding: 3rem 0
}

button.btn-small:active:focus,
button.btn-small:focus {
    outline: 0
}

button.btn-small {
    background: 0 0;
    border: 1px solid #727272;
    font-weight: 700;
    color: #2b2b2b;
    padding: 1rem 2rem;
    transition: all .5s
}

button.btn-small:hover {
    border-color: rgba(0, 0, 0, .2)
}

.price.count {
    transition: all .5s
}

.priceSwicher .toggle {
    position: relative;
    width: 40px;
    height: 20px;
    margin: 0 10px;
    border-radius: 100px;
    background-color: #ff4051;
    overflow: hidden;
    box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, .05)
}

.te-theme .priceSwicher .toggle {
    background-color: #037bfe;
}

.priceSwicher .check {
    margin: 0;
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6
}

.priceSwicher .check:checked~.switch {
    right: 4px;
    left: 57.5%;
    transition: .25s cubic-bezier(.785, .135, .15, .86);
    transition-property: left, right;
    transition-delay: .08s, 0s
}

.priceSwicher .switch {
    position: absolute;
    left: 4px;
    top: 4px;
    bottom: 4px;
    right: 57.5%;
    background-color: #fff;
    border-radius: 36px;
    z-index: 1;
    transition: .25s cubic-bezier(.785, .135, .15, .86);
    transition-property: left, right;
    transition-delay: 0s, .08s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .2)
}

.priceSwicher label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
}

.pricingList {
    list-style: none;
    padding: 0 15px;
}

.hideTable {
    display: none
}

.popularSticky {
    position: absolute;
    right: -38px;
    top: 1.7rem;
    display: inline-block;
    padding: 3px 2rem;
    background-color: #ffec00;
    color: #2b2b2b;
    transform: rotate(45deg);
    min-width: 150px;
    text-align: center;
    font-size: 14px;
    font-weight: 400
}

.flex-col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.socialPagePricing .flex-col-3.col-md-4,
.socialPagePricing .flex-col-4.col-md-3 {
    margin-bottom: 2rem
}

.form_title {
    display: none;
    text-align: center;
    padding-bottom: 30px
}

.form_title h4 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 30px
}


/* UpgradeModal-START */
.upgrade-modal .modal-header,
.payment-modal.no-header .modal-header,
.payment-pending.no-header .modal-header {
    border: 0;
}

.upgrade-modal .modal-header {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
}

.upgrade-modal .modal-body {
    padding-left: 3rem;
    padding-right: 3rem;
}

.payment-pending .actions {
    margin-top: 32px;
    margin-bottom: 10px;
}

.payment-pending .actions.my-0>button {
    margin-right: 0 !important;
}

.upgrade-modal .upgrade-title-section h2 {
    font-size: 23px;
    font-weight: 600;
    color: #202223;
    margin-bottom: 10px;
}

.upgrade-modal .upgrade-title-section p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #637079;
}

.upgrade-modal .upgrade-title-section p span {
    color: #FF4050;
    cursor: pointer;
}

.upgrade-modal .upgrade-title-section p span:hover {
    text-decoration: underline;
}

.upgrade-confirm-area {
    padding: 10px;
    border-radius: 4px;
    background-color: #F2F5F8;
    justify-content: space-between;
}

.upgrade-confirm-area p {
    color: #637079;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding-left: 10px;
}

.upgrade-confirm-area p::before {
    content: '*';
    color: #FF4050;
    position: absolute;
    left: 0;
    top: 0;
}

.upgrade-plans {
    // margin: 30px auto 30px;
    padding-bottom: 15px;
    margin: 30px auto 15px;
}

.plan-box {
    padding: 20px;
    border-radius: 8px;
}

.upgrade-plans .current-plan {
    // width: 267px;
    width: 50%;
    background-color: #E7EAEE;
    margin-right: 70px;
}

.upgrade-plans .current-plan::after {
    content: "";
    background: url(https://cloud.tagbox.com/react-app/media/images/upgrade-arrow.png) no-repeat top right;
    width: 67px;
    height: 76px;
    background-size: contain;
    position: absolute;
    right: -66px;
    top: 2px;
    z-index: 0;
}

.plan-top {
    margin-bottom: 15px;
}

.plan-top span {
    background-color: #F4F4F6;
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 13px;
    font-weight: 500;
    color: #1A232F;
}

.plan-top h2 {
    color: #1A232F;
    font-size: 22px;
    text-transform: uppercase;
}

.upgrade-plan .plan-top h2 {
    color: #fff;
}

.plan-mid {
    padding: 15px;
    border-radius: 4px;
    background-color: #F4F4F6;
}

.upgrade-plan .plan-mid {
    background-color: rgba(255, 255, 255, 0.6);
}

.plan-row {
    margin: 3px 0;
}

.plan-row .plan-price {
    font-size: 28px;
    // color: #1A232F;
    color: #000;
    font-weight: 600;
    line-height: normal;
}

.upgrade-plan .plan-row .plan-price {
    text-align: right;
}

.plan-row .plan-title {
    width: 60%;
    font-size: 13px;
    color: #1A232F;
    // color: #000;
    font-weight: 500;
    line-height: normal;
}

.current-plan .plan-row .plan-title {
    width: auto;
}

.plan-row .plan-subtitle {
    font-size: 13px;
    font-weight: 500;
    color: #637079;
}

.payable-amount {
    margin-top: 10px;
    color: #fff;
}

.payable-amount span {
    font-size: 16px;
}

.payable-amount strong {
    font-size: 28px;
    font-weight: 600;
    text-align: right;
    line-height: normal;
    padding-right: 15px;
}

.upgrade-plans .upgrade-plan {
    // width: 294px;
    width: 50%;
    background-color: #FF4051;
}

.coupon-code {
    height: 39px;
}

.coupon-code .btn-link {
    font-size: 12px;
    font-weight: 400;
    color: #637079;
}

.coupon-code .btn-link:hover {
    text-decoration: underline;
}

.coupon-code .input-group input::-webkit-input-placeholder {
    font-size: 12px;
    font-weight: 400;
    color: #637079;
    text-transform: capitalize;
}

.coupon-code .input-group input::-moz-placeholder {
    font-size: 12px;
    font-weight: 400;
    color: #637079;
    text-transform: capitalize;
}

.coupon-code .input-group input:-ms-input-placeholder {
    font-size: 12px;
    font-weight: 400;
    color: #637079;
    text-transform: capitalize;
}

.coupon-code .input-group input:-moz-placeholder {
    font-size: 12px;
    font-weight: 400;
    color: #637079;
    text-transform: capitalize;
}

.coupon-code .input-group input {
    font-size: 14px;
    font-weight: 600;
    color: #1A232F;
    text-transform: uppercase;
    border-radius: 4px 0 0 4px;
    border: 1px solid #DCE0E3;
}

.coupon-code .input-group .input-group-append button {
    border: 0;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0 4px 4px 0;
    background-color: #DCE0E3;
    color: rgba(26, 35, 47, .50);
}

.coupon-code .d-inline-block>p {
    font-size: 12px;
    font-weight: 500;
}

.coupon-code .input-group .input-group-append.btn-active button {
    color: #fff;
    background-color: #1A232F;
}

.coupon-code .input-group .input-group-append button.spinner:before {
    left: 0;
    right: 0;
    top: 10px;
    margin: 0 auto;
    position: absolute;
}

.coupon-code .input-group .input-group-append button.spinner.spinner-right.btn:not(.btn-block) {
    padding-right: 13px;
}

/**/
.payment-failed .payment-icon-section {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-bottom: 15px;
    background-color: #FFEAEC;
}

.te-theme .payment-failed .payment-icon-section {
    background-color: #e3f1ff;
}

.payment-failed .payment-icon-section.pis-success {
    background: #62D06A1F;
}

.payment-failed .payment-icon-section img {
    max-height: 29px;
}

.payment-failed h2 {
    font-size: 23px;
    font-weight: 600;
    color: #202223;
    margin-bottom: 10px;
    line-height: normal;
}

.payment-failed p {
    font-size: 14px;
    color: #637079;
    max-width: 430px;
}

.payment-failed button {
    margin: 25px auto;
}

.payment-failed .more-information {
    font-size: 12px;
    color: #637079;
}

.payment-failed .more-information em {
    color: #FF4051;
    font-style: normal;
}

.payment-failed .more-information span {
    cursor: pointer;
    font-weight: 500;
    color: #1A232F;
    text-decoration: underline;
}

.payment-failed .more-information span:hover {
    text-decoration: none;
}

.payment-failed .more-information.continue-with span {
    font-size: 14px;
    font-weight: 500;
    color: #637079;
}

.just-play-left .just-pay {
    font-size: 14px;
    font-weight: 500;
    color: #637079;
    margin-right: 2px;
    white-space: nowrap;
    display: inline-block;
}

.just-play-left sup {
    font-weight: 600;
    color: #FF4050;
}

.just-play-left .price.odometer.count {
    font-size: 31px;
    font-weight: 600;
    color: #FF4050;
}

.te-theme .just-play-left sup,
.te-theme .just-play-left .price.odometer.count,
.te-theme .upgrade-modal .upgrade-title-section p span {
    color: #4179ff;
}

.just-pay-wrap .just-play-left .valid_from {
    font-size: 14px;
    font-weight: 500;
    color: #637079;
    margin-left: 5px;
    text-transform: capitalize;
}

.just-play-right .line-through {
    position: relative;
    padding-left: 4px;
    padding-right: 7px;
}

.just-play-right .line-through::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background: #8c9197;
    display: inline-block;
    top: 24px;
    left: 0;
}

.just-play-right .line-through sup {
    color: #8c9197;
    font-weight: 600;
    font-size: 12px;
}

.just-play-right .line-through span.price.odometer.count {
    color: #8c9197;
    font-weight: 600;
    font-size: 20px;
}

.just-play-right .valid_from.color2 {
    color: #b1b7bc;
    font-size: 14px;
    font-weight: 500;
    margin-left: -7px;
    margin-bottom: -14px;
}

.pricingList li>span,
.pricingList li>svg {
    vertical-align: middle;
}

.pricingList li>svg {
    margin-left: 3px;
}

/* UpgradeModal-END */

/* Enterprise-Plan-START */
.enterprise_plan {
    padding-top: 15px !important;

    .enterprise_plan_inn {
        box-shadow: 0px 4px 24px 0px #94AEC540;
        border-radius: 8px;
        padding: 5px 15px 10px 15px;
        margin-bottom: 10px;

        h3 {
            padding-top: 15px;
        }

        .price_btn {
            margin-top: 10px;
        }
    }

    +.pricingList {
        li {

            &:nth-child(4),
            &:nth-child(7) {
                font-size: 13px;
            }
        }
    }
}

/* Enterprise-Plan-END */
@media(min-width:1601px) {
    div.price_card_inner .color2.f2 {
        max-width: inherit;
        min-height: inherit;
    }
}

@media(max-width:1440px) {

    .price_card h3,
    .price_card .h3 {
        padding: 20px 15px 15px;
    }

    .just-play-left .price.odometer.count {
        font-size: 23px;
    }

    .just-play-right .line-through sup {
        font-size: 11px;
    }

    .just-play-left .just-pay,
    .just-pay-wrap .just-play-left sup,
    .just-play-right .valid_from.color2,
    .just-pay-wrap .just-play-left .valid_from {
        font-size: 13px;
    }

    .just-play-right .line-through span.price.odometer.count {
        font-size: 16px;
    }
}

@media (max-width:1100px) {

    .flex-col-3,
    .flex-col-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }
}

@media (max-width:991px) {
    .flex-col-2 {
        width: 50%;
        flex: 1 0 50%
    }

    .form_title {
        display: block
    }

    .price_cta_con {
        margin-bottom: 30px;
        text-align: center;
        padding-right: 0
    }

    .cta_btn,
    .price_cta_con {
        display: block
    }

    .brand_wrap {
        margin-top: 50px;
        height: 55px;
        background-size: auto 100%;
        transition: .3s;
        -webkit-animation: brand_slide 900s linear infinite
    }

    @-webkit-keyframes brand_slide {
        from {
            background-position: 0 0
        }

        to {
            background-position: -50000px 0
        }
    }

    .priceMainWrap {
        padding-top: 100px
    }
}

@media (max-width:767px) {
    .flex-col-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .small_title h4 {
        font-size: 20px
    }

    .form_card {
        padding: 30px 20px;
        max-width: 450px;
        margin: 0 auto;
        margin-bottom: 30px;
        min-height: auto
    }

    .card_icon img {
        max-width: 75px;
        margin-bottom: 20px
    }

    .form_title h4 {
        font-size: 22px;
        margin-bottom: 15px;
        margin-top: 0
    }

    .paymentMethodWrap {
        padding-top: 15px;
        margin-top: 15px
    }

    .paymentMethod h4 {
        font-size: 18px
    }

    .card_content a {
        font-size: 16px
    }

    .product_pricing {
        margin-bottom: 10px
    }

    .form_wrapper span.wpcf7-form-control-wrap {
        margin-bottom: 15px
    }

    .form_wrapper {
        padding: 30px 15px;
        overflow: hidden
    }

    .form_container {
        padding: 0 0
    }

    .main_title {
        margin-bottom: 25px
    }

    .main_title h3 {
        line-height: 1
    }

    .priceSwicher label {
        font-size: 14px
    }

    .price_card {
        max-width: 300px;
        padding: 15px;
        padding-bottom: 70px
    }

    .price_wrap h2 {
        font-size: 40px;
        line-height: 36px
    }

    .pricingList li {
        line-height: 32px;
        font-size: 16px
    }

    .flex-col-2 {
        width: 100%;
        flex: 1 0 100%
    }

    .price_wrap h2 .valid_from {
        font-size: 20px
    }

    .flex-col-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .brakeOnmob {
        display: block;
        text-align: left
    }

    .price_card h3 {
        font-size: 24px
    }
}


.price_container .tooltip__content {
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2px 10px;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 160px;
    background-color: #fff;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, .2);
}


/* for good section css */

.for_good .container {
    width: 100%;
}

.for_good {
    padding-top: 0px;
    padding-bottom: 0px;
}

.for_good.gray-bg {
    background-color: #f8f8f8;
}

.for_good.form_features {
    padding: 80px 0;
}

.for_good_wrap {
    max-width: 1000px;
    margin: 0 auto;
}

.for_good .card_icon img {
    width: 100%;
    max-width: 70px;
    margin-bottom: 5px;
}

.for_good .form_card:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.for_good .form_card {
    background-color: #fff;
    box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 2px 4px rgb(0 0 0 / 4%), 0 5px 14px rgb(0 0 0 / 2%);
    display: block;
    padding: 20px;
    text-align: center;
    margin: 0 auto;
    text-decoration: none;
    color: #2b2b2b;
    transition: all 0.3s;
    height: 100%;
    max-width: 450px;
    border-radius: 0.42rem;
}

.card_content .btn-outline-primary {
    color: #2B2B2B;
    border: 1px solid #545454;
    font-weight: 500;
}

.for_good .card_content h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.for_good .product_pricing {
    margin-bottom: 10px;
}

.for_good h3 {
    font-weight: 600;
}

.for_good p {
    font-weight: 400;
}

.for_good .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.te-theme .highlightCard {
    box-shadow: 0 -3px 0 0px #4179ff;
}

@media screen and (max-width: 1200px) {
    .for_good {
        margin-top: 20px;
    }

    .highlightCard {
        box-shadow: none;
    }

    .getQuote_btn {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 991px) {
    .form_card {
        padding: 40px 23px;
        transition: all 0.3s;
    }

    .for_good.form_features {
        padding: 60px 0;
    }
}

@media screen and (max-width: 767px) {
    .form_card {
        padding: 30px 20px;
        max-width: 450px;
        margin: 0 auto;
        margin-bottom: 15px;
        height: auto;
    }

    .card_icon img {
        max-width: 75px;
        margin-bottom: 20px;
    }
}

/* for good section css */

/* notification-bar */
.notification-bar {
    width: 100%;
    display: block;
    background-color: #000;
    text-align: center;
    color: #fff;
    padding: 6px 15px;
    position: relative;
    z-index: 9;
}

.notification-bar:before {
    content: '';
    display: block;
    background-image: url(https://cloud.tagbox.com/app/assets/taggbox/section-library/images/events/blackfriday_pattern.png);
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    opacity: 0.1;
}

.nb_content,
.nb_btn_wrap {
    display: flex;
    padding: 0 15px;
    vertical-align: middle;
    color: #fff;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 14px;
}

.nb_content .xmas {
    margin-left: 3px;
    font-size: 24px;
    color: #037bfe;
}

strong.nb_code {
    font-weight: 800;
    margin: 0 8px 0 8px;
    font-size: 18px;
}

.nb_content p {
    margin: 0;
    line-height: 26px;
    font-weight: 400;
    color: #ffffff;
    font-size: 16px;
    font-family: "source sans pro", sans-serif;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
}

.nb_content .get-offer {
    display: flex;
    transition: all .38s;
    position: relative;
}

.nb_content .get-offer:hover {
    transform: translateX(5px)
}

;

.nb_content .get-offer:hover {
    left: 5px;
}

.nb_content .get-offer-btn {
    display: inline-block;
    background-color: transparent;
    padding: 1px 15px;
    border-radius: 3px;
    color: #ff4555;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    line-height: 30px;
    text-decoration: underline;
}

a.nb_btn {
    background-color: #037bfe;
    padding: 7px 18px;
    border-radius: 7px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.nb_content img {
    width: auto;
    margin-right: 8px;
    margin-left: 8px;
    height: 36px;
}

.nb_content span.hashtag_color {
    font-size: 12px;
    color: #fff;
    margin: 0px 10px;
    border: 1px dashed #797979;
    padding: 4px 0px;
    line-height: 18px;
    border-radius: 5px;
    display: flex;
    position: relative;
}

.nb_content span.hashtag_color span {
    padding: 0px 8px;
    border-right: 1px dashed #797979;
}

.nb_content span.hashtag_color strong {
    padding: 0px 10px;
    font-weight: 800;
    font-size: 16px;
}

.notification-bar #copyLink-success-alert {
    position: absolute;
    top: 50%;
    right: -170px;
    transform: translateY(-50%);
    background: rgba(68, 68, 68, 0.79);
    color: rgb(255, 255, 255);
    padding: 2px 10px;
    font-size: 14px;
}

@media screen and (min-width: 991px) {
    .transp_bg .notification-bar {
        background-color: #fff;
    }

    .sticky-header.transp_bg .notification-bar {
        background-color: #ffe3e6;
    }
}

@media screen and (max-width: 991px) {
    .nb_close {
        display: block;
    }

    .nb_content img {
        height: 30px;
        margin-right: 5px;
    }

    .nb_content,
    .nb_btn_wrap {
        flex-direction: column;
    }

    .nb_content p {
        margin-bottom: 10px;
        color: #fff;
    }

    .nb_close {
        right: 0;
    }
}

@media screen and (max-width: 767px) {

    .nb_content,
    .nb_btn_wrap {
        font-size: 12px
    }

    strong.nb_code {
        font-size: 16px;
    }

    .nb_content span.hashtag_color strong {
        font-size: 14px;
    }

    .nb_content img {
        margin-right: 5px;
    }
}

@media screen and (max-width: 567px) {
    .notification-bar #copyLink-success-alert {
        right: -95px;
        padding: 2px 5px;
        font-size: 10px;
    }
}

/* notification-bar */

.priceSwicher {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.plan_switcher {
    display: block;
    text-align: center;
    margin: 0px auto 15px;
}

.btn-color-mode-switch {
    display: inline-block;
    margin: 0px auto;
    position: relative;

}

.btn-color-mode-switch>label.btn-color-mode-switch-inner {
    margin: 0px;
    width: 330px;
    height: 42px;
    background: #f7f7f7;
    border-radius: 26px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    display: block;
    cursor: pointer;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
    content: attr(data-on);
    position: absolute;
    font-size: 14px;
    top: 12px;
    right: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
    content: attr(data-off);
    width: 165px;
    height: 42px;
    background: #037bfe;
    border-radius: 30px;
    position: absolute;
    left: 0px;
    color: #fff;
    top: 0px;
    text-align: center;
    padding: 10px 0px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;


    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}


.btn-color-mode-switch>label.btn-color-mode-switch-inner:active:after,
.btn-color-mode-switch>label.btn-color-mode-switch-inner:focus:after {
    width: 200px;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}


.btn-color-mode-switch>.alert {
    display: none;
    background: #FF9800;
    border: none;
    color: #fff;
}

.btn-color-mode-switch input[type="checkbox"] {
    cursor: pointer;
    width: 100%;
    height: 50px;
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 0;
    margin: 0px;
    left: 0;
    right: 0;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
    background: #f7f7f7;
    color: #545454;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
    content: attr(data-on);
    left: auto;
    right: 0;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
    content: attr(data-off);
    right: auto;
    left: 15px;
}

.btn-color-mode-switch input[type="checkbox"]:checked~.alert {
    display: block;
}

.main_title h3 {
    font-size: 14px;
}

@media screen and (max-width: 767px) {
    .btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
        width: 130px;
        height: 40px;
        padding: 11px 0px;
        font-size: 12px;
    }

    .btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
        font-size: 12px;
        top: 12px;
        right: 12px;
    }

    .btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
        left: 13px;
    }

    .btn-color-mode-switch>label.btn-color-mode-switch-inner {
        width: 260px;
        height: 40px;
    }

    .btn-color-mode-switch>label.btn-color-mode-switch-inner:active:after,
    .btn-color-mode-switch>label.btn-color-mode-switch-inner:focus:after {
        width: 125px;
        transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    }
}


.ugc_plan {
    max-width: 800px;
    margin: 0 auto;
}

.ugc_plan .card-body {
    border-radius: 5px;
    overflow: hidden;
}

.ugc_price .ugc_prefix {
    font-size: 20px;
    color: #2b2b2b;
    font-weight: 600;
}

.ugc_price .ugc_amount__ {
    font-size: 44px;
    font-weight: 800;
    color: #2b2b2b;
}

.ugc_price .ugc_dur {
    font-size: 20px;
    color: #959595;
    font-weight: 600;
}

.ugc_content_wrapper {
    width: 80%;
    max-width: 480px;
}

.ugc_learn_more a {
    color: #959595;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
}

.ugc_plan h3 {
    font-size: 40px;
}

.ugc_learn_more a:hover {
    text-decoration: underline;
}

.ugc_starting h5 {
    font-weight: 700;
    font-size: 22px;
}

.ugc_plan p {
    font-size: 16px;
}

@media screen and (max-width: 767px) {
    .ugc_plan .card-body {
        padding: 1.5rem;
    }
}

.highlightCard {
    top: -34px
}



.mostpopularSticky {
    text-align: center;
    background: #fe4051;
    position: initial;
    font-size: 18px;
    border-radius: 4px 4px 0px 0px;
    margin: 0;
    font-weight: 700;
    color: #fff;
    top: -28px;
    padding: 8px 18px;
    width: 100%;
    line-height: 19px;
    height: 34px;
}

.price_card {
    box-shadow: 0px 4px 10px #0000001A;
    border: 1px solid #C6C6C6;
    border-radius: 4px;
    overflow: visible;
    max-width: initial;
}

.price_card.highlightboxCardBorder {
    border: 1px solid #fd4052;
    border-radius: 0 0 4px;

}

.main_title {
    text-align: center;
    margin-bottom: 94px !important;
    display: block;
}


.f2 {
    font-size: 16px;
}

.colorActive {
    color: #037bfe !important;
}

.pricingList {
    // padding-left: 10px
}

.pricingList {
    // text-align: left;
}

.iconCheckClose {
    margin-right: 5px;
}

.ugc_right_width {
    max-width: 1024px;
    margin: 0 auto;
}

span.p_tooltip svg {
    width: 14px;
    height: 14px;
}

span.p_tooltip {
    margin-left: 5px;
}

.hideTooltip .p_tooltip {
    display: none;
}

@media screen and (max-width: 767px) {
    .highlightCard {
        top: 0;
        clear: both;
        margin-bottom: 4.5rem !important;
    }

}

.p_strip {
    position: fixed;
    bottom: 0;
    width: calc(100% - 200px);
    left: 200px;
    background: #fff;
    z-index: 9;
    text-align: center;
    transition: left .2s ease, width .2s ease;
    box-shadow: 0 -1px 2px rgb(0 0 0 / 6%), 0 -2px 4px rgb(0 0 0 / 4%), 0 -5px 14px rgb(0 0 0 / 2%);
}

.p_strip p {
    font-size: 13px;
    font-weight: 600;
}

.btn.bootstrap-touchspin {
    width: auto;
    padding: 6px;
}

.btn.bootstrap-touchspin input {
    width: auto !important;
    border: none;
    max-width: 38px !important;
    min-width: 0;
    padding: 0;
    text-align: center;
    height: auto;
}

.btn.bootstrap-touchspin .btn {
    background: none;
    border: none;
    padding: 0 3px;
}

.btn.bootstrap-touchspin input {
    width: auto !important;
    border: none;
    max-width: 38px !important;
    min-width: 0;
    padding: 0;
    text-align: center;
    height: auto;
}

.add_on .btn.btn-pill.bootstrap-touchspin {
    border: 1px solid #037bfe;

}

.bootstrap-touchspin .btn.btn-secondary:hover,
.bootstrap-touchspin .btn.btn-secondary:focus {
    background-color: transparent !important;
    border-color: transparent !important;
}

.badge-red {
    display: inline-block;
    margin-left: 5px;
    background: #037bfe;
    padding: 1px 8px;
    line-height: normal;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
}

.highlightCard {
    top: -34px
}

.mostpopularSticky {
    text-align: center;
    background: #fe4051;
    position: initial;
    font-size: 18px;
    border-radius: 4px 4px 0px 0px;
    margin: 0;
    font-weight: 700;
    color: #fff;
    top: -28px;
    padding: 8px 18px;
    width: 100%;
    line-height: 19px;
    height: 34px;
}

.price_card {
    box-shadow: 0px 4px 10px #0000001A;
    border: 1px solid #C6C6C6;
    border-radius: 4px;
    overflow: visible;
    max-width: initial;
}

.price_card.highlightboxCardBorder {
    border: 1px solid #fd4052;
    border-radius: 0 0 4px;
}

.main_title {
    text-align: center;
    margin-bottom: 94px !important;
    display: block;
}

.f2 {
    font-size: 16px;
}

.colorActive {
    color: #037bfe !important;
}

.pricingList {
    // padding-left: 10px
}

.pricingList {
    // text-align: left;
}

.iconCheckClose {
    margin-right: 5px;
}

.ugc_right_width {
    max-width: 1024px;
    margin: auto;
}

span.p_tooltip svg {
    width: 14px;
    height: 14px;
}

span.p_tooltip {
    margin-left: 5px;
}

.hideTooltip .p_tooltip {
    display: none;
}

.wizard-wrap .pdback_btn {
    position: relative
}

.wizard-wrap {
    padding-top: 30px
}

.amount_details .plan_time__ {
    white-space: inherit;
}

.multi_payment_g h3 {
    font-weight: 600
}

.pay_details .startup-plan .container_custom .startup-wrapper {
    margin-top: 50px;
}

@media screen and (min-width:992px) {

    .aside-minimize .p_strip,
    .aside-minimize-hover .p_strip {
        left: 70px;
        width: calc(100% - 70px);
    }
}

@media screen and (max-width:767px) {
    .highlightCard {
        top: 0;
        clear: both;
        margin-bottom: 4.5rem !important;
    }

    .price_container {
        padding-top: 20px
    }

    .p_strip .col-md-4 {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 991px) {
    .wizard-wrap .pdback_btn {
        top: -15px !important
    }

    .wizard-wrap .container_custom {
        padding: 0
    }

    .wizard-wrap {
        padding-top: 15px
    }

    #paymentoption .pdback_btn {
        top: -30px;
    }

    #paymentoption .multi_payment_g {
        margin-top: 20px
    }

    #paymentoption .container_custom {
        padding-top: 15px
    }

    .highlightCard {
        top: auto !important;
    }

    .mostpopularSticky {
        display: none
    }

    .price_card {
        max-width: initial;
    }

    .pay_details .startup-plan {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .plan_img img {
        width: 30px;
    }

    .main_title h3 span {
        font-size: 0px;
    }
}

@media screen and (max-width: 1366px) {
    .wizard-wrap .pdback_btn {
        top: -30px
    }
}


.bootstrap-touchspin .btn.btn-secondary:hover,
.bootstrap-touchspin .btn.btn-secondary:focus {
    background-color: transparent !important;
    border-color: transparent !important;
}

@media screen and (max-width:1427px) {
    .addon_features .subtext {
        min-height: 42px;
    }
}


@media screen and (max-width:991px) {
    .p_strip {
        width: 100%;
        left: 0;
    }

    .pay_details {
        height: calc(100vh - 106px);
    }
}

@media screen and (max-width:575px) {
    .addon_features .subtext {
        min-height: 0;
    }

    .p_strip p {
        font-size: 14px;
    }

    .p_strip.checkout_div .addon_checkout {
        padding: 8px 25px;
    }
}


.hide {
    display: none;
}

.contact_us_in form #phone {
    padding-left: 45px;
}

.iti {
    width: 100%;
}

.iti__flag-container {
    top: 15px !important;
    bottom: inherit !important;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: transparent !important;
}

.priceMainWrap {
    padding-top: 150px;
}

.product_pricing {
    position: relative;
    display: block;
    z-index: 1;
    overflow: inherit;
    margin-bottom: 50px !important;
    margin-top: 50px;
    padding: 0;
}

.price_container {
    margin: 0 auto;
    display: block;
}

.main_title p {
    color: #2b2b2b;
    font-size: 22px
}

.priceSwicher .b {
    display: block
}

.priceSwicher .check {
    margin: 0;
    position: absolute;
    display: block;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 6
}

.priceSwicher .check:checked~.switch {
    right: 4px;
    left: 57.5%;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: .08s, 0s
}

.priceSwicher .switch {
    position: absolute;
    left: 4px;
    top: 4px;
    bottom: 4px;
    right: 57.5%;
    background-color: #fff;
    border-radius: 36px;
    z-index: 1;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0s, .08s;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
}

.price_container .DisplayEvents.box-flex {
    background-color: transparent;
    box-shadow: none;
}

.DisplayEvents .price_card {
    background-color: #fff;
}

.price_container .box-flex {
    display: flex;
    border-radius: 16px;
    // box-shadow: 0px 3px 6px #0000000f;
    // border-radius: 10px;
    // background-color: #fff;
}

.price_container .box-flex .price_counter {
    width: 23.5%;
    margin-right: 2%;
    // width: 25%;
}

.price_container .box-flex .price_counter:last-child {
    margin-right: 0;
}

.hideTable {
    display: none;
}

.forMob {
    display: none;
}

.popularSticky {
    right: -3rem;
    top: 2.4rem;
    font-size: 14px;
}

.price_container {
    max-width: 100%;
}

.priceSwicher-main {
    margin: 20px 0px 30px;
}

ul.platform__icons li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
    position: relative;
}

ul.platform__icons li ul {
    position: absolute;
    background-color: #fff;
    padding: 8px;
    min-width: 170px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .1);
    border-radius: 3px;
    right: -18px;
    left: auto;
    top: 110%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    margin: 10px auto;
    text-align: center;
    z-index: 9;
}

ul.platform__icons li:hover ul.platform__more_ {
    top: 100%;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
}

ul.platform__icons li img {
    width: 16px;
    height: auto;
}

.price_container .pricingList .p_tooltip {
    position: relative;
}

.price_container .pricingList .p_tooltip:hover .tooltip__content {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease;
}

.price_container .tooltip__content {
    visibility: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2px 10px;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 160px;
    background-color: #fff;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, .2);
}

/*New Price card css */

.highlightCard {
    box-shadow: 0 -3px 0 0px #e73b4e;
    border-radius: 16px 16px 0 0;
}

.highlightCard .price_card h3 {
    background-color: #fff;
    // padding: 15px 15px 11px 15px;
}

.highlightCard .price_card_inner {
    border-right: 0px;
    // transform: scale(0.95);
    // padding-top: 0px;
}

.price_container .box-flex .price_counter:last-child .price_card_inner {
    border-right: 0px;
}

.highlightCard .price_card_inner .f2 {
    padding-top: 0px;
    // padding-bottom: 13px;
    font-size: 14px;
    font-weight: 500;
}

.price_card_inner .color2.f2 {
    font-size: 14px;
    font-weight: 500;
    color: #1A232F;
    max-width: 200px;
    margin-bottom: 10px;
}

.price_btn .btn {
    font-size: 14px;
}

.highlightCard .price_card_inner .price_wrap h2 {
    margin-top: 0px;
}

.mostpopularSticky {
    text-align: center;
    background: #fe4051;
    position: initial;
    font-size: 18px;
    border-radius: 4px 4px 0px 0px;
    margin: 0;
    font-weight: 700;
    color: #fff;
    top: -28px;
    padding: 8px 18px;
    width: 100%;
    line-height: 19px;
    height: 34px;
}

.main_title {
    text-align: center;
    margin-bottom: 10px !important;
    margin-top: 10px;
    display: block;
    margin-left: 15px;
}

.main_title h3 {
    margin-bottom: 0px;
}

.one_day_pass_title {
    background-color: #037bfe;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 3px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    background-color: #037bfe;
}

.price_container .event_plans .price_card_wrap {
    width: 33.33%;
}

.price_card h3 {
    margin: 0px;
    text-align: left;
    padding: 25px 15px 10px;
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    font-size: 16px;
    font-weight: 700;
}

.price_container .box-flex .price_counter:nth-child(1) .price_card h3 {
    // border-top-left-radius: 5px;
}

.price_container .box-flex .price_counter:last-child .price_card h3 {
    // border-top-right-radius: 5px;
}

.f2 {
    font-size: 13px;
    padding: 0px;
    text-align: left;
    margin: 0px;
    min-height: 45px;
}

.colorActive {
    color: #037bfe !important;
}

.pricingList {
    // padding-left: 0px;
    text-align: left;
}

.widget-price-section .highlightboxCardBorder label.active {
    margin-top: -5px;
}

.iconCheckClose {
    margin-right: 5px;
}

@media screen and (max-width: 992px) {
    .mostpopularSticky {
        display: none
    }

    .highlightCard {
        top: auto;
    }
}

.price_wrap h2 {
    margin: 0px;
    font-size: 28px;
    text-align: left;
    min-height: 46px;
    font-weight: 500;
}

.price_wrap h2 sup {
    top: -7px;
    font-size: 60%;
}

.odometer.odometer-auto-theme {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.price_card_inner {
    // padding: 15px 20px 0 25px;
    padding: 0px 15px 0 15px;
    // border-right: 1px solid #ccc;
}

.pricingList li {
    font-weight: normal;
    line-height: 26px;
    // color: #5C5C5C;
    // font-size: 13px;
    font-size: 14px;
    font-weight: 500;
    color: #1A232F;
    padding-top: 4px;
    padding-bottom: 4px;
}

.pricingList li:last-child {
    padding-bottom: 0;
}

span.price.odometer.count {
    font-weight: 600;
    font-size: 28px;
    color: #1A232F;
}

.price_btn .btn-outline-primary {
    color: #2B2B2B;
    border: 1px solid #545454;
    font-weight: 500;
}

.socialPagePricing .price_card .price_btn {
    position: inherit;
    margin-bottom: 10px;
    margin-top: 10px;
}

.socialPagePricing .price_card .price_btn .btn-outline-primary {
    font-size: 14px;
    font-weight: 500;
    background: #F4F4F6;
    border-color: #F4F4F6;
}

.socialPagePricing .highlightCard .price_card .price_btn {
    // margin-top: 0px;
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-default {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-default .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
    display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value,
.odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
    position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.odometer.odometer-auto-theme .odometer-value,
.odometer.odometer-theme-default .odometer-value {
    text-align: center;
}

/* PRICING PLAN TABLE */

.pricingPlans {
    margin-top: 80px;
    margin-bottom: 50px;
    padding: 0;
    overflow: initial;
}

.pricingPlans .container {
    max-width: 1060px;
    margin: 0 auto;
    display: block
}

.pricingPlans .table-responsive {
    display: initial
}

.pricingPlans .table {
    border-color: #EBEBEB;
    background: #fff;
    table-layout: fixed
}

.pricingPlans .table tr th {
    color: #2b2b2b;
    font-size: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 69px;
    z-index: 99;
    background: #fff;
    min-width: 230px;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    vertical-align: baseline;
    box-shadow: inset 0px -1px 1px #EBEBEB;
    border-bottom-width: 0
}

.pricingPlans .table tr td {
    color: #2b2b2b;
    font-weight: 100
}

.pricingPlans .table tbody tr:first-child td {
    border-top: 0
}

.pricingPlans .table tr th h4 {
    font-size: 18px;
    margin: 7px 0;
}

.pricingPlans .table tr.secTH {
    display: none;
}

.pricingPlans .table tr th,
.pricingPlans .table tr td {
    text-align: center;
    border-color: #EBEBEB;
    padding: 15px 20px;
}

.pricingPlans .table tr th:first-child,
.pricingPlans .table tr td:first-child {
    text-align: left
}

.pricingPlans .table thead .price_btn {
    display: none
}

.pricingPlans .table-hover>tbody>tr:hover {
    background-color: #fffcfc
}

.showAllBtn {
    padding: 1.5rem 0;
}

button.btn-small:focus,
button.btn-small:active:focus {
    outline: none
}

button.btn-small {
    background: transparent;
    border: 1px solid #727272;
    font-weight: 600;
    color: #2b2b2b;
    padding: .7rem 1.5rem;
    transition: all .5s;
    font-size: 14px;
}

button.btn-small:hover {
    border-color: rgba(0, 0, 0, .2)
}

.ugc_plan {
    max-width: 800px;
    margin: 0 auto;
}

.ugc_plan .card-body {
    border-radius: 5px;
    overflow: hidden;
    padding: 2rem;
}

.ugc_price .ugc_prefix {
    font-size: 20px;
    color: #2b2b2b;
    font-weight: 600;
}

.ugc_price .ugc_amount__ {
    font-size: 44px;
    font-weight: 800;
    color: #2b2b2b;
}

.ugc_price .ugc_dur {
    font-size: 20px;
    color: #959595;
    font-weight: 600;
}

.ugc_content_wrapper {
    width: 80%;
    max-width: 480px;
}

.ugc_learn_more a {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    padding: 8px 22px;
}

.ugc_plan h2 {
    font-size: 40px;
    margin-bottom: 24px;
}

.ugc_starting h5 {
    font-weight: 700;
    font-size: 22px;
}

.ugc_plan p {
    font-size: 16px;
}

.socialPagePricing .price_card .price_wrap {
    margin: 0px;
    text-align: left;
}

.getQuote_btn {
    margin-top: 20px;
}

.getQuote_btn .getQuoteBtn {
    color: #2B2B2B;
    border: 1px solid #545454;
    font-weight: 500;
    display: block;
    padding: 10px 15px;
    width: 100%;
    font-size: 14px;
}

.getQuote_btn .getQuoteBtn:hover {
    color: #fff;
    border: 1px solid #037bfe;
    background-color: #037bfe;
}

.get-quote-model .quoteForm textarea {
    height: 70px !important;
}

.get-quote-model label {
    font-size: 14px;
    font-weight: 600;
}

.contact_us_in form .form-control {
    width: 100%;
    outline: none;
    border-radius: 4px;
    border: 1px solid #545454;
    padding: 6px 10px;
    height: 44px;
    font-size: 16px;
    // white-space: nowrap;
}

.contact_us_in form .form-control.error {
    display: block;
    margin: 0px;
}

.contact_us_in form label.error {
    color: #db4b20 !important;
    margin: 0px;
    padding: 0px;
    font-weight: normal;
}

.contact_us_in form textarea.form-control {
    height: 70px;
}

.ugc_right_width .price_counter {
    width: 33.33% !important;
}

.ugc_right_width .price_card_inner .f2 {
    min-height: 92px;
}

.DisplayEvents>.box-flex {
    width: 80%;
    display: flex !important;
}

.DisplaySignage>.box-flex {
    display: flex !important;
}

.price_container .dispaly-price-plan .price_counter {
    width: 33.33%;
}

.odometer .odometer-digit .odometer-value {
    width: 100%;
}

.commerce-price-plan {
    max-width: 1024px;
}

.commerce-price-plan .price_counter {
    width: 33.33% !important;
}

@media screen and (max-width: 767px) {
    .ugc_plan .card-body {
        padding: 1.5rem;
    }
}

@media screen and (max-width: 1399PX) {
    .price_card_inner {
        padding: 15px 15px 0px 15px;
        padding-top: 0;
    }

    .price_card h3,
    .price_card .h3 {
        padding: 15px 15px 10px;
    }

    .price_card_inner .color2.f2 {
        min-height: 42px;
    }

    .enterprise_plan .enterprise_plan_inn {
        padding-top: 0px;
    }

    .pricingList li,
    .enterprise_plan+.pricingList li:nth-child(4),
    .enterprise_plan+.pricingList li:nth-child(7) {
        font-size: 11px;
    }
}

@media(max-width:1199px) {
    .price_card_inner .color2.f2 {
        min-height: inherit;
        max-width: inherit;
    }

    .enterprise_plan .enterprise_plan_inn .price_btn {
        padding-top: 25px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .price_container .box-flex {
        flex-wrap: wrap;
        border-radius: 0px;
        box-shadow: none;
        background-color: transparent;
    }

    .price_container .box-flex .price_counter {
        width: 50%;
        padding: 12.5px;
    }

    .price_card_inner {
        border-right: 0px;
    }

    .socialPagePricing .price_card {
        box-shadow: 0px 3px 6px #0000000f;
        padding-bottom: 15px;
        background-color: #fff;
    }

    .highlightCard {
        transform: inherit;
        border: 0px;
        background-color: transparent;
        box-shadow: none;
    }

    .highlightCard .price_card {
        border: 4px solid #e73b4e;
        border-radius: 15px;
        overflow: hidden !important;
    }

    .highlightCard .price_card_inner {
        transform: inherit;
    }

    .product_pricing {
        margin-bottom: 0px !important;
    }

    .price_btn .btn {
        padding: 8px 15px;
    }

    .ugc_right_width {
        width: 100% !important;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .enterprise_plan .enterprise_plan_inn .price_btn {
        padding-top: 30px;
    }

    .price_container .box-flex {
        flex-wrap: wrap;
        border-radius: 0px;
        box-shadow: none;
        background-color: transparent;
    }

    .price_container .box-flex .price_counter {
        width: 50%;
        padding: 12.5px;
    }

    .price_card_inner {
        border-right: 0px;
    }

    .socialPagePricing .price_card {
        box-shadow: 0px 3px 6px #0000000f;
        padding-bottom: 15px;
        background-color: #fff;
    }

    .highlightCard {
        transform: inherit;
        border: 0px;
        background-color: transparent;
    }

    .highlightCard .price_card {
        border: 4px solid #e73b4e;
        border-radius: 15px;
        overflow: hidden !important;
    }

    .highlightCard .price_card_inner {
        transform: inherit;
    }

    .product_pricing {
        margin-bottom: 0px !important;
    }

    .price_btn .btn {
        padding: 8px 15px;
    }

    .ugc_right_width {
        width: 100% !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .enterprise_plan .enterprise_plan_inn .price_btn {
        padding-top: 5px;
    }

    .socialPagePricing .price_card {
        max-width: 340px;
        margin: 0 auto;
    }

    .commerce-price-plan {
        width: 100% !important;
    }
}

@media(max-width:575px) {
    .enterprise_plan .enterprise_plan_inn .price_btn {
        padding-top: 15px;
    }
}

@media (max-width: 767.98px) {
    .socialPagePricing .productIcon {
        width: 48px;
        margin-right: 0;
        height: 54pxpx;
        display: inline-block;
        text-align: center;
        width: 100%;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    .main_title p {
        font-size: 18px;
    }

    .price_container .box-flex {
        flex-wrap: wrap;
        border-radius: 0px;
        box-shadow: none;
        background-color: transparent;
    }

    .DisplayEvents>.box-flex {
        width: 100% !important;
    }

    .price_container .box-flex .price_counter {
        // width: 100% !important;
        padding: 15px 0px;
        margin-bottom: 0px;
        background-color: transparent;
    }

    .commerce-price-plan {
        width: 100% !important;
    }

    .price_card_inner {
        border-right: 0px;
    }

    .socialPagePricing .price_card {
        box-shadow: 0px 3px 6px #0000000f;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden !important;
    }

    .highlightCard {
        transform: inherit;
        border: 0px;
    }

    .highlightCard .price_card {
        border: 4px solid #e73b4e;
        border-radius: 15px;
        overflow: hidden !important;
    }

    .highlightCard .price_card_inner {
        transform: inherit;
    }

    .product_pricing {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .brakeOnmob {
        display: block;
        text-align: left;
    }

    .forMob {
        display: block;
        text-align: center;
    }

    .forMob .seeMore {
        font-size: 16px;
        padding: 1rem;
        color: #037bfe;
        cursor: pointer;
    }

    .socialPagePricing .price_card .price_btn {
        margin-bottom: 20px;
    }

    .forDesktop {
        display: none;
    }

    .table-bordered>thead>tr>th h4 {
        font-size: 16px;
    }

    table th,
    table td {
        font-size: 14px;
    }

    table td:first-child {
        text-align: left;
    }

    table td:nth-child(2) {
        text-align: center;
    }

    .get-quote-model {
        padding-right: 0px !important;
    }

    .ugc_right_width {
        width: 100% !important;
    }

    .highlightCard {
        // margin-bottom: 0px !important;
    }

    .features_table_wrap {
        margin-top: 0px;
    }

    .f2 {
        min-height: auto;
    }

    .features_btn_wrap {
        padding-bottom: 0px;
    }

    .for_good .form_card {
        margin-top: 20px;
        height: auto;
        padding: 15px 15px 25px 15px;
        max-width: inherit;
    }

    .price_container {
        padding: 0px;
    }
}


.showcase-reviews-section {
    border-radius: 0.42rem;
    color: #2b2b2b;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 2px 4px rgb(0 0 0 / 4%), 0 5px 14px rgb(0 0 0 / 2%);
    height: 100%;
}

.showcase-reviews-section:before {
    content: '';
    display: block;
    background-color: #FFF2F3;
    // background-color: #037bfe14;
    position: absolute;
    height: 410px;
    width: 150%;
    border-radius: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.te-theme .showcase-reviews-section:before {
    background-color: #037bfe14;
}

.showcase-review-box .quote-top path,
.showcase-review-box .quote-top~p svg path {
    fill: rgba(255, 64, 81, 0.21);
}

.te-theme .showcase-review-box .quote-top path,
.te-theme .showcase-review-box .quote-top~p svg path {
    fill: rgb(3 123 254 / 21%);
}

.showcase-reviews-section:after {
    content: '';
    display: block;
    background-color: transparent;
    // border: 1px solid #FFF2F3;
    border: 1px solid #037bfe14;
    position: absolute;
    height: 425px;
    width: 150%;
    border-radius: 100%;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.showcase-reviews-conttent {
    z-index: 1;
}

.showcase-ribbon {
    position: absolute;
    right: 40px;
    top: -10px;
}

.showcase-title {
    text-align: center;
    padding-top: 40px;
    font-size: 28px;
    width: 77%;
    margin: 0 auto;
    margin-bottom: 40px;
}

.showcase-title span {
    // background: linear-gradient(to right, #613983, #E55B98 100%);
    background: linear-gradient(to right, #613983, #e25a97 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.te-theme .showcase-title span {
    background: linear-gradient(to right, #037bfe, #00edff 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.showcase-batches {
    margin-bottom: 40px;
}

.showcase-batches ul {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px;
}

.showcase-batches ul li {
    width: 90px;
    padding: 0px 2px;
}

.showcase-batches ul li.leadger-batches {
    width: 120px;
}

.showcase-review-box {
    padding: 0px 150px;
    text-align: center;
}

.review-text .quote-top {
    position: absolute;
    left: -70px;
    top: -40px;
}

.review-text p {
    color: #2b2b2b;
}

.review-text p span {
    position: relative;
    top: 10px;
    left: 10px;
}

.review-writer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.review-writer .writer-thumbnail {
    height: 42px;
    width: 42px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
}

.review-writer .writer-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.review-writer .writer-info {
    text-align: left;
}

.review-writer .writer-info p {
    color: #ff4051;
    line-height: 20px;
}

.te-tagembed .review-writer .writer-info p {
    color: #4179ff;
}

.review-writer .writer-info span {
    font-size: 16px;
    color: #737373;
}

@media screen and (max-width: 1399px) {
    .showcase-title {
        width: 73%;
        margin: 0 auto 30px;
        padding-top: 30px;
    }
}

@media screen and (max-width: 991px) {
    .showcase-review-box {
        padding: 0px 100px;
    }

    .highlightCard {
        box-shadow: none;
    }
}

@media screen and (max-width: 767px) {
    .showcase-ribbon {
        right: 15px;
        top: 0px;
    }

    .showcase-ribbon svg {
        width: 30px;
        height: 55px;
    }

    .showcase-title {
        width: 72%;
        font-size: 20px;
    }

    .showcase-batches ul li {
        width: 100px;
        padding: 0px 5px;
    }

    .showcase-batches ul li img {
        width: 100%;
    }

    .showcase-reviews-section:before {
        height: 220px;
        transform: translate(-50%, -23%);
    }

    .showcase-reviews-section:after {
        height: 230px;
        transform: translate(-50%, -23%);
    }

    .showcase-review-box {
        padding: 0px 15px;
    }

    .review-text .quote-top {
        left: -10px;
    }

    .review-text .quote-top svg {
        height: auto;
        width: 40px;
    }

    .review-text h4 {
        font-size: 18px;
    }

    .review-text p {
        font-size: 14px;
    }

    .showcase-batches {
        margin-bottom: 40px;
        padding: 0 15px;
    }
}

.commerce-price-plan .price_counter:last-child .pricingList {
    padding-bottom: 84px;
}

@media screen and (max-width:1399px) {
    .commerce-price-plan .price_counter:last-child .pricingList {
        padding-bottom: 55px;
    }
}

@media screen and (max-width:1199px) {
    .commerce-price-plan .price_counter:last-child .pricingList {
        padding-bottom: 0;
    }

    .price_card h3,
    .price_card .h3 {
        padding: 25px 15px 15px;
    }

    .price_container .box-flex .price_counter {
        margin-right: 0;
    }
}













/** Stripe **/

.billing_title {
    display: flex;
    flex-direction: column;
    position: relative;
}

.multi_payment_g h3 {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 25px;
    position: relative;
    padding: 20px;
    border-bottom: 1px solid #ccc;
}

.multi_payment_g h5 {
    font-size: 16px;
    padding: 0px 20px;
}

ul.select_payment_g {
    list-style: none;
    padding: 20px;
}

ul.select_payment_g li {
    display: inline-block;
    vertical-align: middle;
    padding-right: 0;
    width: 48.3%;
    margin-bottom: 15px;
    position: relative;
    text-align: center;
}

ul.select_payment_g li:last-child {
    margin-left: 15px;
}

ul.select_payment_g li a img {
    max-height: 47px;
    max-width: 187px;
}

ul.select_payment_g li a {
    background-color: #fff;
    display: block;
    padding: 40px 15px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #ccc;
    min-height: 112px;
    position: relative;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

ul.select_payment_g li a span {
    position: absolute;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    right: -11px;
    top: -12px;
}

ul.select_payment_g li a:hover,
ul.select_payment_g li a.selected {
    border: 1px solid #45a704;
}

ul.select_payment_g li a:hover span,
ul.select_payment_g li a.selected span {
    border: 0px solid #50BCFE;
    background: #fff;
}

ul.select_payment_g li a:hover span img,
ul.select_payment_g li a.selected span img {
    opacity: 1;
    transform: scale(1);
}

ul.select_payment_g li a span img {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .3s;
    transform: scale(0);
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.multi_payment_g {
    background: #fff;
    width: 100%;
    max-width: 600px;
    margin: 80px auto 0;
    border: 1px solid #ebedf3;
    box-shadow: 0 0 3px rgb(0 0 0 / 10%);
    border-radius: 6px;
}

.stripe_footer img {
    max-width: 130px;
    margin: 0 auto;
}

.stripe_footer {
    display: block;
    margin-bottom: 20px;
}

@media screen and (device-aspect-ratio: 2/3) {

    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 12px !important;
    }
}

@media screen and (device-aspect-ratio: 40/71) {

    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 12px !important;
    }
}

@media screen and (device-aspect-ratio: 375/667) {

    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 12px !important;
    }
}

@media screen and (device-aspect-ratio: 9/16) {

    input[type="date"],
    input[type="datetime-local"],
    input[type="datetime"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select,
    textarea {
        font-size: 12px !important;
    }
}

.wizard-wrap {
    padding-top: 50px;
}

.wizard-wrap h3 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
    position: relative;
}

.billing_info p {
    margin-top: 0;
}

.billing_info h4,
.payment_info h4 {
    margin-bottom: 12px;
    font-weight: 600;
}

.billing_fields {
    padding: 10px 20px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 5px 0 rgb(37 47 53 / 6.2%);
    box-shadow: 0 2px 5px 0 rgb(37 47 53 / 6.2%);
    background-color: #fff;
    position: relative;
}

.billing_fields .bootstrap-select.btn-group .dropdown-menu {
    max-height: 300px !important;
    box-shadow: none;
    margin-bottom: 0;
}

.billing_fields .btn-default.active,
.billing_fields .btn-default:active,
.billing_fields .btn-default:hover,
.billing_fields .open>.btn-default.dropdown-toggle {
    color: #333;
    background-color: #fff;
    border-color: #fff;
}

.billing_info {
    margin: 0 0 30px;
}

.form__group {
    -ms-display: flex;
    display: flex;
    margin: 0;
    padding: 15px 15px 8px 15px;
    border-bottom: 1px solid #e3e9ed;
    align-items: center;
}

.subscription_wrap {
    margin: 84px 0 30px;
    padding: 30px;
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 5px 0 rgb(37 47 53 / 6.2%);
    box-shadow: 0 2px 5px 0 rgb(37 47 53 / 6.2%);
    background-color: #fff;
}

.form__group.error-message label {
    color: #fa755a !important;
}

.form__group input::placeholder,
.form__group select::placeholder {
    color: #999;
    opacity: 1;
}

.form__group input:-ms-input-placeholder,
.form__group select::placeholder {
    color: #999;
}

.form__group input::-ms-input-placeholder,
.form__group select::placeholder {
    color: #999;
}

.form__group label {
    min-width: 70px;
    max-width: 70px;
    margin: 0 30px 0 0;
    text-align: left;
    color: #545454;
    transition: all 0.3s;
    font-size: 14px;
    font-weight: 600;
}

.error_txt {
    position: relative;
    padding-left: 20px;
    display: none;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
    opacity: 0;
    color: #fa755a !important;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.error_txt.show_error {
    opacity: 1;
    transform: none;
    display: block;
}

.error_txt:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "\f05a";
    font-family: "Font Awesome 5 Pro";
    font-size: 14px;
}

.suc_txt {
    position: relative;
    padding-left: 20px;
    display: none;
    margin-top: 10px;
    font-size: 12px;
    color: #479847 !important;
    font-weight: 600;
    opacity: 0;
    transform: translateY(10px);
    transition-property: opacity, transform;
    transition-duration: 0.35s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.suc_txt.show_success {
    opacity: 1;
    transform: none;
    display: block;
}

.suc_txt:before {
    position: absolute;
    left: 0;
    top: -1px;
    content: "\f058";
    font-size: 14px;
    font-family: taggboxsocialfonts;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
    -webkit-text-fill-color: #313b3f;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

.row__ {
    display: flex;
    align-items: flex-start;
    margin: 0 -30px;
    flex-wrap: wrap;
}

.col_12,
.col_5,
.col_6,
.col_7 {
    position: relative;
    padding: 0 40px;
    box-sizing: border-box;
}

.row_col_2 {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.row_inside__ {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 0;
}

.row_inside__ .col_12 {
    padding: 0 0;
    width: 100%;
}

.col_12 {
    width: 100%;
}

.row_col_2 .form__group {
    width: 50%;
}

.row__ .col_7 {
    width: 58.33333%;
}

.row__ .col_5 {
    width: 41.66667%;
}

.container_custom {
    padding: 0 50px;
    width: 100%;
    display: block;
}

@media screen and (min-width: 992px) {
    .row_col_2 .form__group:last-child label {
        min-width: auto;
    }
}

.form__group .dropdown-toggle,
.form__group .form__control {
    background-color: transparent;
    border: none;
    border-radius: 0;
    flex: 1;
    border-style: none;
    outline: 0;
    color: #313b3f;
    width: 100%;
}

.form__group .bootstrap-select .dropdown-toggle:focus {
    outline: 0 !important;
    outline-offset: 0;
    background-color: transparent;
}

.payment_modal_panel .bootstrap-select.open .btn,
.payment_modal_panel .bootstrap-select.open.dropup .btn {
    border-color: transparent;
}

.sr-root,
.subsription_info {
    margin-bottom: 45px;
}

.subscription_wrap .table>tbody>tr>td {
    padding: 15px 0px 10px 0px;
    width: 50%;
}

.plan_time__,
.wizard-wrap .table h3 {
    padding-right: 0px;
}

.subscription_wrap table h4,
.wizard-wrap .table h3 {
    padding-left: 0px;
}

.plan_img img {
    width: 30px;
    margin: 5px 0;
}

.price_btn label.active_plan {
    background-color: #838383;
    cursor: no-drop;
}

.pay_btns {
    display: inline-block;
}

.wizard-wrap table {
    margin-bottom: 0;
}

.subscription_wrap table h4 {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: inherit;
}

.wizard-wrap .table h3 {
    margin: 0;
    font-size: 18px;
}

.subscription_wrap table tbody tr:first-child td {
    border-top: none;
}

select.change-plan-type {
    color: #999;
}

.subsription_time label,
.subsription_time select,
.subsription_time span {
    display: block;
    margin: 0;
}

.plan_img {
    margin-right: 15px;
}

.subsription_time {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.change_plan_info a {
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 600;
    color: #2e91f7;
}

.pdback_btn img {
    height: 14px;
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
}

.pdback_btn {
    position: absolute;
    left: 0px;
    font-size: 14px;
    color: #333;
    top: -30px;
    opacity: 0.8;
    text-decoration: none;
}

.pdback_btn:hover {
    opacity: 1;
    color: #ff4050;
    text-decoration: none;
}

.subsription_time label {
    padding-left: 4px;
    cursor: pointer;
}

.coupon-section input {
    text-transform: uppercase;
}

.coupon-section {
    padding-top: 20px;
}

.coupon-section label {
    color: #333;
}

.coupon-section input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0 !important;
    font-size: 14px;
    padding: 0 15px;
}

.plan_info_wrap {
    display: -ms-flexbox;
    display: flex;
}

.coupon-row .input-group {
    display: none;
}

.plan_time__ {
    white-space: nowrap;
}

.features_table {
    background-color: #fff;
}

.features_table_wrap {
    margin-top: 20px;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 0 15px;
}

.features_table .table {
    background-color: #fff;
    margin-bottom: 0;
}

.features_btn_wrap {
    text-align: center;
    display: block;
    padding: 0px 0px 15px;
}

.features_table .table tr td,
.features_table .table tr th {
    text-align: center;
    border-color: #ebebeb;
    padding: 15px 20px;
}

.features_table .table tr th h4 {
    font-size: 18px;
    margin: 7px 0;
    font-weight: 700;
}

.features_table .table tr td:first-child,
.features_table .table tr th:first-child {
    text-align: left;
}

span.p_tooltip svg {
    width: 20px;
    height: 20px;
}

.qus_icon {
    width: 18px;
    height: 18px;
    margin-top: -3px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.payment_info .sr-root {
    margin-bottom: 20px;
    display: block;
}

.billing_title {
    display: flex;
    flex-direction: column;
    position: relative;
}

.pay_details {
    width: 100%;
}

.subscription_wrap .amount_details .plan_time__ .event-plan-description {
    color: #959595;
}

.sr-combo-inputs-row {
    display: flex;
    width: 100%;
}

.sr-combo-inputs-row.card-data {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-items: center;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 5px 0 rgb(37 47 53 / 6%);
    box-shadow: 0 2px 5px 0 rgb(37 47 53 / 6%);
    padding: 17px 20px;
    min-height: 55px;
    background-color: #fff;
}

.StripeElement {
    display: flex;
    flex-grow: 1;
}

.__PrivateStripeElement {
    width: 100%;
}

.sr-combo-inputs-row span {
    max-width: 130px;
    padding-left: 10px;
    min-width: 95px;
    margin: 0 20px 0 0;
    text-align: left;
    color: #545454;
    transition: all .3s;
    font-size: 14px;
    font-weight: 600;
}

@media screen and (max-width: 1600px) {

    .col_12,
    .col_5,
    .col_6,
    .col_7 {
        padding: 0 15px;
    }

    .payment-box.payment-method-box ul .add-card .card-img img {
        width: 40px;
    }

    .payment-box.payment-method-box ul .card-field .card-yyyy {
        width: 60px;
    }

    .payment-box.payment-method-box ul .card-field .card-number {
        width: 160px;
    }

    .payment-box.payment-method-box ul .card-field p {
        width: 110px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 1366px) {
    .pdback_btn {
        left: 0;
        top: -50px;
    }

    .container_custom {
        padding: 0 15px;
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 1300px) {
    .plan_info {
        padding: 30px 15px 15px 15px;
    }

    .subscription_wrap table h4 {
        font-size: 14px;
    }

    .wizard-wrap .table h3 {
        font-size: 14px;
    }

    .subscription_wrap table h4,
    .wizard-wrap .table h3 {
        padding-left: 10px;
    }

    .amount_details table.table {
        display: table;
    }

    .subscription_wrap .table>tbody>tr>td {
        padding: 20px 0;
        display: table-cell;
    }
}

@media screen and (max-width: 1200px) {
    .row__ {
        margin: 0 -15px;
    }

    .col_12,
    .col_5,
    .col_6,
    .col_7 {
        padding: 0 15px;
    }

    .row__ .col_5,
    .row__ .col_7 {
        width: 100%;
    }

    .wizard-wrap h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .plan_time__ {
        white-space: inherit;
    }

    .subscription_wrap {
        margin: 0px 0 45px;
    }
}

@media screen and (max-width: 991px) {
    .row_col_2 .form__group {
        width: 100%;
    }

    .payment_info .sr-root {
        margin-bottom: 15px;
    }

    .form_wizard .nav-tabs>li a h4 {
        line-height: 50px;
        font-size: 20px;
    }

    .form_wizard .nav-tabs>li a {
        width: 50px;
        height: 50px;
    }

    .form_wizard .nav-tabs>li.conn_line:after,
    .form_wizard .nav-tabs>li.conn_line:before {
        top: 23px;
    }

    .flex-col-3 {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .form_wizard {
        margin: 0 auto;
        border-radius: 0;
        display: contents;
    }

    .sr-combo-inputs-row span {
        display: none;
    }

    .form_summury {
        margin-top: 0;
    }

    ul.card_details li.cardNumber {
        padding-left: 40px;
    }

    ul.card_details li.cardNumber:before {
        left: 10px;
        width: 30px;
        height: 49px;
    }

    .form__group label {
        min-width: 100%;
        max-width: 100%;
        margin: 0 0px 4px 0;
        text-align: left;
        color: #333;
    }

    .form__group .form__control {
        width: 100%;
    }

    .form__group {
        -ms-display: block;
        display: block;
        padding: 8px 0px 8px 0px;
    }

    .wizard-wrap table h3 {
        font-size: 18px;
        padding: 0 15px;
    }

    .subscription_wrap table h4 {
        font-size: 14px;
    }

    .subscription_wrap .table>tbody>tr>td {
        padding: 20px 5px 20px 5px;
    }

    .subscription_wrap .table>tbody>tr>td:nth-child(2) {
        text-align: right;
    }

    .plan_time__ {
        white-space: nowrap;
    }

    .subscription_wrap table h4,
    .wizard-wrap .table h3 {
        padding-left: 0px;
    }

    .coupon-section input {
        font-size: 16px;
    }

    .plan_info {
        padding: 0px;
    }

    .row__ {
        margin: 0;
    }

    .col_12,
    .col_5,
    .col_6,
    .col_7 {
        padding: 0 0;
    }

    .subscription_wrap {
        margin: 15px 0 30px;
        padding: 20px;
    }

    .billing_fields {
        margin: 10px 0 0;
    }

    .billing_info {
        margin: 0 0 30px;
    }

    .wizard-wrap h3 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .plan_img {
        min-width: 30px;
        margin-right: 5px;
        padding-top: 5px;
    }

    .features_table .table tr td,
    .features_table .table tr th {
        padding: 12px 15px;
    }

    .features_table .table tr th h4 {
        font-size: 16px;
    }

    .features_table_wrap {
        padding-top: 10px;
    }

    ul.select_payment_g li {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
    }

    .subsription_time .change-plan-type .dropdown-menu {
        width: 100% !important;
    }

    ul.select_payment_g li {
        width: 100%;
    }

    ul.select_payment_g li:last-child {
        margin-left: 0;
    }

    .subscription_wrap .billing_fields .countrypicker {
        width: 100%;
    }

    .subscription_wrap .billing_fields .countrypicker button {
        padding-left: 0px;
    }
}

@media screen and (max-width: 480px) {
    ul.card_details {
        display: block;
    }

    li.cardNumber {
        width: 100%;
    }

    li.cardExpireDate {
        width: 70%;
    }

    li.cardCVV {
        width: 28%;
    }

    ul.select_payment_g li a img {
        max-height: 40px;
        max-width: 177px;
    }

    ul.select_payment_g li a {
        min-height: 0;
    }
}

@media screen and (max-width: 390px) {
    .plan_info {
        padding: 24px 10px 15px 10px;
    }

    .plan_info_wrap {
        display: block;
    }

    .plan_img {
        padding-top: 0px;
        display: inline-block;
        vertical-align: middle;
    }

    .subsription_time {
        display: inline-block;
        vertical-align: middle;
    }

    .change_plan_info {
        display: block;
        margin-top: 20px;
        text-align: right;
    }

    .amount_details table.table {
        display: block;
    }

    .amount_details table.table tbody,
    .amount_details table.table tr,
    .amount_details table.table td {
        width: 100%;
        display: block;
    }

    .subscription_wrap .table>tbody>tr>td {
        width: 100%;
        padding: 10px 15px;
        text-align: left;
    }

    .subscription_wrap table h4,
    .wizard-wrap .table h3 {
        padding-left: 0;
    }

    .pay_btns {
        display: block;
    }

    .stripe_footer {
        display: block;
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }
}

@media screen and (max-width: 360px) {
    .form_wizard .nav-tabs>li {
        padding: 0 2%;
    }

    ul.select_payment_g li p {
        max-width: 200px;
        line-height: 15px;
        top: 5px;
        margin-top: 0px !important;
    }
}

.colored_social_icons .fa-twitter {
    color: #26c4f1;
}

.colored_social_icons .fa-instagram {
    color: #cd486b;
}

.colored_social_icons .fa-facebook {
    color: #1878F2;
}

.colored_social_icons .fa-linkedin {
    color: #0e76a8;
}

.colored_social_icons .fa-rss {
    color: #ee802f;
}

.colored_social_icons .fa-flickr {
    color: #ff0084;
}

.colored_social_icons .fa-pinterest {
    color: #bd081c;
}

.colored_social_icons .fa-vimeo {
    color: #1ab7ea;
}

.colored_social_icons .fa-tumblr {
    color: #35465c;
}

.colored_social_icons .fa-youtube {
    color: #e62117;
}

.colored_social_icons .fa-google {
    color: #DD3D28;
}

.colored_social_icons .fa-yelp {
    color: #BD0301;
}

.colored_social_icons .fa-yammer {
    color: #255190;
}

.colored_social_icons .fa-slack {
    color: #481449;
}

.colored_social_icons .fa-airbnb {
    color: #F7565D;
}

.colored_social_icons .fa-soundcloud {
    color: #F85204;
}

.colored_social_icons .fa-workplace {
    color: #677B8C;
}

.colored_social_icons .fa-giphy {
    color: #110F17;
}

ul.platform__icons li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
    position: relative;
}

ul.platform__icons,
ul.platform__icons ul {
    padding: 0;
    list-style: none;
}

ul.platform__icons li ul {
    position: absolute;
    background-color: #fff;
    padding: 8px;
    min-width: 170px;
    box-shadow: 0 0 12px rgba(0, 0, 0, .1);
    border-radius: 3px;
    right: -18px;
    left: auto;
    top: 110%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    margin: 10px auto;
    text-align: center;
    z-index: 9;
}

ul.platform__icons li:hover ul.platform__more_ {
    top: 100%;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
}

ul.platform__icons li svg,
ul.platform__icons li i {
    vertical-align: middle;
    display: block;
    line-height: 30px;
}

#signage_features_table label {
    background: #037bfe;
    color: #fff;
    padding: 1px 10px;
    border-radius: 4px;
    float: right;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
}

.subscription_wrap .have-coupon {
    border: 1px dashed #CBCBCB;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 3px;
}

.subscription_wrap .have-coupon:hover {
    border-color: #9f9f9f;
}

.subscription_wrap .have-coupon span {
    display: inline-block;
    vertical-align: middle;
}

.subscription_wrap .have-coupon img {
    width: 6px;
    transform: rotate(180deg);
    margin-left: 10px;
    opacity: 0.6;
}

@media screen and (max-width: 1199px) {
    .pdback_btn {
        top: -350px;
    }
}

@media screen and (max-width: 767px) {
    .pdback_btn {
        top: -340px;
    }
}

@media screen and (max-width: 575px) {
    .pdback_btn {
        top: -340px;
    }

    div.price_container .box-flex .price_counter {
        width: 100%;
    }

    .price_container .box-flex .price_counter,
    .price_container .box-flex .price_counter:nth-child(2n) {
        margin-right: 0;
    }
}

.one_day_pass {
    margin-right: 30px;
    overflow: hidden;
    margin-top: -28px;
    border-radius: 12px;
    box-shadow: 0px 1px 26px rgb(0 0 0 / 15%);
}

.one_day_pass .price_card_wrap.price_counter {
    width: 100%;
}

.ugc-suite-section {
    box-shadow: 0 1px 2px rgb(0 0 0/6%), 0 2px 4px rgb(0 0 0/4%), 0 5px 14px rgb(0 0 0/2%);
    border-radius: 7px;
    color: #2b2b2b;
    background-color: #fff;
    margin: 0 auto;
    position: relative;
    align-items: center;
}

.ugc-suite-section .top_text {
    background-color: #e6f3fe;
    border-radius: 0 0 15px 15px;
    padding: 5px 20px;
    text-align: center;
    text-transform: uppercase;
    color: #2b2b2b;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    display: inline-block;
    position: absolute;
    top: 0px
}

#addonFeatures .card.card-custom.card-stretch.gutter-b {
    height: calc(100% - 32px);
}

.ugc-suite-section h3,
.ugc-suite-section p {
    margin-bottom: 20px
}

.ugc-suite-section .quote-btn .btn {
    padding: 12px 25px;
    // background-color: #037bfe;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 14px;
    margin-top: 6px
}

.ugc-suite-section .quote-btn .btn:hover {
    box-shadow: 0 0 10px #ff455596
}

.ugc-suite-section .quote-btn .btn svg {
    -webkit-transition: transform .3s ease-out;
    -moz-transition: transform .3s ease-out;
    -ms-transition: transform .3s ease-out;
    -o-transition: transform .3s ease-out;
    transition: transform .3s ease-out
}

.ugc-suite-section .quote-btn .btn:hover svg {
    -webkit-transform: translateX(5px);
    -moz-transform: translateX(5px);
    -ms-transform: translateX(5px);
    -o-transform: translateX(5px);
    transform: translateX(5px)
}

.ugc-suite-section .ugc-suite-left {
    padding: 35px 100px 15px 15px;
    position: relative
}

.ugc-suite-section .ugc-suite-left:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-image: radial-gradient(#959595, #fff, #fff);
    right: 0;
    top: 50%;
    transform: translate(0%, -50%)
}

.ugc-suite-section .ugc-suite-right {
    padding: 15px
}

.ugc-suite-section .ugc-suite-right h5 {
    margin-bottom: 20px
}

.ugc-suite-section .ugc-suite-right ul li {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px
}

.ugc-suite-section .ugc-suite-right ul li svg {
    margin-right: 5px
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .one_day_pass {
        margin-right: 0;
        overflow: visible;
        margin-top: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .one_day_pass .price_card_wrap .price_card {
        padding: 0px 0px;
        overflow: hidden;
        border-radius: 12px;
        box-shadow: 0px 1px 26px rgb(0 0 0 / 15%);
        max-width: 340px;
    }
}

@media(max-width:1600px) {
    .ugc-suite-section .top_text {
        top: 0px
    }
}

@media screen and (max-width:1399px) {
    .ugc-suite-section .ugc-suite-left {
        padding: 30px 15px 15px
    }

    .ugc-suite-section h3,
    .ugc-suite-section p {
        margin-bottom: 10px
    }
}

@media screen and (max-width:1199px) {
    .ugc-suite-section {
        height: auto
    }

    .price_container .box-flex .price_counter {
        margin-right: 2%;
        margin-bottom: 2%;
    }

    .price_container .box-flex .price_counter:nth-child(2n) {
        margin-right: 0;
    }

    .price_container .box-flex .price_counter {
        width: 49%;
        padding: 0;
    }

    .highlightCard .price_card {
        border: 0;
    }

    .price_container .box-flex .price_counter.highlightCard {
        margin-bottom: 2% !important;
    }
}

@media screen and (max-width:991px) {
    .one_day_pass {
        margin-right: 0;
        overflow: visible;
        margin-top: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .one_day_pass .price_card_wrap .price_card {
        padding: 0px 0px;
        overflow: hidden;
        border-radius: 12px;
        box-shadow: 0px 1px 26px rgb(0 0 0 / 15%);
        max-width: 340px;
    }

    .plan-row .plan-price,
    .payable-amount strong {
        font-size: 24px;
    }
}

@media screen and (max-width:767px) {
    .ugc-suite-section .ugc-suite-left:after {
        content: '';
        height: 1px;
        width: 100%;
        top: inherit;
        bottom: 0;
        transform: inherit
    }

    .ugc-suite-section .ugc-suite-left {
        padding: 0 15px 15px
    }

    .ugc-suite-section .top_text {
        position: relative
    }

    .ugc-suite-section h3 {
        margin-top: 20px
    }

    .d-flex.event_plans {
        flex-wrap: wrap;
    }

    .upgrade-plans>div {
        flex-wrap: wrap;

        .plan-box {
            width: 100%;
            // bottom: -66px;
        }

        .current-plan {
            margin-right: 0;
            margin-bottom: 66px;

            &::after {
                left: 0;
                right: 0;
                top: inherit;
                bottom: -66px;
                margin: 0 auto;
                transform: rotate(95deg);
            }
        }
    }

    .upgrade-modal .upgrade-title-section h2,
    .upgrade-modal .upgrade-title-section .h2 {
        font-size: 20px;
    }

    .payment-failed h2,
    .payment-failed .h2,
    .plan-row .plan-price,
    .payable-amount strong {
        font-size: 18px
    }

    .payment-failed .more-information.continue-with span,
    .upgrade-modal .upgrade-title-section p,
    .payment-failed p {
        font-size: 13px;
        line-height: normal;
    }

    .plan-box {
        padding: 15px;
    }

    .upgrade-plans {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

@media(max-width:479px) {
    .upgrade-modal .modal-body {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .upgrade-plans {
        >div {
            .current-plan {
                margin-right: 0;
                margin-bottom: 50px;

                &::after {
                    width: 57px;
                    height: 56px;
                    bottom: -46px;
                }
            }
        }
    }
}

@media (max-width: 1366px) {

    .main_title h3,
    .main_title .h3,
    .price_wrap h2 .valid_from,
    .price_wrap .h2 .valid_from,
    // .price_card h3,
    .price_card .h3,
    .for_good .card_content h3,
    .for_good .card_content .h3,
    .one_day_pass_title,
    .ugc-suite-section h3 {
        font-size: 13px;
    }

    .f2 {
        font-size: 11px;
        min-height: 36px;
    }

    .price_wrap h2,
    .price_wrap .h2 {
        font-size: 16px;
        min-height: 44px;
    }

    .highlightCard .price_card_inner .f2,
    .features_btn_ {
        // padding-bottom: 12px;
    }

    .price_btn .btn.btn-primary {
        font-size: 14px;
    }

    .new-tag {
        font-size: 10px;
    }

    .card_content .btn-outline-primary,
    .ugc-suite-section .top_text,
    .ugc-suite-section .ugc-suite-right ul li,
    .btn-color-mode-switch>label.btn-color-mode-switch-inner:before,
    .btn-color-mode-switch>label.btn-color-mode-switch-inner:after,
    .ugc-suite-section .quote-btn .btn,
    .features_btn_,
    .priceSwicher label {
        font-size: 11px;
    }

    .btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
        width: 135px;
        height: 38px;
    }

    .p_strip {
        width: calc(100% - 180px);
        left: 180px;
    }

    .showcase-title {
        font-size: 22px;
    }

    .showcase-batches ul li {
        width: 70px;
    }

    .showcase-batches ul li.leadger-batches {
        width: 90px;
    }

    .pay_details {
        height: calc(100vh - 90px);
        padding-bottom: 20px;
    }

    span.p_tooltip svg,
    .ugc-suite-section .quote-btn .btn svg {
        width: 14px;
        height: 14px;
    }

    .btn-color-mode-switch>label.btn-color-mode-switch-inner {
        width: 280px;
        height: 42px;
    }

    .priceSwicher-main {
        margin: 15px 0px 20px;
    }

    .ugc-suite-section .quote-btn .btn {
        padding: 10px 15px;
    }

    .price_meter.price_meter_new {
        padding: 15px !important;
    }
}