.analytics_main {
  margin-top: 48px;
  height: calc(100vh - 102px);
  overflow-y: auto;
}


.analytics_main .nav.nav-tabs.nav-tabs-line .nav-link {
  position: relative;
  border-bottom: none;
  white-space: nowrap;
}

.analytics_main .overflow-x-auto {
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 15px;
  padding-left:15px;
}

.analytics_main .nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled):after, 
.analytics_main .nav.nav-tabs.nav-tabs-line .nav-link.active:after, 
.analytics_main .nav.nav-tabs.nav-tabs-line .show>.nav-link:after {
  width: 100%
}

.analytics_main .nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.analytics_main .nav.nav-tabs.nav-tabs-line .nav-link.active,
.analytics_main .nav.nav-tabs.nav-tabs-line .show>.nav-link {
  border-bottom: none
}

.analytics_main .nav .show > .nav-link, 
.analytics_main .nav .nav-link:hover:not(.disabled), 
.analytics_main .nav .nav-link.active {
  color: #2b2b2b;
}

.analytics_main .nav.nav-tabs.nav-tabs-line .nav-link {
  padding: 18px 0 12px;
  margin: 0;
  margin-left: 2rem;
  font-size: 1.1rem;
}

.analytics_main .nav.nav-tabs.nav-tabs-line.top_nav_tabs .nav-link {
  padding: 25px 0 18px;
  margin-left: 0.5rem;
}
.analytics_main .nav-tabs.top_nav_tabs:after {
  border-bottom: none;
}
.con_user_img i, .icon_on_img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  padding: 2px;
  line-height: 10px;
  position: absolute;
  right: -6px;
  top: 0px;
}

.symbol-2by2 .icon_on_img {
  top: -6px;
}
.con_user_img i svg, .icon_on_img svg {
  max-width: 100%;
  display: inline-block;
  border-radius: 0;
  height: auto;
}

.users_row:hover {
  background-color: #f7f7f7;
}
.hover-secondary:hover {
  background-color: #f7f7f7;
}
.line-clamp-2, .line-clamp-3, .line-clamp-4, .line-clamp-5{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}
.line-clamp-4 {
  -webkit-line-clamp: 4;
}
.line-clamp-4 {
  -webkit-line-clamp: 5;
}


.m_card_cont .symbol.symbol-circle {
  height: 34px;
  width: 34px;
  text-align: center;
  color: #fff;
  display: block;
}

.m_grid_userinfo {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 215px;
}

.m_grid_author span {
  line-height: 12px;
  font-size: 10px;
  color: #959595;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

span.sepratedot {
  height: 3px;
  width: 3px;
  background-color: #959595;
  border-radius: 50%;
  margin: 0 4px;
  display: table;
  align-self: center;
}
.m_social_icon svg {
  width: 20px;
  height: 20px;
}
.top_posts_row{
  margin-right: -8px;
  margin-left: -8px;
}
.top_posts{
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
}
.post_type_score {
  margin-top: 5%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.post_score_border {
  height: 3px;
  width: 50px;
  margin: 0 auto;
  background-color: #ccc;
  border-radius: 5px;
}

.post_score {
  min-height: 84px;
}

.m_card_cont .post_cotnent {
  min-height: 40px;
}
.post_type_score .flex-column:first-child .post_score_border{
  background-color: #fc5362;
}
.post_type_score .flex-column:nth-child(2) .post_score_border{
  background-color: #fec736;
}
.post_type_score .flex-column:nth-child(3) .post_score_border{
  background-color: #5096ff;
}

.word_cloud {
  max-height: 358px;
}

.filter_btns .btn {
  align-items: center;
  display: flex;
}

.cursor-default {
  cursor: default !important;
}
.feeds-filter {
  max-width: 350px;
  min-width: 350px;
  width: 100%;
}

/* .daterangepicker {
  position: fixed;
} */

.feeds-filter .css-1pahdxg-control{
  box-shadow: none;
}

.feeds-filter .css-yk16xz-control, .feeds-filter .css-yk16xz-control:hover, .feeds-filter .css-1pahdxg-control:hover{
  border-color: #181C32;
}

.feeds-filter svg path{
  fill: #181C32 !important;
}

.feeds-filter .css-tlfecz-indicatorContainer, .feeds-filter .css-tlfecz-indicatorContainer{
  cursor: pointer;
}
.btn.btn-outline-dark:hover:not(.btn-text):not(:disabled):not(.disabled) svg path[fill] , .btn.btn-outline-dark:focus:not(.btn-text) svg path[fill] , .btn.btn-outline-dark.focus:not(.btn-text) svg path[fill] {
  fill: #fff !important;
}

.btn.btn-outline-dark svg path[fill]{
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #181C32 !important;
}

#postgraph-tabpane-shared_posts, #postgraph-tabpane-mentions_posts {
  overflow-y: auto;
  max-height: 432px;
}

.post_click_platform .apexcharts-legend-series {
  margin: 2% 5px !important;
}

.analytics_main .alert.custom-alert {
  margin-top: 1rem;
  margin-bottom: 0;
}

@media (min-width: 1600px){
  .post_click_platform .apexcharts-legend {
      display: block;
      top: 50% !important;
      bottom: inherit !important;
      transform: translate(0px, -50%);
      max-height: 300px;
  }
  .post_click_platform .apexcharts-legend-series {    
      margin: 5% 5px !important;
      display: block !important;
  }
  
  .post_click_platform .apexcharts-legend-marker {
      margin-right: 20px;
  }
}

.progress_sentiment.progress {
  height: 1.2rem;
  font-size: 0.75rem;
  border-radius: 20px;
  overflow: inherit;
  border-radius: 20px;
}

.progress_sentiment .progress-bar{
  position: relative;
  overflow: inherit;
  border-radius: 20px;
  min-width: 16px;
}

.progress_label {
  position: absolute;
  background-color: #fff;
  padding: 5px;
  border: 1px solid;
  border-color: #545454;
  bottom: 120%;
  border-radius: 3px;
  right: -25px;
  left: auto;
  opacity: 0;
  visibility: hidden;
  transition: all .1s linear;
}


.progress_sentiment:hover .progress_label{
  opacity: 1;
  right: -35px;
  visibility: visible;
}
.progress_label span {
  display: table;
  color: #545454;
  padding: 10px;
}
.progress_sentiment.pro_0 .progress-bar{
  background-color: #6AC259;
}
.progress_sentiment.pro_1 .progress-bar{
  background-color: #5ACBB7;
}
.progress_sentiment.pro_2 .progress-bar{
  background-color: #FFB74A;
}
.progress_sentiment.pro_3 .progress-bar{
  background-color: #FF7483;
}
.progress_sentiment.pro_4 .progress-bar{
  background-color: #F74B5B;
}

.progress_sentiment.pro_0 .progress_label{
  border-color: #6AC259;
}
.progress_sentiment.pro_1 .progress_label{
  border-color: #5ACBB7;
}
.progress_sentiment.pro_2 .progress_label{
  border-color: #FFB74A;
}
.progress_sentiment.pro_3 .progress_label{
  border-color: #FF7483;
}
.progress_sentiment.pro_4 .progress_label{
  border-color: #F74B5B;
}
.donut_chart_wrapper{
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.donut_chart_wrapper_post{
  height: 420px;
  padding: 5% 0;
}

.analytics_main .card.card-custom > .card-body{
  min-height: 300px;
}
.analytics_subheader.subheader{
  z-index: 9;
  box-shadow: 0 10px 30px 0 rgba(82,63,105,.08);
  
}
.modal-footer .btn.btn-light:not(:disabled):not(.disabled).active{
  color: #fff;
  background-color: #ff4051;
  border-color: #ff4051;
}
@media (min-width: 992px){
.analytics_subheader.subheader{
  z-index: 9;
}
}
.modal .modal-header .close span{
  display: flex;
}

.see_more_btn_tabs {
  position: absolute;
  right: 1.25rem;
  top: 15px;
  z-index: 2;
}
.more_container {
  min-height: 500px;
  width: 100%;
}
.mentions_profile_items_info .post_count,
.mentions_profile_items_info .followers_count{
  font-size: 12px;
}
.author_name, .followers_count {
  width: 250px;
  max-width: 100%;
}
.analytics_main .users_row .flex-grow-1 {
  max-width: calc(100% - 220px);
}
.analytics_main .modal-dialog .users_row .flex-grow-1 {
  max-width: inherit;
}
.influential-data-list{
  max-height: 500px;
  overflow: auto;
  padding-right: 10px;
  margin-right: -15px;
}
.influential_contributors_custom_modal .btn-outline-dark{
  border-color: #545454;
  color: #545454;
}
.influential_contributors_custom_modal .export-pdf{
  border-color: #E0E0E0;
}

.influential_contributors_custom_modal .author_name{
  width: 160px;
  max-width: inherit;
  margin-right: 30px;
}

.symbol .symbol-label{
  image-orientation: none;
}

.filter_btn_wrap button{
  color: #181C32;
  box-shadow: none;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.filter_btn_wrap button i{
  margin-right: 5px;
}
.filter_btn_wrap svg {
  height: 20px;
}
.filter_btn_wrap .css-1qprcsu-option {
  background-color: #ffffff;
}
.filter_btn_wrap .css-1pcexqc-container{
  box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 2px 4px rgb(0 0 0 / 4%), 0 5px 14px rgb(0 0 0 / 2%);
}
.filter_btn_wrap .css-6y1x9t-control{
  box-shadow: none;
  outline: none;
}
.filter_btn_wrap .css-6y1x9t-control:hover{
  border-color:#181C32;
  outline: none;
  box-shadow: none;
}
.filter_btn_wrap .css-11unzgr > div{
  cursor: pointer;
}
.filter_btn_wrap .css-11unzgr > div.active, .filter_btn_wrap .css-11unzgr > div:hover{
  background-color:#f7f7f7;
}

.no-repeat-fit-background{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.daterangepicker{
  border: 0px;
  box-shadow: 0px 0px 10px #e1e1e1;
  padding: 10px 0px;
}
.daterangepicker .ranges ul{
  width: 200px;
}
.daterangepicker td.in-range {
  background-color: #fff1f2;
}
.daterangepicker .ranges li.active{
  background-color: transparent;
  color: #545454;
}
.daterangepicker .ranges li.active:before {
  border: 5px solid #ff4051;
}
.daterangepicker .ranges li{
  position: relative;
  padding-left: 45px;
}
.daterangepicker .ranges li:before{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  left: 20px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  border: 1px solid #ccc;
}
.daterangepicker .ranges li[data-range-key='Custom Range']{
  border-top: 1px solid #e3e3e3;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #ff4051;
}
.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #ffdce1;
}
.list_category_icon svg{
  width: 20px;
  height: 20px;
}
.profile_with_users_list .user_info h6 span{
  max-width: 200px;
}
.feeds-filter .css-1rhbuit-multiValue {
  background-color: #E4E6EF;
}

.no-pointer-events{
  pointer-events: none;
}
.sub_author_name{
  font-size: 12px;
  font-weight: normal;
}

.modal_wrapper {
  overflow-y: auto;
  height: calc(100vh - 220px);
}
.react-search-feed > div button ~ div{
  right: 0px;
}
.react-search-feed .check-feed__option {
  width: 256px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  align-items: center;
}
.react-search-feed .check-feed__option i{
  font-size: 14px;
  margin-right: 5px;
}

@media (max-width: 1800px){
  .author_name, .followers_count {
      width: 190px;
  }
}

@media (max-width: 1600px){
  .author_name, .followers_count {
      width: 170px;
      font-size: 13px;
  }
}

@media (min-width: 992px){
  .modal-xxl {
      max-width: 800px;
  }
}
@media (min-width: 1200px){
  .modal-xxl {
      max-width: 1140px;
  }
}
@media (min-width: 1200px){
  .modal-xxl {
      max-width: 1100px;
  }
}
@media (min-width: 1600px){
  .modal-xxl {
      max-width: 1400px;
  }
  .col-xxxl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
  }
}


@media (min-width: 991px){
  .daterangepicker{
      position: fixed;
      /* top: 128.297px !important; */
      top: 99px !important;
      left: auto !important;
      right: 24.9996px !important;
      padding-bottom: 0px;
  }
  .daterangepicker:before{
      content: none;
  }
  .daterangepicker.show-calendar li[data-range-key='Custom Range']{
      position: absolute;
      bottom: 4px;
      border: 0px;
      margin: 0px;   
  }
  .daterangepicker.show-calendar li:hover{
      background-color: transparent;
  }
  .daterangepicker.show-calendar .ranges li{
      font-size: 14px;
      padding: 11px 12px 11px 45px;
  }
  
  .daterangepicker.opensright:after{
      content: none;
  }
}
@media (min-width: 1600px) and (max-width: 1700px){
  .m_post_title, .m_list_post_title{
      max-width: 130px;
  }
  .m_grid_author span{
      max-width: 50px;
  }

}
@media (max-width: 1600px){
.top_posts{
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.feeds-filter {
  min-width: 300px;
}
.card.card-custom > .card-body, .card-header {
  padding: 1rem 1.25rem;
}
.nav.nav-tabs.nav-tabs-line .nav-link {
  margin-left: 1.25rem;
}

.card-body {
  padding: 1rem 1.25rem;
}

.progress_label {
  right: -5px;
}

.progress_sentiment:hover .progress_label {
  right: -15px;
}
.post_click_platform .apexcharts-legend {
  align-items: left;
  justify-content: space-around !important;
}
}
@media (max-width: 1400px){
.author_name, .followers_count {
  width: 170px;
}
}
@media (max-width: 1200px){
.top_posts{
  flex: 0 0 50%;
  max-width: 50%;
}
.author_name, .followers_count {
  width: 120px;
}
.users_row .btn-min-w {
  min-width: auto;
}
.users_row .flex-grow-1 {
  max-width: calc(100% - 220px);
}
.nav.nav-tabs.nav-tabs-line .nav-link {
  font-size: 14px;
}
.card.card-custom > .card-header .card-title, .card.card-custom > .card-header .card-title .card-label {
  font-size: 14px;
}
.nav.nav-tabs.nav-tabs-line .nav-link {
  margin-left: 1rem;
}
}


@media (max-width: 991px){
.daterangepicker {
  position: absolute;
  z-index: 90;
}
.donut_chart_wrapper_post {
  padding: 5% 0 0 0;
}
.btn_txt {
  font-size: 0px;
}
.filter_btns .btn.btn-sm .svg-icon{
  margin-right: 0rem;
}
.post_type_score {
  margin-top: 0%;
  margin-bottom: 15px;
}
.modal_wrapper {
  height: calc(100vh - 270px);
}
.filter_btn_wrap .btn-more{
  display: block !important;
}

.mobile-more-option{
  position: absolute;
  flex-direction: column;
  background-color: #fff;
  width: 80px;
  right: 20px;
  z-index: 1;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 5px;
  display: none !important;
}
.mobile-more-option.active{
  display: block !important;
}
.react-search-feed > div button ~ div{
  right: 0px;
}
.mobile-more-option button{
  width: 100% !important;
  margin: 0px !important;
}
.mobile-more-option .m_refresh{
  width: 100%;
}
}

@media (max-width: 580px){
  .top_posts{
      flex: 0 0 100%;
      max-width: 100%;
  }
  .feeds-filter {
      max-width: 100%;
      min-width: auto;
  }
  .filter_btn_wrap {
      width: 100%;
  }
  .font-size-h5 {
      font-size: 1.1rem !important;
  }
  .card.card-custom > .card-header .card-title, .card.card-custom > .card-header .card-title .card-label {
      font-size: 1.1rem;
  }
  .conn_user_info h6 {
      font-size: 1rem;
  }
  .daterangepicker{
      width: 200px;
  }
  .daterangepicker.show-calendar{
      width: auto;
  }
  .daterangepicker .ranges ul{
      width: auto;
  }
  .daterangepicker.show-ranges.ltr .drp-calendar.left{
      border-left: 0px;
      border-top: 1px solid #ddd;
  }
}
@media (max-width: 480px){	
  .see_more_btn_tabs {	
      top: 17px;
      right: 8px;	
      font-size: 12px;
  }	
  .nav_with_see_more.nav.nav-tabs.nav-tabs-line .nav-link {	
      padding: 25px 0 12px;	
  }
  .post_click_platform .apexcharts-legend{
      justify-content: start !important;
  }
}
@media (max-width: 350px){
.author_name, .followers_count {
  max-width: 100px;
  width: 100%;
}
}


.user_details_section .user_images span,
.mentions_profile_items span{
  display: block;
  background-size: cover;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: #f7f7f7;
}
.user_details_section .user_info h6 svg{
  width: 20px;
  margin-left: 10px;
}
.user_details_section .user_info h6 small{
  color: #000;
  font-size: 14px;
}
.user_details_section .user_info p{
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 12px;
}
.users_list{
  max-height: 450px;
  overflow: auto;
  padding-right: 10px;
  margin-right: -15px;
}
.users_list .user_profile_items span{
  height: 100%;
  width: 100%;
  display: block;
  background-size: cover;
  border-radius: 5px;
  background-color: #f7f7f7;
}
.users_list .user_profile_items_info h6 svg{
  width: 20px;
}
.users_list .user_profile_items_info .author_username{
  margin-bottom: 10px;
  font-size: 12px;
}
.user_profile_items_title strong{
max-width: 180px;   
}
.user_profile_disc p{
  margin-bottom: 10px;
  font-size: 12px;
}
.user_profile_data p .svg-icon.svg-icon-lg svg{
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}
.user_profile_data p strong{
  width: 80px;
  text-align: right;
}
.mentions_thumbnail_items span{
  height: 100%;
  width: 100%;
  display: block;
  background-size: cover;
  border-radius: 5px;
  background-color: #f7f7f7;
}
.mentions_user_info h6 svg{
  width: 20px;
}
.mentions_profile_data h6{
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.readMore a.morelink,
.readMore a.lesslink{
  color: #545454;
  display: block;
}
.readMore a.morelink:hover,
.readMore a.lesslink:hover{
  color: #ff4051;
  display: block;
}
.mentions_social_share{
  margin-top: 50px;
}
.mentions_social_share span + span{
  margin-left: 25px;
}
.mentions_social_share span i{
  color: #ccc;
}
#postgraph-tabpane-mentions_posts .con_user_img span,
#postgraph-tabpane-shared_posts .con_user_img span{
  width: 40px;
  height: 40px;
}
.card-stretch.Contributors .card-header,
.card-stretch.ActiveUsers .card-header{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.card-stretch.Contributors .card-header .card-title,
.card-stretch.ActiveUsers .card-header .card-title{
  margin-right: 0px !important;
}
@media (max-width: 991px){
  .mobile-w-100{
      width: 100% !important;
  }
  .mobile-d-block{
      display: block !important;
  }
  .user_profile_data p{
      font-size: 12px;
      margin-bottom: 10px;
  }
}
@media (max-width: 580px){
  .user_details_inner .user_info h6{
      display: block !important;
  }
  .user_profile_data p span{
      width: auto !important;
  }
  .user_profile_data p strong{
      width: auto;
  }
  .users_list{
      margin-right: 0px;
  }
  .user_details_section .user_info h6 svg{
      margin-left: 0px;
  }
  .profile_with_users_list .user_info h6 span{
      display: block;
  }
  .user_details_section .user_info h6 small{
      display: block;
      margin-left: 0px !important;
      margin-top: 5px;
  }
  .shwoing-filter{
      display: none !important;
  }
  .btn-min-w{
      min-width: auto;
  }
  .font-size-0{
      font-size: 0px;
  }
  .nav.nav-tabs.nav-tabs-line .nav-link{
      font-size: 13px;
  }
  .react-search-feed > div{
      position: inherit !important;
  }
  .react-search-feed > div button{
      padding-left: 0px;
  }
  .react-search-feed > div button ~ div{
      right: inherit;
      left: 50%;
      width: 90%;
      transform: translate(-50%, 0%);
  }
}
.instatooltip > .tooltip-inner {
max-width: 70%;
}

.pdf-loading{
  color: #333333!important;
  /* background-color: #181C32!important;
  border-color: #181C32!important; */
}
.pdf-loading.btn.btn-outline-dark svg path[fill], .pdf-loading.btn.btn-outline-dark:disabled .svg-icon svg g [fill] {
  fill: #333 !important;
}
@media (max-width: 580px){
  .influential_contributors_custom_modal .conn_user_info{
      width: 100%;
  }
}

.search-input.form-control:focus {
  color: #3F4254;
  background-color: #ffffff;
  border-color: #2b2b2b;
  outline: 0;
}



.influ_social span svg {
  width: 1.2em;
  height: 1.2em;
}
.user_info h6 {
  max-width: 230px;
}
.user_profile_items img {
  height: 170px;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.user_profile_info svg{
  width: 1.5em;
  height: 1.5em;
}
.influ_tabs .btn svg {
  width: 1.2em;
  height: 1.2em;
  margin-right: 8px;
}
.influ_primary {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease;
  
}
.influ_primary span {
  display: flex;
  font-size: 10px;
  border-radius: 0px 0px 0px 3px;
  background-color: #6ac259;
  color: #fff;
  text-align: center;
  padding: 2px 4px;
}

.influ_users_row, .influ_users_add{
  transition: all .3s ease;
  border-color: #EBEDF3;
}
.influ_users_row:hover, .influ_users_row:not(:disabled):not(.disabled).active {
  border-color: #6ac259;
  box-shadow: 0px 0px 0px .5px #6ac259 !important;
}

.influ_users_row:hover .influ_primary, .influ_users_row.active .influ_primary {
  opacity: 1;
  visibility: visible;
}

.influ_primary span svg {
  width: 13px;
  height: 15px;
}

.influ_primary span svg path{
  fill:#fff;
}

.influ_net_dropdown .menu-icon svg {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 5px;
}

.influ_net_dropdown .menu-item .btn {
  font-size: 12px;
}

.influ_net_dropdown .menu-item {
  border-bottom: 1px solid #f3f6f9;
}

.influ_net_dropdown .menu-item:last-child {
  border-bottom: none;
}

.influ_net_dropdown .menu-item:hover{
  transition: color .2s ease,background-color .2s ease;
  background-color: rgba(245,248,250,.8);
}

.influ_net_dropdown {
  display: block;
  width: 200px;
  /* overflow-y: auto; */
  /* width: 100%; */
}

.influ_users_row .infl_network_name{
  width: 100px;
}

.influ_user_link {
  max-width: calc(100% - 200px);
}
@media (max-width: 991px){

.user_profile_items img {
  height: auto;
}
}
@media (max-width: 767px){
.influ_user_link{
  order: 13;
  width: 100%;
}

.infl_add_url {
  order: -1;
  width: 100%;
}
.influ_user_link {
  max-width: 100%;
}
.influ_users_row{
  flex-wrap: wrap;
}
.apexcharts-tooltip{
  border-radius: 3px !important;
  box-shadow: 0px 0px 6px 1px #00000029 !important;
}
.apexcharts-tooltip-series-group{
  background-color: #FFF !important;
  color: #000;
  padding: 1px 25px 3px 5px !important;
}
.apexcharts-tooltip-marker{
  display: block !important;
  border-radius: 0px !important;
  width: 4px !important;
  height: 34px !important;
  margin-right: 5px !important;
}
.apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-label{
  display: block !important;
  color: #545454;
}
.apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-value{
  margin-left: 0px;
  font-size: 18px;
  line-height: 18px;
}
.apexcharts-tooltip-y-group{
  padding: 3px 0 0px !important;
}
#sentiment-tabpane-sstatistics .loader-container, #postgraph-tabpane-posttype .loader-container{
  min-height: 400px;
}
#sentiment-tabpane-sposts .loader-container{
  min-height: 436px;
}
#postgraph-tabpane-mentions_posts .loader-container{
  min-height: 430px;
}
#postgraph-tabpane-shared_posts .loader-container{
  min-height: 410px;
}
.TopPerformancePosts .top_posts_row .loader-container{
  min-height: 242px;
}
.custom-profile-items .img-fluid {
  height: 250px;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-width: 100%;
}
@media (max-width: 998px){
  #sentiment-tabpane-sstatistics .loader-container,
  #sentiment-tabpane-sposts .loader-container,
  #postgraph-tabpane-mentions_posts .loader-container,
  #postgraph-tabpane-shared_posts .loader-container{
      min-height: auto;
  }
}
}
.influ_posts_count-search .search-input-box{
  width: 0px;
  overflow: hidden;
}
.show-search{
  position: relative;
}

.show-search .influ_posts_count-search .search-input-box{
  width: 140px;
}
.show-search .influ_posts_count-search{
  border: 1px solid hsl(0,0%,80%);
  padding-left: 10px;
  border-radius: 4px;
  position: absolute;
  right: 40px;
  background-color: #fff;
  top: 0px;
}
.show-search .influ_posts_count-search button{
  border: 0px;
}
.influ_posts_count-search .search-input-box .search-input{
  align-items: center;
  background-color: hsl(0,0%,100%);
  border:0px;
  border-radius: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  padding: 0px;
}
@media(max-width:592px) {
  .influ_tabs ul li + li button{
      min-width: 40px;
  }
  .influ_tabs ul li + li button svg{
      margin-right: 0px !important;
  }
  .influ_tabs ul li + li button span{
      font-size: 0px;
  }
} 
@media (max-width: 991px){
.analytics_main {
  margin-top: 0px;
  height: calc(100vh - 110px);
}
}
@media (max-width: 1366px) {
  .con_user_img i, .icon_on_img{
    right: -2px;
    top: 2px;
  }
  .filter_btn_wrap button{
    font-size: 11px !important;
  }
  .display-6{
    font-size: 1.8rem;
  }
  .author_name, .followers_count{
    font-size: 11px;
  }
}