.sw_analytics_main {
  margin-top: 62px;
  height: calc(100vh - 116px);
  overflow-y: auto;
}

.sw-anlts-nav-tabs .nav-link {
  padding: 8px 10px;
  color: #707070;
  border-radius: 4px;
  font-weight: bold;
}

.sw-anlts-nav-tabs .nav-link.active,
.sw-anlts-nav-tabs .nav-link:hover {
  background-color: #f2f3f7;
  color: #2b2b2b;
}

.card-border-top {
  border: 0px !important;
  border-top: 4px solid #5E78F4 !important;
}

.card-border-top.top-border-0 {
  border-color: #5E78F4 !important;
}

.card-border-top.top-border-3 {
  border-color: #7dd677 !important;
}

.card-border-top.top-border-1 {
  border-color: #F5AD66 !important;
}

.card-border-top.top-border-2 {
  border-color: #FE60CE !important;
}

.card-shadow {
  box-shadow: 0px 1px 4px #15223224;
}

.sw-custom-nav.nav.nav-tabs.nav-tabs-line .nav-link.active,
.sw-custom-nav.nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled),
.sw-custom-nav.nav.nav-tabs.nav-tabs-line .show>.nav-link {
  position: relative;
  border: 0px;
  font-weight: 700;
}

.sw-custom-nav.nav.nav-tabs.nav-tabs-line .nav-link.active:after,
.sw-custom-nav.nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled):after,
.sw-custom-nav.nav.nav-tabs.nav-tabs-line .show>.nav-link:after {
  content: '';
  display: block;
  background: linear-gradient(109deg, #f5a267, #d43e8e, #5364d3);
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.sw-custom-table thead tr th {
  font-weight: 400;
  padding: 10px 15px;
  white-space: nowrap;
}

.sw-custom-table tbody tr td {
  padding: 15px;
}

.recent-post {
  display: flex;
}

.recent-post .splide__track {
  width: 100%;
}

.recent-post li {
  margin-right: 3px;
}

.recent-post li img {
  width: 100% !important;
  height: 45px !important;
  object-fit: cover !important;
  border-radius: 4px;
  min-height: auto !important;
  margin: 0px !important;
  margin-right: 5px;
}

.img-45 {
  width: 45px;
  height: 45px !important;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 5px;
}

.bulits {
  height: 15px;
  width: 15px;
}

.count-tag {
  background-color: #f1f1f1;
  padding: 5px 12px;
  border-radius: 15px;
}

.engagment-tabs.nav.nav-tabs.nav-tabs-line .nav-link {
  padding: 10px 5px;
}

.symbol .social-icon {
  position: absolute;
  width: 22px;
  bottom: -2px;
  background-color: #fff;
  border-radius: 50px;
  height: 22px;
  text-align: center;
  right: -5px;
  border: 2px solid #fff;
}

.favorite-icon .count {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

span.font-size-h6 {
  font-size: calc(11px + .1vw + .1vh) !important;
}

.display-6 {
  font-size: calc(9px + .7vw + .7vh);
}

.h3,
h3 {
  font-size: calc(9px + .7vw + .7vh);
}

h6,
.h6 {
  font-size: calc(12px + .1vw + .1vh) !important;
}

.gutter-b {
  margin-bottom: 10px !important;
}

.mx-width-80 {
  max-width: 80px;
  display: block;
  text-align: center;
}

table .alert-success {
  background-color: #d1e7dd78;
}

table .alert-danger {
  background-color: #f8d7da7d;
}

.sw_analytics_main .table td,
.sw_analytics_main .table th {
  padding: 10px 15px;
}

.sw_analytics_main .table th {
  color: #9b9b9b;
}

.splide__arrow {
  background-color: #fff !important;
  box-shadow: 5px 3px 10px #ccc;
  height: 32px !important;
  width: 32px !important;
}

.splide__arrow svg {
  fill: #5a5a5a !important;
  width: 22px !important;
  height: 22px !important;
}

.splide__arrow--next {
  right: 0px !important;
}

.splide__arrow--prev {
  left: 0px !important;
}

table .splide__arrow {
  background-color: #fff !important;
  box-shadow: 5px 3px 10px #ccc;
  height: 22px !important;
  width: 22px !important;
}

table .splide__arrow svg {
  fill: #5a5a5a !important;
  width: 12px !important;
  height: 12px !important;
}

table .splide__arrow--next {
  right: -12px !important;
}

table .splide__arrow--prev {
  left: -12px !important;
}

.count-box {
  border: 1px solid #ccc;
  margin: 0px 3px;
  border-radius: 4px;
  padding: 10px 0px;
}

.tab-header-list {
  border-bottom: 1px solid #d1d1d1 !important;
}

.tab-header-list li a.active {
  border-bottom: 2px solid #000;
}

.score-count {
  background-color: #f2f3f7;
  padding: 2px 4px 2px 2px;
  border-radius: 3px;
  font-size: 11px;
}

.sentiment-statistics {
  position: relative;
}

.sentiment-statistics .graph-bg {
  position: absolute;
  right: 0px;
  width: 100%;
  bottom: 0px;
  object-fit: cover;
  padding-left: 175px;
}

.sentiment-statistics .svg-icon svg {
  width: 34px !important;
  height: 34px !important;
}

.progress_sentiment .progress-bar {
  height: 16px;
  border-radius: 50px !important;
  opacity: 0.9;
  z-index: 1;
}

.apexcharts-tooltip {
  border: 3px solid #FFF;
  border-radius: 3px !important;
}

.apexcharts-tooltip .apexcharts-tooltip-series-group {
  padding: 5px 10px !important;
}

.apexcharts-tooltip-marker {
  margin-right: 0px !important;
  width: 10px !important;
  height: 10px !important;
}

.apexcharts-tooltip-text {
  background-color: #fff;
  color: #000;
  margin-left: 0px;
}

.apexcharts-tooltip-y-group {
  padding: 0px 0px 0px 5px !important;
  display: flex;
  align-items: center;
}

.apexcharts-tooltip-y-group span {
  display: block;
  margin: 0px;
}

.apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-value {
  font-size: 14px;
  line-height: 14px;
  margin-left: 5px;
}

.apexcharts-legend-series {
  background-color: #f2f3f7;
  padding: 5px 10px;
  border-radius: 4px;
  margin: 0px 5px !important;
}

.apexcharts-legend-series .apexcharts-legend-text {
  font-weight: 600 !important;
  padding-left: 20px;
}

.apexcharts-legend-series.apexcharts-inactive-legend {
  background-color: transparent;
  opacity: 1;
}

.apexcharts-legend-series.apexcharts-inactive-legend .apexcharts-legend-text {
  color: #707070 !important;
}

.react-search-feed button {
  box-shadow: none;
  border: 1px solid #e4e6ef;
}

.sw_analytics_main .table td,
.sw_analytics_main .table th {
  white-space: nowrap;
}

.m_user_info {
  margin-top: -20px;
}

.m_social_icon_new {
  right: 4px;
  top: 18px;
  height: 16px;
  width: 16px;
}

.m_social_icon_new svg {
  height: 14px;
  width: 14px;
}

.post-status {
  padding: 2px 10px;
  border-radius: 20px;
}

.bg-position {
  background-color: #e2f7e9;
}

.mb-lst-0:last-child {
  margin-bottom: 0px !important;
}

.placeholder-image {
  min-height: 300px;
}

.sw-theme .btn.btn-light:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light:not(:disabled):not(.disabled).active,
.show>.btn.btn-light.dropdown-toggle,
.show .btn.btn-light.btn-dropdown,
.btn.btn-hover-dark:hover {
  color: #fff !important;
  background-color: #1a2028 !important;
}

.btn.btn-hover-dark:hover i {
  color: #fff !important;
  background-color: #1a2028 !important;
}

.symbol.symbol-170 .symbol-label {
  width: 170px;
  height: 170px;
}

.chart-bg {
  width: 100%;
  justify-content: space-between;
  height: 100%;
  position: absolute;
  bottom: 0px;
  align-items: end;
}

.chart-bg::before {
  content: '';
  display: block;
  height: 1px;
  width: calc(100% - 20px);
  border-bottom: 1px dashed #909FAB;
  position: absolute;
  left: 9px;
  bottom: 29px;
}

.chart-bg span {
  position: relative;
  display: flex;
  height: 100%;
  align-items: end;
  padding-right: 10px;
}

.chart-bg span:before {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 88%;
  /* background-color: #efefef; */
  border-left: 1px dashed #E0E3E8;
  left: 50%;
  bottom: 30px;
}

.symbol.symbol-35>.symbol-image {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
}

.top-perfom-post>div {
  flex-direction: column;
  width: 100% !important;
}

.top-perfom-post>div .text-left {
  text-align: center !important;
  margin-top: 10px;
}

.top-perfom-post>div .text-left .conn_auther_info {
  justify-content: center;
}

.top-perfom-post>div .conn_user_info {
  min-height: 35px;
}

.top-perfom-post .conn_user_info .author_name {
  margin: 0 auto;
}

.top_posts_row .top_posts {
  display: grid;
  height: 100%;
}

.conn_user_info {
  width: 100% !important;
}

.rounded-8 {
  border-radius: 8px !important;
  overflow: hidden;
}

.size-12 {
  width: 12px;
  height: 12px;
}

.popup-author .w-50 {
  width: 100% !important;
}

.popup-tags-list ul li {
  border-radius: 20px;
  min-width: 60px;
}

.popup-connnected button {
  width: 100%;
}

.popup-featur-list li p span {
  color: #959595;
}

.influential-data-list table thead tr th {
  white-space: nowrap;
}

.symbol.symbol-100 .symbol-label img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.top_posts_new {
  max-width: 100% !important;
}

.play-media {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.play-media svg {
  -webkit-filter: drop-shadow(10px 10px 10px #000);
  filter: drop-shadow(10px 10px 10px #000);
}

.modal-content .splide {
  width: 77%;
}

.more_container {
  min-height: auto !important;
}

table tr td .w-50 {
  width: 100% !important;
}

.apexcharts-text tspan {
  font-size: 14px;
  fill: #7c7c7c;
}

.apexcharts-text {
  background: #ccc;
}

.blinking-marker {
  position: relative !important;
  z-index: 10 !important;
  animation: blink 1s infinite !important;
}

/* Animation to make the marker blink */
@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  /* Change the opacity to create the blink effect */
  100% {
    opacity: 1;
  }
}

/* Adjust the position of the marker if needed */
.blinking-marker circle {
  position: relative !important;
}


.tooltip-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  position: absolute;
}

.apexcharts-tooltip-item {
  padding: 8px 15px 8px 5px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: start;
  color: #fff;
}

.apexcharts-tooltip img {
  width: 20px;
  margin-right: 3px;
}

.apexcharts-tooltip-item>div {
  font-size: 12px;
}

.apexcharts-tooltip-item>div strong {
  font-size: 13px;
}

.conn_auther_info small {
  max-width: 100px;
  width: auto;
}

.bg-red {
  background: red;
}

.bg-yelow {
  background-color: #f3e104;
  color: #2b2b2b;
}

.bg-green {
  background-color: green;
}

.last-child-mb-0:last-child {
  margin-bottom: 0px !important;
}

.last-child-border-0:last-child {
  border-bottom: 0px !important;
}

.thumbnail-preview-popup .splide__arrow {
  height: 32px !important;
  width: 32px !important;
}

.thumbnail-preview-popup .splide__arrow svg {
  width: 22px !important;
  height: 22px !important;
}

.sw_theme .btn.btn-primary {
  white-space: nowrap;
}

.trimPostContent {
  display: inline;
}

.count-box-card {
  width: 50%;
}

.mx-height-419 {
  max-height: 419px;
  overflow-y: auto;
}

.assetsType .apexcharts-tooltip .apexcharts-tooltip-series-group {
  padding: 0px 0px 0px 3px !important;
}

.assetsType .apexcharts-tooltip .apexcharts-tooltip-text {
  padding: 5px;
}

.assetsType .apexcharts-tooltip-y-group {
  flex-direction: column;
  align-items: start;
}

.assetsType .apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-value {
  margin-left: 0px;
  font-size: 18px;
  line-height: 20px;
}

.assetsType .apexcharts-datalabels-group text:nth-child(2) {
  font-weight: 600;
  font-size: 26px;
}

.w-0 {
  width: 0px;
}

.progress_sentiment.progress-tooltip-over strong {
  position: absolute;
  right: 0px;
  top: -20px;
}

.min-h-350 {
  min-height: 350px;
}

.min-h-350 iframe {
  min-height: 350px;
}

.mx-h-470 {
  max-height: 470px;
}

.fb_iframe_widget span {
  text-align: center !important;
}

.fb_iframe_widget span iframe {
  position: inherit;
}

.recentPost-modal {
  background-color: rgba(0, 0, 0, 0.2);
}

.recentPost-modal .modal-content {
  background-color: transparent;
  box-shadow: none;
}

.recentPost-modal .modal-header {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
}

.recentPost-modal .modal-header .btn-close {
  background-color: #ffffff;
  opacity: 1;
  border-radius: 100%;
  box-shadow: 0px 0px 10px #8f8f8f;
}

.recentPost-modal .influential_contributors_custom_modal {
  padding: 0px !important;
}

.recentPost-modal .row {
  align-items: inherit !important;
  margin: 0px;
}

.recentPost-modal .influential_contributors_img {
  background-color: #000;
  padding: 0px;
}

.recentPost-modal .influential_contributors_content {
  background-color: #fff;
  padding: 10px 20px;
}

.influential_contributors_content.col-md-12 .popup-connnected {
  margin-top: 0px !important;
}

.influential_contributors_content.col-md-12 .conn_status_wrap button {
  width: auto;
  padding: 8px 30px;
}

.recentPost-modal .splide__arrow--next {
  right: -40px !important;
}

.recentPost-modal .splide__arrow--prev {
  left: -40px !important;
}

.influential-data-list {
  width: 100%;
}

.recentPost-modal .influential-data-list {
  max-height: inherit;
}

.sw_analytics_main .btn-link {
  color: #8d8d8d;
}

.thumbnail-preview-popup .thumbnail-preview-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.more-option {
  display: none;
}

.thumbnail-preview-popup .splide {
  width: 100%;
}

.my-custom-tooltip {
  inset: auto !important;
}


.trimPostContentNew {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.top_posts_row .splide {
  /* width: auto; */
  width: 100%;
}

.trimPostContent,
.trimPostContent2 {
  word-wrap: break-word;
}

.recent-post li .no-image-dis,
.performing-post .post_img1 .no-image-dis,
.home_recentPost .no-image-dis {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px;
}

.recent-post li .no-image-dis {
  height: 45px;
  border: 1px solid #e9e9e9;
}

.performing-post .post_img1 .no-image-dis,
.home_recentPost .no-image-dis {
  height: 250px;
  border: 1px solid #e9e9e9;
  padding: 10px;

}

.recent-post li .no-image-dis span,
.performing-post .post_img1 .no-image-dis span,
.home_recentPost .no-image-dis span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 6px;
  word-break: break-all;
  white-space: normal;
  color: #000;
  font-weight: 700;
}

.recent-post li .no-image-dis span {
  max-width: 35px;
}

.performing-post .post_img1 .no-image-dis span,
.home_recentPost .no-image-dis span {
  max-width: 250px;
  font-size: 10px;
}

/*  */
div.an-numof-post.true-width-20 {
  width: 20%;
}

div.an-numof-post.true-width-25 {
  width: 25%;
}

.an-arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #02A97E;
}

.an-arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f00;
}

.an-change {
  padding: 4px 5px;
  font-size: 12px;
  border-radius: 2px;
  margin-left: 6px;
}

.an-change em {
  font-style: normal;
  margin-left: 3px;
}

.an-up {
  background: #F9FBFA;
  color: #02A97E;
}

.an-down {
  background: #F8F8F8;
  color: #FF4E5A;
}

.sw-card-custom .count-tag {
  border-radius: 4px;
}

.list_category_icon svg {
  width: 16px;
  height: 16px;
}

.cutsom-tab-viewall .nav-link.active {
  position: unset !important;
  box-shadow: 0px -3px 0 0px #ff4051 inset;
  border-radius: 0 !important;
}

.te-theme .cutsom-tab-viewall .nav-link.active {
  position: unset !important;
  /* box-shadow: 0px -3px 0 0px #4179ff inset; */
  border-radius: 0 !important;
}

.cutsom-tab-viewall .nav-link.active::after {
  display: none;
}

.cutsom-tab-viewall .nav-link .see_more_btn {
  display: none;
}

.cutsom-tab-viewall .nav-link.active .see_more_btn {
  display: block;
  position: absolute;
  right: 15px;
  top: 14px;
}

.custom-tabalign>.tab-content {
  display: flex;
  height: calc(100% - 52px);
}

ul#sentiment-postgraph {
  margin-top: 5px;
}

ul#sentiment-postgraph button {
  font-size: 14px;
}

.custom-tabalign #sentiment-tabpane-statistics,
.custom-tabalign #sentiment-tabpane-posts {
  width: 100%;
}

#sentiment-tabpane-statistics .col-md-9.col-10>div {
  padding-top: 5px;
  padding-bottom: 30px;
}

.custom-splide-item {
  border-radius: 6px;
  padding: 8px;
  border: 1px solid #dce0e3;
}

.custom-splide-item img {
  max-height: 200px;
  border-radius: 6px 6px 0 0;
}

.custom-splide-autsec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-splide-autsec span img {
  width: 32px;
  height: 32px;
  display: block;
  border-radius: 50%;
}

.cs-detail {
  margin-left: 10px;
}

.cs-detail p {
  font-size: 11px;
}

.cs-detail .cs-seprator {
  margin: 0 3px;
  height: 11px;
  overflow: hidden;
  display: inline-block;
  margin-bottom: -1px;
}

.custom-splide-autsec i {
  font-size: 20px;
}

/*  */
#analytics_main .card.card-custom {
  border-top: 3px solid transparent;
}

#analytics_main .card.card-line-1 {
  border-color: #5E78F4;
}

#analytics_main .card.card-line-2 {
  border-color: #ED2FE0;
}

#analytics_main .card.card-line-3 {
  border-color: #FE8900;
}

#analytics_main .card.card-line-4 {
  border-color: #FEC600;
}

#analytics_main .card.card-line-5 {
  border-color: #59D999;
}

#analytics_main .filter_btn_wrap button {
  border-radius: 4px;
}

#reportrange {
  border: 1px solid #eaedf3;
  border-radius: 4px;
  margin-left: 9px;
  height: 35px;
  line-height: 35px;
}

#reportrange+button {
  margin-left: 8px;
  border-radius: 50%;
  background-color: #6DC15A;
}

#reportrange+button:hover {
  opacity: 0.9;
}

#reportrange+button svg path,
#reportrange+button svg path+rect {
  fill: #fff !important;
}

.custom-top-performing .modal-header {
  box-shadow: 0px -1px 0px 0px #E1E3E5 inset;
}

.custom-top-performing .modal-header strong {
  font-size: 18px;
  color: #202223;
  font-weight: 500;
}

.custom-top-performing .btn-connect,
.custom-top-performing .btn-connect:hover {
  background-color: #FF4051;
  font-size: 14px;
  width: 100%;
  color: #fff;
}

.custom-top-performing .btn-connect:hover,
.gi-content a:hover {
  opacity: 0.9;
}

.custom-top-performing .btn-connect img {
  margin-right: 10px;
}

.gi-content,
.gi-overview {
  margin: 15px 0;
}

.gi-content p {
  color: #626B76;
  font-size: 13px;
  font-weight: 500;
}

.gi-content a,
.gi-overview span {
  color: #FF4051;
}

.gi-sentiment p {
  margin: 0;
  color: #626B76;
  display: flex;
  align-items: center;
}

.gi-sentiment p:first-child {
  width: 120px;
}

.gi-sentiment p img {
  margin-right: 10px;
}

.gi-sentiment p .an-change {
  padding: 1px 3px;
}

.gi-sentiment>div:not(:last-child) {
  margin-bottom: 15px;
}

.an-sentimentcalc {
  /* background-color: #D4FFCB; */
  border-radius: 14px;
  padding: 1px 6px;
}

.an-sentimentcalc img {
  margin-bottom: 1px;
}

.an-sentimentcalc span {
  font-size: 11px;
  font-weight: 600;
  color: white;
  /* color: #6DC15A; */
}

.an-positive {
  background-color: #5edb9d;
}

.an-natural {
  background-color: #ffbd5a;
}

.an-negative {
  background-color: #ff6a7a;
}

/* color: #5edb9d; green
color: #ffbd5a; orange
color: #ff6a7a; blue */

.gi-post {
  margin-top: 15px;
  border-top: 1px solid #E1E3E5;
  border-bottom: 1px solid #E1E3E5;
  padding: 15px 0;
}

/*  */

.postOnlyContent {
  padding: 20px 15px;
  height: 200px;
  background-color: #f6f9fb;
  border-radius: 4px;
  overflow: hidden;
}

.postContentMain p {
  color: #6c757d;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.postOnlyContent p {
  color: #637079;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

.postOnlyContent .m_rating+p {
  -webkit-line-clamp: 7;
}

.postOnlyContent .m_rating {
  justify-content: start !important;
  padding-top: 0 !important;
}

/*  */

img[src="https://cloud.tagbox.com/react-app/media/images/no-image.svg"] {
  width: 100%;
  height: 100%;
}

.max-img {
  text-align: center;
}

.max-img img {
  min-height: 375px;
  max-height: 375px;
  object-fit: cover;
  width: 100%;
}

/* BehindBlur-START */
.behind-blur {
  position: absolute;
  top: calc(50% + 20px);
  transform: translateY(-50%);
  z-index: 8;
  text-align: center;
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  left: 0;
  background: rgba(255, 255, 255, 0.78);
}

.after_intro_screen .tab-content {
  min-height: 308px;
}

.behind-blur p {
  font-size: 14px;
  font-weight: 400;
  color: #637079;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: center;
  max-width: 70%;
  margin: 0 auto 20px;
}

.behind-blur button {
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.02em;
  text-align: left;
}

.behind-blur+div {
  filter: blur(4px);
}

/* BehindBlur-END */

/* Upgrade Now Sentiments Start*/
.custom-tabalign .sentimentupgrade {
  background-color: #F2F4F8;
  border-radius: 4px;
  color: #637079;
}

/* Upgrade Now Sentiments End*/
/* AfterConflictWithOldANalytics-START */
#analytics_main .card.card-custom>.card-header,
.sw-card-custom .card-header,
#analytics_main .card.card-custom .nav-tabs-line {
  height: 46px;
}

.card.card-custom #sentiment-tabpane-posts .nav-tabs-line {
  height: 30px;
}

.card.card-custom #sentiment-tabpane-posts .nav-tabs-line button,
.card.card-custom #sentiment-postgraph-tab-negative .nav-tabs-line button {
  padding-top: 0;
}

.TopPerformancePosts .card-title h5+span,
.cutsom-tab-viewall button span,
.sw-card-custom .card-title h5+span {
  max-height: 16px;
}

.TopPerformancePosts .card-title h5+span svg,
.cutsom-tab-viewall button span svg,
.sw-card-custom .card-title h5+span svg {
  max-height: 18px;
  vertical-align: top;
}

.cutsom-tab-viewall button span svg {
  vertical-align: middle;
}

.TopPerformancePosts.card.card-custom>.card-header .card-title .card-label {
  margin-right: 0;
}

#analytics_main .card.card-custom>.card-header .card-title {
  margin: 0;
}

#analytics_main .card.card-custom>.card-header .card-title,
#analytics_main .card.card-custom>.card-header .card-title .card-label,
.sw-card-custom .card-label span:first-child {
  display: flex;
  font-size: 16px;
  align-items: center;
}

.sw-card-custom .card-label,
.sw-card-custom .card-title {
  margin: 0;
  font-size: 16px;
}

button#creators-tab-activeUsers,
button#creators-tab-topInfluencers,
button#postgraph-tab-posttype,
button#postgraph-tab-postnetwork,
button#sentiment-tab-statistics,
button#sentiment-tab-posts {
  font-size: 16px;
  font-weight: 600;
}

.user_profile_items img {
  height: 170px !important;
  object-fit: cover !important;
  object-position: center;
  border-radius: 6px;
  width: 100% !important;
}

.influential_contributors_custom_modal .headerActiveCreator {
  padding: 1rem 0;
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.before-search+div {
  width: 40%;
}

.influential_contributors_custom_modal .influential-data-list,
.paginatioPopup {
  /* padding-left: 1.75rem;
  padding-right: 1.75rem; */
  margin-left: 1.75rem;
  margin-right: 1.75rem;
  padding-right: 0;
}

.influential_contributors_custom_modal .influential-data-list {
  width: calc(100% - 3.5rem);
}

.paginatioPopup {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.paginatioPopup .page-item .page-link {
  outline: none !important;
  cursor: pointer;
  display: flex;
  height: 30px;
  min-width: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  border: 0;
  transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
  color: #545454;
  background-color: transparent;
  background: #f3f6f9;
}

.paginatioPopup .page-item.active .page-link {
  background-color: #0d6efd;
  color: #fff;
}

.paginatioPopup .page-item.disabled {
  pointer-events: none;
  opacity: 0.75;
}

.paginatioPopup .page-item span[aria-hidden="true"] {
  font-size: 16px;
}

.influential-data-list .post-loader {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.influential_contributors_custom_modal .influential-data-list .card {
  margin-bottom: 5px;
}

.analytics_main .nav.nav-tabs.nav-tabs-line button.nav-link {
  padding-top: 10px;
  padding-bottom: 11px;
}

.analytics_main .nav.nav-tabs.nav-tabs-line button#postgraph-tab-posttype,
.analytics_main .nav.nav-tabs.nav-tabs-line button#postgraph-tab-postnetwork {
  padding-bottom: 11px;
  padding-top: 10px;
}

.custom-overview .card-body {
  padding-top: 15px;
  padding-bottom: 15px;
}

.custom-tabalign #sentiment-postgraph-tabpane-positive .card-body,
.custom-tabalign #sentiment-postgraph-tabpane-negative .card-body {
  overflow-y: auto;
  max-height: 383px;
  margin-bottom: 0 !important;
}

.te-theme .custom-top-performing .btn-connect,
.te-theme .custom-top-performing .btn-connect:hover {
  background-color: #2d65eb;
  border-color: #2d65eb;
}

.sw-hover {
  transition: all 0.5s ease-in-out;
}

.sw-hover:hover {
  box-shadow: 0 0 12px #ddd;
  border-color: #f9f9f9;
  cursor: pointer;
}

.profimg-custom>span {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  margin: 0 10px 0px 0;
}

.user_profile_items_title span {
  font-size: 12px;
  font-weight: normal;
  display: block;
  margin-top: 2px;
  opacity: 0.5;
}

.post-icon {
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 4px;
  width: 36px;
}

.svg-icon svg image {
  width: 30px;
}

div#analytics_main .splide__slide {
  width: 226px !important;
}

div#analytics_main .custom-slide-arrow-mod.custom-next {
  right: 0 !important;
}

.TopPerformancePosts .top_posts .custom-splide-item .tb_video__ video {
  object-fit: cover;
  border-radius: 4px;
}

.influential_contributors_custom_modal .allPostData .custom-profile-items .tb_video__ video {
  object-fit: cover;
  border-radius: 4px;
}

#analytics_main #creators-tabpane-activeUsers {
  max-height: 374px;
  overflow-y: auto;
}

#analytics_main .card.card-custom.TopPerformancePosts .cs-detail p:first-child {
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  color: #1A232F;
  margin-bottom: 1px !important;
  overflow: hidden;
  width: 118px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* AfterConflictWithOldANalytics-START */

.nav.nav-tabs.nav-tabs-line #sentiment-postgraph-tab-positive.nav-link,
.nav.nav-tabs.nav-tabs-line #sentiment-postgraph-tab-negative.nav-link {
  padding-bottom: 8px;
}

@media (max-width: 1600px) {
  .top_posts_new {
    flex: 0 0 20% !important;
    max-width: 100% !important;
  }

  .top_posts_row .splide {
    width: 100%;
  }
}

@media (max-width: 1366px) {
  .react-search-feed button {
    padding: 6px 8px 5px 8px;
  }
}

@media(max-width:1280px) {
  #analytics_main .card.card-custom {
    height: 100%;
  }

  button#creators-tab-activeUsers,
  button#creators-tab-topInfluencers,
  button#postgraph-tab-posttype,
  button#postgraph-tab-postnetwork,
  button#sentiment-tab-statistics,
  button#sentiment-tab-posts,
  #analytics_main .card.card-custom>.card-header .card-title,
  #analytics_main .card.card-custom>.card-header .card-title .card-label,
  .sw-card-custom .card-label span:first-child,
  .sw-card-custom .card-label,
  .sw-card-custom .card-title {
    font-size: 14px;
  }

  #analytics_main .card.card-custom>.card-header,
  .sw-card-custom .card-header,
  .card.card-custom .nav-tabs-line {
    height: 36px;
  }
}

@media (max-width: 992px) {
  .sw_analytics_main {
    margin-top: 0px;
  }

  #analytics_main .card.card-custom .display-6 {
    font-size: 1.3rem;
  }
}

@media(max-width:767px) {
  .list-btn {
    width: 100%;
  }

  .sw_analytics_main .table td:first-child,
  .sw_analytics_main .table th:first-child {
    display: none;
  }

  .more-option {
    display: block;
  }

  .option-list {
    position: absolute;
    bottom: -50px;
    z-index: 1;
    background-color: #fff;
    padding: 10px 0px;
    width: 100%;
    box-shadow: 0px 10px 10px #d5d5d5;
    left: 0px;
    display: none;
  }

  .option-list.active {
    display: flex;
  }

  .react-search-feed .css-1r4vtzz {
    padding: 5px 8px 5px 8px;
  }

  div.an-numof-post.true-width-20 {
    width: 33.33%;
  }
}

@media(max-width:630px) {
  .analytics_subheader .flex-sm-nowrap {
    flex-direction: column;
  }

  .analytics_subheader .flex-sm-nowrap .flex-grow-1,
  .analytics_subheader .flex-sm-nowrap .flex-grow-1+div {
    width: 100%;
  }

  .analytics_subheader .flex-sm-nowrap .flex-grow-1+div {
    justify-content: flex-start !important;
    margin-top: 3px;
  }
}

@media(max-width:579px) {
  .analytics_subheader .flex-sm-nowrap .flex-grow-1+div .filter_btn_wrap {
    width: auto;
  }

  div.an-numof-post.true-width-25 {
    width: 50%;
  }
}

@media (max-width: 567px) {

  .recentPost-modal .splide__arrow--next {
    right: 20px !important;
  }

  .recentPost-modal .splide__arrow--prev {
    left: 10px !important;
  }
}

@media(max-width:479px) {
  div.an-numof-post.true-width-20 {
    width: 50%;
  }
}