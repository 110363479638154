
@import '~bootstrap/scss/bootstrap';
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ff4051;
    --bs-btn-border-color: #ff4051;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #e23442;
    --bs-btn-hover-border-color: #e23442;
    --bs-btn-focus-shadow-rgb: 226,52,66;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #e23442;
    --bs-btn-active-border-color: #e23442;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(226,52,66);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #e23442;
    --bs-btn-disabled-border-color: #e23442;
}
.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm, .offcanvas-xl, .offcanvas-xxl {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 340px;
    --bs-offcanvas-height: 30vh;
    --bs-offcanvas-padding-x: 1rem;
    --bs-offcanvas-padding-y: 1rem;
    --bs-offcanvas-color: ;
    --bs-offcanvas-bg: #fff;
    --bs-offcanvas-border-width:0;
    --bs-offcanvas-border-color: none;
    --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.nav {
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: #2b2b2b;
    --bs-nav-link-hover-color: #2b2b2b;
    --bs-nav-link-disabled-color: #6c757d;
}

.nav-tabs {
    --bs-nav-tabs-link-active-color: #2b2b2b;
}

.accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: #f2f6f8;
    --bs-accordion-btn-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27var%28--bs-body-color%29%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
    --bs-accordion-btn-focus-border-color: #86b7fe;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #ff4051;
    --bs-accordion-active-bg: #f2f6f8;
}

.btn {
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.65rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 13px;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #212529;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 3px;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
    display: inline-block;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-link {
    --bs-link-color: #545454;
    --bs-link-hover-color: #ff4051;
    --bs-code-color: #ff4051;
    --bs-highlight-bg: #fff;
}
.btn-outline-secondary {
    --bs-btn-color: #545454;
    --bs-btn-border-color: #e4e6ef;
    --bs-btn-hover-color: #545454;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #e4e6ef;
    --bs-btn-focus-shadow-rgb: 108,117,125;
    --bs-btn-active-color: #545454;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #e4e6ef;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #545454;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #e4e6ef;
    --bs-gradient: none;
}

.btn-secondary {
    --bs-btn-color: #3f4254;
    --bs-btn-bg: #e4e6ef;
    --bs-btn-border-color: #e4e6ef;
    --bs-btn-hover-color: #3f4254;
    --bs-btn-hover-bg: #d7d9e3;
    --bs-btn-hover-border-color: #d7d9e3;
    --bs-btn-focus-shadow-rgb: 130,138,145;
    --bs-btn-active-color: #3f4254;
    --bs-btn-active-bg: #d7d9e3;
    --bs-btn-active-border-color: #d7d9e3;
    --bs-btn-active-shadow: inset 0 0 0 rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #aaa;
    --bs-btn-disabled-bg: #e4e6ef;
    --bs-btn-disabled-border-color: #e4e6ef;
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6ac259;
    --bs-btn-border-color: #6ac259;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #60b350;
    --bs-btn-hover-border-color: #60b350;
    --bs-btn-focus-shadow-rgb: 60,153,110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #60b350;
    --bs-btn-active-border-color: #60b350;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6ac259;
    --bs-btn-disabled-border-color: #6ac259;
}

.btn-danger {
    --bs-btn-color: #fff;
    --bs-btn-bg: #df4708;
    --bs-btn-border-color: #df4708;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #d04105;
    --bs-btn-hover-border-color: #d04105;
    --bs-btn-focus-shadow-rgb: 225,83,97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #d04105;
    --bs-btn-active-border-color: #d04105;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #df4708;
    --bs-btn-disabled-border-color: #df4708;
}

.btn-info {
    --bs-btn-color: #fff;
    --bs-btn-bg: #6d72fe;
    --bs-btn-border-color: #6d72fe;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6368eb;
    --bs-btn-hover-border-color: #6368eb;
    --bs-btn-focus-shadow-rgb: 11,172,204;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6368eb;
    --bs-btn-active-border-color: #6368eb;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6d72fe;
    --bs-btn-disabled-border-color: #6d72fe;
}

.btn-dark {
    --bs-btn-color: #fff;
    --bs-btn-bg: #1a2029;
    --bs-btn-border-color: #1a2029;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #000000;
    --bs-btn-hover-border-color: #000000;
    --bs-btn-focus-shadow-rgb: 66,70,73;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #000000;
    --bs-btn-active-border-color: #000000;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #434a56;
    --bs-btn-disabled-border-color: #434a56;
}

.spinner-border-sm {
    --bs-spinner-width: 20px;
    --bs-spinner-height: 20px;
    --bs-spinner-border-width: 2px;
}
.tooltip {
    --bs-tooltip-color: #fff;
    --bs-tooltip-bg: #000;
}
.tooltip.light {
    --bs-tooltip-color: #000;
    --bs-tooltip-bg: #fff;
}

.card {
    --bs-card-spacer-y: 2rem;
    --bs-card-spacer-x: 2.5rem;
}

.accordion {
    --bs-accordion-active-bg: #f2f6f8;
    --bs-accordion-btn-active-icon: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%232b2b2b%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
}
@import './price.scss';
@import './sweetalert.scss'