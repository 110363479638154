.themeModrnWatrFall .flatCard .post {
  box-shadow: 0 1px 4px rgb(0 0 0/10%);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
  border: 0;
  border-radius: 12px;
  overflow: hidden;
}
.themeModrnWatrFall .flatCard .postContent .image img.img-responsive {
  width: 100%;
}
.themeModrnWatrFall .flatCard .postContent .image img.img-responsive {
  margin: 0 auto;
  min-height: 40px;
}
.themeModrnWatrFall .flatCard .postContentCard {
  padding: 10px;
}
.themeModrnWatrFall .authorDetails img {
  position: static;
  float: none;
  clear: both;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.themeModrnWatrFall .authorDetails .authrHandle,
.themeModrnWatrFall .authorDetails .timePost {
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  margin-right: 0;
  font-size: 10px;
  font-weight: 500;
}
.themeModrnWatrFall .flatCard h4 .postNetwork i {
  font-size: 30px !important;
}
.themeModrnWatrFall .authorDetails .postAuthorName {
  margin: 0 auto;
}
.authorDetails .postAuthorName {
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.themeModrnWatrFall .authorDetails .authrHandleTime * {
  vertical-align: middle;
  display: inline-block;
  line-height: inherit;
  float: none;
  clear: both;
}
.themeModrnWatrFall .flatCard h4 .postNetwork {
  width: auto;
  height: auto;
  margin: 8px auto;
  float: none;
}
.themeModrnWatrFall .postedTxt {
  width: 100%;
  display: block;
  word-wrap: break-word;
  margin: 10px 0;
}
.themeModrnWatrFall .flatCard .postedTxt h1,
.themeModrnWatrFall .flatCard .postedTxt p {
  margin: 0;
}
.themeModrnWatrFall .authrHandleTime .timePost {
  font: 600 10px/18px "open sans";
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  margin-right: 0;
}
.themeModrnWatrFall .authorDetails .authrHandle {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.themeStart56 .flatCard .post2 {
  background-color: #ffb800 !important;
}
.themeStart56 .flatCard .post {
  position: relative;
  border: none !important;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 2;
  box-shadow: 0 1px 2px rgb(0 0 0/6%), 0 2px 4px rgb(0 0 0/4%),
    0 5px 14px rgb(0 0 0/2%);
}
.postContent div.image,
.themeStart40 .postContent div.image {
  position: relative;
}
.flatCard .postContent .image img.img-responsive {
  width: 100%;
}
.flatCard .postContent .image img.img-responsive {
  margin: 0 auto;
  min-height: 40px;
}
.themeStart56 .flatCard .postContentCard {
  padding: 15px;
}
.postedTxt {
  width: 100%;
  display: block;
  word-wrap: break-word;
  margin: 10px 0;
}
.flatCard .postedTxt h1,
.flatCard .postedTxt p {
  margin: 0;
}
.authorDetails img {
  height: 41px;
  width: 41px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  float: left;
  margin-right: 10px;
}
.sepratedot {
  width: 4px;
  height: 4px;
  background-color: #fff;
  margin: 3px;
  border-radius: 50%;
  display: inline-block;
}
.authorDetails .postAuthorName {
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.authorDetails .authrHandle {
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.authorDetails .authrHandle,
.authorDetails .timePost {
  font-size: 10px;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  margin-right: 0;
  white-space: nowrap;
}
.cardPreviewStyle .themeStart57 .authrHandleTime {
  display: flex;
  margin-top: 6px;
}
.flatCard h4 .postNetwork {
  margin-top: 8px;
  float: right;
  margin-left: 20px;
  margin-bottom: 10px;
}
.themeStart56 .postContentCard h4 {
  display: table;
  width: 100%;
  margin-top: 15px;
}
.flatCard .postedTxt h1,
.flatCard .postedTxt p {
  margin: 0;
}
.themeStart57 .post {
  padding: 15px 15px 5px;
  border-radius: 12px;
}
.cardPost.themeStart57 {
  background-color: #f2f6f8;
  padding: 30px 15px;
}
.flatCard .postContentCard {
  padding: 10px;
}
.themeStart57 .flatCard .postContentCard {
  min-height: 60px;
}
.tb_wall_list_wrap___ {
  float: left;
  width: 20%;
}
.tb_wall_card_in__ {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  overflow: hidden;
}
.card_img__01 {
  display: table;
  width: 100%;
}
.card_img_02 {
  max-width: 100%;
  display: block;
  width: 100%;
}
.card_video__01 {
  display: table;
  width: 100%;
}
.tb_post_vid_005 {
  width: 100%;
  border: none;
  height: 100%;
  display: block;
  background-color: #000;
}
.tb_wall_card_wrap__ {
  display: block;
  padding: 10px;
}
.tb_wall_card_content___ {
  padding: 15px;
}
.tb_wall_card_conent__001 {
  position: relative;
  display: block;
  width: 100%;
}
.tb_post_author_001_ {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 10px;
}
.tb_post_userinfo__ {
  padding-bottom: 10px;
}
.tb_post_username__ {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 4px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb_post_002 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.tb_post_user_003 {
  font-size: 11px;
  line-height: 13px;
  color: #2b2b2b;
  font-weight: 500;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb_seprate_dot__ {
  width: 4px;
  height: 4px;
  background-color: #2b2b2b;
  border-radius: 50%;
  margin: 0 5px;
}
.tb_post_time_001 {
  font-size: 11px;
  line-height: 13px;
  color: #2b2b2b;
  font-weight: 500;
  max-width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tb_card_social_001 {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}
.tb_card_social_001 .fab {
  font-size: 26px;
  color: #2b2b2b;
}
.tb_post_content__05 {
  margin-bottom: 10px;
}
.tb_post_cont_in__001 {
  text-align: center;
  font-size: 14px;
  display: block;
}
.tb_card_social_bottom {
  border-bottom: 60px solid #1f1b1b;
  border-left: 60px solid transparent;
  position: absolute;
  left: auto;
  right: -15px;
  top: auto;
  bottom: -30px;
  margin: 0;
  height: 20px;
  width: 20px;
}
.tb_card_social_bottom .fab {
  position: absolute;
  left: -23px;
  right: 0;
  z-index: 9;
  color: #fff;
  top: 30px;
  display: block;
  font-size: 20px;
}
.themeStart46 .tb_wall_card_in__ {
  box-shadow: 5px 5px 18px -6px rgb(0 0 0/30%);
  border: 0;
  border-radius: 10px;
}
.themeStart46 .tb_post_author__ {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.themeStart46 .tb_post_username__ {
  text-align: left;
}
.themeStart46 .tb_post_userinfo__ {
  padding-bottom: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.themeStart46 .tb_post_002 {
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}
.themeStart46 .tb_post_cont_in__001 {
  text-align: left;
}
.themeStart46 .tb_post_author_001_ {
  height: 48px;
  width: 48px;
  margin-right: 15px;
  margin-bottom: 0;
}
.tb_con_theme {
  width: 100%;
  padding: 0 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}
.tb_con_theme_wrapper {
  position: relative;
  height: 100%;
}
.tb_con_container {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.tb_con_wrap {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.tb_author_profile {
  height: 60px;
  width: 60px;
  background-position: center;
  background-size: cover !important;
  border-radius: 8px;
  border: 1px solid #707070;
  margin-right: 15px;
  background-color: #707070;
  font-size: 30px;
  text-align: center;
  font-weight: 700;
  color: #2b2b2b;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tb_con_author_name {
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
}
.tb_con_post_time {
  color: #9f9f9f;
  font-size: 14px;
  opacity: 0.5;
}
.tb_con_post_social {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.tb_con_post_social .tb_icon,
.tb_con_post_social .tb_icon2 {
  font-size: 24px;
}
.tb_svg_icon img {
  min-width: 24px;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.tb_con_post_social {
  padding-left: 20px;
}
.tb_con_post_content {
  display: flex;
  overflow-x: hidden;
}
.tb_con_post_content00 {
  overflow-x: hidden;
  background-color: #fff;
  padding: 20px 60px 20px 20px;
  display: inline-block;
  margin-left: 75px;
  max-width: 75%;
  border-top-left-radius: 0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0/3%), 0 2px 4px rgb(0 0 0/2%),
    0 5px 14px rgb(0 0 0/1%);
  box-shadow: 0 1px 2px rgb(0 0 0/3%), 0 2px 4px rgb(0 0 0/2%),
    0 5px 14px rgb(0 0 0/1%);
}
.tb_con_post_cont01 {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
}
.tb_con_post_media {
  max-width: 350px;
  border-radius: 10px;
  overflow: hidden;
}
.tb_con_post_cont002 {
  font-size: 18px;
  color: #232323;
  margin-bottom: 15px;
  line-height: 160%;
  word-break: break-word;
}
.tb_con_post_media img,
.tb_con_post_media video,
.tb_con_post_media iframe {
  max-width: 100%;
  display: block;
  height: auto;
}
.tb_con_post_media video,
.tb_con_post_media iframe {
  background-color: #000;
  width: 100%;
  min-height: 300px;
  height: 100%;
}
.tb_con_author {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 12px;
}
.tb_chat_box_right .tb_author_profile {
  margin-right: 0;
  margin-left: 15px;
}
.tb_chat_box_right .tb_con_author {
  justify-content: flex-end;
}
.tb_chat_box_right .tb_con_post_content00 {
  margin-right: 75px;
  margin-left: auto;
}
.tb_con_title {
  font-weight: 700;
}
.tb_chat_box_right .tb_con_post_content00 {
  border-top-left-radius: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}
.animate-typing-col {
  transition: all 300ms;
}
.animate-typing-col .tb_dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 3px;
  border-radius: 50%;
  background: #333;
  animation: wave 1.3s linear infinite;
}
.animate-typing-col .tb_dot:nth-child(2) {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.animate-typing-col .tb_dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
@-webkit-keyframes wave {
  0%,
  100%,
  60% {
    -webkit-transform: initial;
    transform: initial;
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
@keyframes wave {
  0%,
  100%,
  60% {
    -webkit-transform: initial;
    transform: initial;
  }
  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
.tb_con_post_content .tb_con_post_cont01 {
  display: none;
}
.tb_con_wrap.tb_left_anim .animate-typing-col,
.tb_con_wrap.tb_right_anim .animate-typing-col {
  display: none;
}
.tb_con_wrap.tb_left_anim .tb_con_post_cont01,
.tb_con_wrap.tb_right_anim .tb_con_post_cont01 {
  display: flex;
}
.tb_con_wrap.tb_left_anim .tb_con_post_content00 {
  -webkit-animation: scale-in-tl 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-tl 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: relative;
  min-height: 58px;
  margin-bottom: 5px;
}
@-webkit-keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
.tb_con_wrap.tb_chat_box_right.tb_right_anim .tb_con_post_content00 {
  -webkit-animation: scale-in-tr 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-tr 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: relative;
  min-height: 58px;
}
@-webkit-keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
.tb_con_wrap.tb_right_anim .tb_author_profile,
.tb_con_wrap.tb_left_anim .tb_author_profile {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.tb_con_container {
  padding-left: 10px;
  padding-right: 10px;
}
.tb_con_theme {
  padding: 0 10px;
}
.tb_author_profile {
  height: 45px;
  width: 45px;
}
.tb_con_post_social {
  padding-left: 15px;
}
.tb_con_post_content00 {
  padding: 15px 48px 15px 15px;
  display: inline-block;
  margin-left: 60px;
  max-width: calc(100% - 60px);
}
.tb_con_author_name {
  font-size: 16px;
  text-transform: capitalize;
}
.tb_con_post_time {
  font-size: 12px;
}
.tb_chat_box_right .tb_con_post_content00 {
  margin-right: 60px;
}
.tb_con_post_cont002 {
  font-size: 14px;
}
.tb_con_post_media {
  max-width: 250px;
  border-radius: 5px;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.fa-flickr {
  color: #006add;
}
.fa-twitter {
  color: #29a9e1;
}
.fa-instagram {
  color: #d45662;
}
.fa-facebook,
.fa-facebook-f {
  color: #1877f2;
}
.fa-google {
  color: #4081ed;
}
.fa-rss {
  color: #f78422;
}
.fa-youtube {
  color: red;
}
.fa-vimeo,
.fa-vimeo-square {
  color: #1eb8eb;
}
.fa-workplace {
  color: #4767ab;
}
.fa-tumblr {
  color: #36465d;
}
.fa-yelp {
  color: #be362e;
}
.fa-yammer {
  color: #0078d4;
}
.fa-slack {
  color: #510d4e;
}
.fa-airbnb {
  color: #ff7977;
}
.fa-giphy {
  color: #000;
}
.fa-soundcloud {
  color: #fc5502;
}
.tb_scroll_to_bottom button {
  border-radius: 0;
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: rgba(255, 64, 80, 0.8);
  font-family: FontAwesome;
  box-shadow: 0 0 0 0 #ff4050;
  animation: pulse-black 2s infinite;
  display: inline-block;
}
.tb_scroll_to_bottom button:hover {
  background-color: rgba(255, 64, 80, 0.8);
}
.tb_scroll_to_bottom button:before {
  content: "\f063";
}
@keyframes pulse-black {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(255, 64, 80, 0.7);
  }
  70% {
    transform: scale(1);
  }
  99% {
    box-shadow: 0 0 0 10px transparent;
  }
  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 transparent;
  }
}
