.assets_modal_main{
    position: fixed;
    top: 54px;
    width: 100%;
    left: 0px;
    height: 100%;
    z-index: 99;
    padding-left: 200px;
}
.aside-minimize .assets_modal_main{
    padding-left: 60px;
}
.assets_modal_main .assets_modal_inner_1{
    background-color: #fff;
    height:100%;
}
.assets_modal_main .table th, .assets_modal_main .table td{
    border: 0px;
}
.assets_modal_main .symbol-34 {
    width: 34px !important;
    height: 34px !important;
    background-size: cover;
}
.assets_modal_main .symbol > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    flex-shrink: 0;
    display: inline-block;
    border-radius: 4px;
}
.assets_modal_main .tags-group .badge-pointer{
    padding:1px;
}
.assets_modal_main .tags-group .badge-pointer .badge{
    position: relative;
    text-align: center;
    display: inline-block;
    list-style: none;
    border: 1px solid transparent;
    padding: 5px 10px;
    border-radius: 16px;
    font-size: 12px;
    transition: all .3s;
    text-transform: capitalize;
    box-shadow: 0px 0px 10px #d3d3d3cc;
    font-weight: normal;
    background-color: #fff;
}
.assets_modal_main .tags-group .block-center input{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}
.assets_modal_main .tags-group .badge-pointer.recommend-tag .badge{
    background: #f1f6f8;
    color: #000;
    border-color: transparent;
}
.sw_theme .assets_modal_main .tags-group .badge-pointer.recommend-tag{
    background: linear-gradient(to right, #f5a267, #d43e8e, #5364d3);
    padding: 1px;
    border-radius: 30px;
}
.sw_theme .assets_modal_main .tags-group .badge-pointer.recommend-tag .badge{
    background:#fff;
}
.ass_modal_actions_box{
    height: calc(100vh - 95px);
    overflow: auto;
}
.assets_modal_inner .assets_modal_arrow{
    border: 0px;
    background-color: #fff;
    height: 44px;
    width: 44px;
    border-radius: 50px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 20px;
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.assets_modal_arrow_left{
    left: 20px;
}
.assets_modal_arrow_right{
    right: 20px;
}
.assets_modal_inner .assets_modal_arrow.disable{
    opacity: 0.5;
    cursor: default;
}
.assets_modal_inner .assets_modal_img_outer{
    width: calc(100% - 150px);
    height: calc(100vh - 200px);
    position: relative;
}
.assets_modal_inner .assets_modal_img_outer .assets_modal_img_wrapper img{
    width: 100%;
    max-height: calc(100vh - 200px);
    object-fit: contain;
}
.assets_modal_inner .assets_modal_img_outer .assets_modal_img_wrapper video{
    width: 100%;
    max-height: calc(100vh - 200px);
}
.assets_modal_inner .assets_modal_img_outer .assets_modal_img_wrapper iframe{
    width: 100% !important;
    height: calc(100vh - 200px);
}

.assets_modal_img_wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.assets_modal_media__{
    max-width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
}


.assets_modal_img_wrapper .fb_iframe_widget span {
    display: flex;
    position: initial;
    text-align: justify;
    height: 100% !important;
    width: 100% !important;
    justify-content: center;
    align-items: center;
}

.assets_modal_img_wrapper .tb_video__,
.assets_modal_img_wrapper .fb_iframe_widget,
.assets_modal_img_wrapper .fb-video{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fb_iframe_widget iframe{
    width: auto !important;
    margin: 0 auto;
}
.assets_modal_inner .assets_modal_img_wrapper .text_post_modal .m_rating span{
    font-size: 22px;
}
.assets_modal_inner .assets_modal_img_wrapper .text_post_modal .fs-16{
    font-size: 16px;
}
.text_post_modal_main{
    height: calc(100vh - 200px);
}
.assets_modal_img_outer .sk_rect.sk_img_thumb{
    height: calc(100vh - 200px);
}
.assets_modal_img_outer .sk_rect:before{
    background-size:100%;
}
.ass_modal_actions_box .assets_btn_wrapper .btn,
.ass_modal_actions_box .assets_btn_wrapper .btn:hover{
    background-color: #ecf1f4;
}
.ass_modal_actions_box .assets_btn_wrapper .btn.disabled{
    opacity: 1;
}
.ass_modal_actions_box .m_grid_check.m_active,
.ass_modal_actions_box .m_grid_check.m_active:hover,
.ass_modal_actions_box .m_grid_check:hover{
    background-color: #6ac259 !important;
    color: #fff !important;
}
.ass_modal_actions_box .m_grid_non_check.m_active,
.ass_modal_actions_box .m_grid_non_check.m_active:hover,
.ass_modal_actions_box .m_grid_non_check:hover{
    background-color: #db4b20 !important;
    color: #fff !important;
    border-color: #db4b20;
}
.me-8 {
    margin-right: 2rem !important;
}

@media screen and (max-width: 1366px) {
    .assets_modal_main{
        top: 48px;
        padding-left: 180px;
    }
}
@media screen and (max-width: 992px) {
    .assets_modal_inner .assets_modal_img_outer{
        width: 100%;
        height: auto;
        position: relative;
    }
    .assets_modal_img_wrapper,
    .fb_iframe_widget iframe{
        position: relative !important;
        width: 100% !important;
    }
    .ass_modal_actions_box {
        height: calc(100vh - 106px);
    }

    .assets_btn_wrapper{
        margin-bottom: 30px;
    }

    .assets_modal_inner .assets_modal_arrow {
        background-color: rgb(255 255 255 / 42%);
    }

    .assets_modal_arrow i{
        color: #000;
    }
    .assets_modal_main{
        padding-left: 0px;
        top: 55px;
    }
    .assets_modal_main .assets_modal_inner,
    .assets_modal_main .full_modal_sidebar{
        width: 100%;
    }
    .assets_modal_main .full_modal_sidebar_inner{
        height: auto;
    }
    .assets_modal_inner .audioPlayer{
        position: relative;
        transform: inherit;
        left: 0px;
        top: 0px;
        margin:0 auto;
    }  
    .assets_modal_main .ass_modal_actions .options{
        position: absolute;
        right: 20px;
        display: flex;
        flex-direction: column;
        top: 20px;
        background-color: #fff;
        width: 140px;
        z-index: 9;
        padding: 10px;
        box-shadow: 0px 0px 10px #ccc;
        border-radius: 5px;
    }
    .assets_modal_main .ass_modal_actions .options button+button{
        margin-top: 7px;
    }
}
@media screen and (max-width: 767px) {
    
    .text_post_modal_main{
        height: auto;
        padding: 40%;
    }
}