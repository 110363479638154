/* Review Banner Start */
.tb_rv_banner_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.tb_rv_head_text,
.tb_rv_rating_wrap {
  display: flex;
  align-items: center;
}

.tb_rv_head_text .tb_rv_head_social_text {
  margin-right: 5px;
}

.bannerPreviewCard {
  border-radius: 4px;
}

.tb_rv_banner_btn_wrap .commanBtn {
  border-color: transparent !important;
}

.tb_rv_review_count {
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}

.tb_rv_rview_star {
  margin-right: 10px;
}

.tb_rv_rating_wrap .tb_rv_review_count {
  display: flex;
}

@media(max-width:1366px) {
  .minicolors-theme-bootstrap .minicolors-input {
    padding-top: 9px;
    padding-bottom: 10px;
  }
}

@media(max-width:474px) {
  .tb_rv_banner_wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .tb_rv_banner_btn_wrap {
    margin-top: 7px;
  }
}

/* Review Banner End */








/* NewBanner-START */
.chooseBanner {
  position: relative;
}

.chooseBanner .frontBanner img,
div.customChoosebanner {
  border: 1px solid #ddd;
  border-radius: 8px;
}

.chooseBanner.bnr-selected .frontBanner img {
  border: 0;
  border-radius: 8px;
}

.chooseBanner.bnr:hover::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  border-radius: 8px;
}

.chooseBanner .bnr-action {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%) translateY(-50%);
  display: none;
  align-items: center;
  justify-content: center;
}

.chooseBanner:hover .bnr-action {
  display: flex;
  z-index: 2;
}

.chooseBanner .bnr-action .btn {
  margin: 0 5px;
  width: 110px;
  transition: all 0.3s ease-in-out;
}

.chooseBanner .bnr-action .btn:hover {
  opacity: 0.9;
}

.chooseBanner .bnr-action .btn-edit {
  background-color: #fff;
  color: #262b3f;
}

.chooseBanner .bnr-action .btn-apply {
  background-color: #ff4051;
  color: #fff;
}

.customChoosebanner {
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  padding: 30px 15px;
}

.customChoosebanner:hover {
  cursor: pointer;
}

.customChoosebanner span {
  display: inline-block;
  margin-top: 10px;
  font-weight: 500;
}

.widgetBannerToggle {
  margin-bottom: 8px;
}

.widgetBannerToggle label {
  color: #2b2b2b;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.widgetBannerToggle label>span {
  background: #ff4051;
  color: #fff;
  font-weight: 400;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 7px 0;
  text-transform: uppercase;
  vertical-align: middle;
}

.te-theme .widgetBannerToggle label>span {
  background: #4179ff;
}

.widgetBannerToggle .btnToggle>span {
  font-weight: 500;
  opacity: 0.65;
  margin-right: 5px;
}

.bnr-7-preview {
  padding: 20px 15px;
  border-radius: 6px;
  margin: 15px 0;
  background-color: #fff;
  border: 1px solid #e3e6eb;
}

.bnr-7-preview .bnr-7-pleft .bnr-profile {
  margin-left: 15px;
}

.bnr-7-preview .bnr-7-pleft .bnr-profile h6 {
  margin-bottom: 5px;
  line-height: normal;
  /* font-family: "Open Sans"; */
}

.bnr-7-preview .bnr-7-pleft .bnr-networks {
  margin-top: 15px;
}

.bnr-7-preview .bnr-7-pleft .bnr-networks button {
  min-width: 60px;
  font-size: 13px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #959595;
}

.bnr-7-preview .bnr-7-pleft .bnr-networks button.active {
  background-color: #000;
  color: #fff;
}

.bnr-7-preview .bnr-7-pleft .bnr-networks button i {
  /* filter: grayscale(1);
  opacity: 0.8; */
  margin-right: 3px;
  vertical-align: middle;
}

.bnr-7-preview .bnr-7-pleft .bnr-networks button span {
  /* color: #959595; */
  vertical-align: middle;
}

.bnr-7-preview .bnr-7-pleft .bnr-networks button.active span {
  color: #fff;
}

.bnr-7-preview .bnr-7-pright button {
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  padding: 7px 18px;
  color: #fff;
  background-color: #0095f6;
  display: flex;
  align-items: center;
}

.bnr-7-preview .bnr-7-pright button i {
  margin-right: 5px;
  color: #fff;
}

.bnr-7-preview .bnr-7-pright button i,
.bnr-7-preview .bnr-7-pright button span {
  vertical-align: middle;
}

.bnr-7-preview .bnr-7-pright button span {
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customcolPicker {
  color: #3f4254;
  border-radius: 4px;
  padding: 6px 8px;
  border: 1px solid #e4e6ef;
  display: flex;
  align-items: center;
}

.customcolPicker>div {
  padding: 0;
  height: 24px;
}

.customcolPicker>div>div {
  padding: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}

.customcolPicker>span {
  margin-left: 10px;
}

.customSelectNet {
  border-bottom: 1px solid #e7e9ef;
  margin: 15px 0;
}

.customImageuploader {
  color: #3f4254;
  padding: 3px 8px;
  border: 1px solid #e4e6ef;
  border-radius: 4px;
}

.customImageuploader .btn,
.customImageuploader .btn:hover {
  margin: 0;
  padding: 0;
  border-radius: 0;
  background-color: transparent !important;
}

.customImageuploader .btn:hover span {
  text-decoration: underline;
}

.customImageuploader .btn span {
  color: #0095f6;
}

.customImageuploader>span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customImageuploader .custImgname {
  line-height: normal;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  width: 130px;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.uploaderimgThumb {
  border-radius: 50%;
  width: 26px;
  height: 26px !important;
}

.uploaderimgThumb .maintainPopUp {
  margin: 0;
  display: inline-block;
  margin-left: 10px;
}

.preview-profImg {
  position: relative;
}

.preview-profImg>span,
.preview-profImg>img {
  height: 44px !important;
  width: 44px !important;
  border-radius: 50%;
  display: block;
  margin: 5px;
  position: relative;
  z-index: 2;
  background-size: contain;
}

.preview-profImg::before,
.preview-profImg::after {
  border-radius: 50%;
  content: "";
  position: absolute;
}

.preview-profImg::before {
  background: #fff;
  height: calc(100% - 4px);
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  z-index: 2;
}

.preview-profImg::after {
  background: #ec8a46;
  background: linear-gradient(90deg, #ec8a46, 50%, #469aea);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#EC8A46", endColorstr="#469AEA", GradientType=1);
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.bnr-selected {
  border-color: #eee;
  box-shadow: 0 0 15px #eeee;
  border: 1px solid;
  border-radius: 8px;
}

.bnr-selected::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url(https://cloud.tagbox.com/react-app/media/images/bnr-selected.png);
  z-index: 10;
  width: 34px;
  height: 34px;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0 8px 0 0;
}

.chooseBanner.customChoosebanner>img {
  object-fit: contain;
  max-height: 103px;
  object-position: top;
}

.customSelectNet .selectpicker__control.selectpicker__control--menu-is-open,
.customSelectNet .selectpicker__control.selectpicker__control--is-focused,
.customSelectNet .selectpicker__control.css-13cymwt-control {
  min-width: 140px;
  max-width: 140px;
}

/*Social Banner Perview End */