.Toastify__toast-icon{
	display: none;
}
.Toastify__close-button {
    width: 18px;
    height: 30px;
	font-size: 0;
	color: #2b2b2b;
	-webkit-text-shadow: none;
	text-shadow: none;
	opacity: .8;
	line-height: 1;
	float: right;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: TaggboxSocialFonts;
	background-color: transparent;
	border: none
}

.Toastify__close-button svg path {
    fill: #2b2b2b;
}

.Toastify__close-button > svg {
    height: 18px;
    width: 18px;
}
.Toastify__toast-container {
	position: fixed;
	z-index: 999999;
	pointer-events: none
}

.Toastify__toast-container>.Toastify__toast--success {
	background-image: url('https://cloud.tagbox.com/app/analytics/toastr-icons/success.svg')!important;
	border-color: #6ac259
}

.Toastify__toast-container>.Toastify__toast--error {
	background-image: url('https://cloud.tagbox.com/app/analytics/toastr-icons/error.svg')!important;
	border-color: #db4b20
}

.Toastify__toast-container>.Toastify__toast--warning {
	background-image: url('https://cloud.tagbox.com/app/analytics/toastr-icons/warning.svg')!important;
	border-color: #ff9300
}

.Toastify__toast-container>.Toastify__toast--info {
	background-image: url('https://cloud.tagbox.com/app/analytics/toastr-icons/info.svg')!important;
	border-color: #208cdb
}

.Toastify__toast-container>.Toastify__toast--default {
	background-image: url('https://cloud.tagbox.com/app/analytics/toastr-icons/activity.gif')!important
}
.toast-progress {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 4px;
	background-color: #000;
	opacity: .4
}

.Toastify__toast {
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
    min-height: 54px;
	padding: 12px 12px 12px 50px;
	border-radius: 3px 3px 3px 3px;
	background-position: 15px center;
	background-repeat: no-repeat;
	color: #2b2b2b;
	opacity: 1;
	border: 0;
	border-left: 3px solid #2b2b2b;
	background-color: #fff;
	background-size: 26px;
	-webkit-box-shadow: 0 0 50px 0 rgba(82, 63, 105, .15);
	box-shadow: 0 0 50px 0 rgba(82, 63, 105, .15);
	border-radius: .25rem
}
.Toastify__toast-body {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.Toastify__toast-container {
    z-index: 9999;
    padding: 0;
    width: 320px;
}

@media only screen and (max-width: 480px){
	.Toastify__toast-container{
		left: inherit;
	}
}