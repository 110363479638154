
body{
.swal2-popup.swal2-toast {
	box-sizing: border-box;
	grid-column: 1/4!important;
	grid-row: 1/4!important;
	grid-template-columns: 1fr 99fr 1fr;
	padding: 1em;
	overflow-y: hidden;
	background: #fff;
	box-shadow: 0 0 1px rgba(0, 0, 0, .075), 0 1px 2px rgba(0, 0, 0, .075), 1px 2px 4px rgba(0, 0, 0, .075), 1px 3px 8px rgba(0, 0, 0, .075), 2px 4px 16px rgba(0, 0, 0, .075);
	pointer-events: all
}

.swal2-popup.swal2-toast>* {
	grid-column: 2
}

.swal2-popup.swal2-toast .swal2-title {
	margin: .5em 1em;
	padding: 0;
	font-size: 1em;
	text-align: initial
}

.swal2-popup.swal2-toast .swal2-loading {
	justify-content: center
}

.swal2-popup.swal2-toast .swal2-input {
	height: 2em;
	margin: .5em;
	font-size: 1em
}

.swal2-popup.swal2-toast .swal2-validation-message {
	font-size: 1em
}

.swal2-popup.swal2-toast .swal2-footer {
	margin: .5em 0 0;
	padding: .5em 0 0;
	font-size: .8em
}

.swal2-popup.swal2-toast .swal2-close {
	grid-column: 3/3;
	grid-row: 1/99;
	align-self: center;
	width: .8em;
	height: .8em;
	margin: 0;
	font-size: 2em
}

.swal2-popup.swal2-toast .swal2-html-container {
	margin: .5em 1em;
	padding: 0;
	font-size: 1em;
	text-align: initial
}

.swal2-popup.swal2-toast .swal2-html-container:empty {
	padding: 0
}

.swal2-popup.swal2-toast .swal2-loader {
	grid-column: 1;
	grid-row: 1/99;
	align-self: center;
	width: 2em;
	height: 2em;
	margin: .25em
}

.swal2-popup.swal2-toast .swal2-icon {
	grid-column: 1;
	grid-row: 1/99;
	align-self: center;
	width: 2em;
	min-width: 2em;
	height: 2em;
	margin: 0 .5em 0 0
}

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
	display: flex;
	align-items: center;
	font-size: 1.8em;
	font-weight: 700
}

.swal2-popup.swal2-toast .swal2-actions {
	justify-content: flex-start;
	height: auto;
	margin: 0;
	margin-top: .5em;
	padding: 0 .5em
}

.swal2-popup.swal2-toast .swal2-styled {
	margin: .25em .5em;
	padding: .4em .6em;
	font-size: 1em
}

.swal2-popup.swal2-toast .swal2-success {
	border-color: #a5dc86
}

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
	position: absolute;
	width: 1.6em;
	height: 3em;
	transform: rotate(45deg);
	border-radius: 50%
}

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
	top: -.8em;
	left: -.5em;
	transform: rotate(-45deg);
	transform-origin: 2em 2em;
	border-radius: 4em 0 0 4em
}

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
	top: -.25em;
	left: .9375em;
	transform-origin: 0 1.5em;
	border-radius: 0 4em 4em 0
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
	width: 2em;
	height: 2em
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
	top: 0;
	left: .4375em;
	width: .4375em;
	height: 2.6875em
}

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
	height: .3125em
}

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
	top: 1.125em;
	left: .1875em;
	width: .75em
}

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
	top: .9375em;
	right: .1875em;
	width: 1.375em
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
	-webkit-animation: swal2-toast-animate-success-line-tip .75s;
	animation: swal2-toast-animate-success-line-tip .75s
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
	-webkit-animation: swal2-toast-animate-success-line-long .75s;
	animation: swal2-toast-animate-success-line-long .75s
}

.swal2-popup.swal2-toast.swal2-show {
	-webkit-animation: swal2-toast-show .5s;
	animation: swal2-toast-show .5s
}

.swal2-popup.swal2-toast.swal2-hide {
	-webkit-animation: swal2-toast-hide .1s forwards;
	animation: swal2-toast-hide .1s forwards
}

.swal2-container {
	display: grid;
	position: fixed;
	z-index: 1060;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-sizing: border-box;
	grid-template-areas: "top-start     top            top-end" "center-start  center         center-end" "bottom-start  bottom-center  bottom-end";
	grid-template-rows: minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto) minmax(-webkit-min-content, auto);
	grid-template-rows: minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, auto);
	height: 100%;
	padding: .625em;
	overflow-x: hidden;
	transition: background-color .1s;
	-webkit-overflow-scrolling: touch
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
	background: rgba(0, 0, 0, .4)
}

.swal2-container.swal2-backdrop-hide {
	background: 0 0!important
}

.swal2-container.swal2-bottom-start,
.swal2-container.swal2-center-start,
.swal2-container.swal2-top-start {
	grid-template-columns: minmax(0, 1fr) auto auto
}

.swal2-container.swal2-bottom,
.swal2-container.swal2-center,
.swal2-container.swal2-top {
	grid-template-columns: auto minmax(0, 1fr) auto
}

.swal2-container.swal2-bottom-end,
.swal2-container.swal2-center-end,
.swal2-container.swal2-top-end {
	grid-template-columns: auto auto minmax(0, 1fr)
}

.swal2-container.swal2-top-start>.swal2-popup {
	align-self: start
}

.swal2-container.swal2-top>.swal2-popup {
	grid-column: 2;
	align-self: start;
	justify-self: center
}

.swal2-container.swal2-top-end>.swal2-popup,
.swal2-container.swal2-top-right>.swal2-popup {
	grid-column: 3;
	align-self: start;
	justify-self: end
}

.swal2-container.swal2-center-left>.swal2-popup,
.swal2-container.swal2-center-start>.swal2-popup {
	grid-row: 2;
	align-self: center
}

.swal2-container.swal2-center>.swal2-popup {
	grid-column: 2;
	grid-row: 2;
	align-self: center;
	justify-self: center
}

.swal2-container.swal2-center-end>.swal2-popup,
.swal2-container.swal2-center-right>.swal2-popup {
	grid-column: 3;
	grid-row: 2;
	align-self: center;
	justify-self: end
}

.swal2-container.swal2-bottom-left>.swal2-popup,
.swal2-container.swal2-bottom-start>.swal2-popup {
	grid-column: 1;
	grid-row: 3;
	align-self: end
}

.swal2-container.swal2-bottom>.swal2-popup {
	grid-column: 2;
	grid-row: 3;
	justify-self: center;
	align-self: end
}

.swal2-container.swal2-bottom-end>.swal2-popup,
.swal2-container.swal2-bottom-right>.swal2-popup {
	grid-column: 3;
	grid-row: 3;
	align-self: end;
	justify-self: end
}

.swal2-container.swal2-grow-fullscreen>.swal2-popup,
.swal2-container.swal2-grow-row>.swal2-popup {
	grid-column: 1/4;
	width: 100%
}

.swal2-container.swal2-grow-column>.swal2-popup,
.swal2-container.swal2-grow-fullscreen>.swal2-popup {
	grid-row: 1/4;
	align-self: stretch
}

.swal2-container.swal2-no-transition {
	transition: none!important
}

.swal2-popup {
	display: none;
	position: relative;
	box-sizing: border-box;
	grid-template-columns: minmax(0, 100%);
	width: 100%;
	max-width: 28em;
	border: none;
	border-radius: 5px;
	background: #fff;
	color: #545454;
	padding: 2rem;
    border-radius: 0.42rem;
}

.swal2-popup.swal2-loading {
	overflow-y: hidden
}

.swal2-title {
    position: relative;
    max-width: 100%;
    color: inherit;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
    font-weight: 600;
    font-size: 1.2rem;
    margin: 0 0 15px 0;
    color: #2b2b2b;
}

.swal2-actions {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	// padding: 0 1.2rem 1.2rem 1.2rem
}

.swal2-loader {
	display: none;
	align-items: center;
	justify-content: center;
	width: 2.2em;
	height: 2.2em;
	margin: 0 1.875em;
	-webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
	animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
	border-width: .25em;
	border-style: solid;
	border-radius: 100%;
	border-color: #2778c4 transparent #2778c4 transparent
}

.swal2-styled {
	margin: .3125em;
	padding: .625em 1em;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-weight: 500
}

.swal2-styled:not([disabled]) {
	cursor: pointer
}

.swal2-styled.swal2-confirm {
	border: 0;
	border-radius: .25em;
	background: initial;
	background-color: #ff4051;
	color: #fff;
	font-size: 1em
}

.swal2-styled.swal2-deny {
	border: 0;
	border-radius: .25em;
	background: initial;
	background-color: #dc3741;
	color: #fff;
	font-size: 1em
}

.swal2-styled.swal2-cancel {
	border: 0;
	border-radius: 3px;
	background: initial;
	background-color: #e4e6ef;
	color: #545454;
	font-size: 1em;
}

.swal2-styled::-moz-focus-inner {
	border: 0
}

.swal2-footer {
	justify-content: center;
	margin: 1em 0 0;
	padding: 1em 1em 0;
	border-top: 1px solid #eee;
	color: inherit;
	font-size: 1em
}

.swal2-timer-progress-bar-container {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	grid-column: auto!important;
	overflow: hidden;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px
}

.swal2-timer-progress-bar {
	width: 100%;
	height: .25em;
	background: rgba(0, 0, 0, .2)
}

.swal2-image {
	max-width: 100%;
	margin: 1em auto 0em;
    width: 50px;
}

.swal2-close {
	z-index: 2;
	align-items: center;
	justify-content: center;
	width: 1.2em;
	height: 1.2em;
	margin-top: 10px;
	margin-right: 10px;
	padding: 0;
	overflow: hidden;
	transition: color .1s, box-shadow .1s;
	border: none;
	border-radius: 5px;
	background: 0 0;
	color: #ccc;
	font-family: serif;
	font-size: 2em;
	cursor: pointer;
	right: 0;
	top: 0;
	justify-self: end;
	position: absolute
}

.swal2-close:hover {
	transform: none;
	background: 0 0;
	color: #545454
}

.swal2-close::-moz-focus-inner {
	border: 0
}

.swal2-html-container {
	z-index: 1;
	justify-content: center;
    margin-bottom: 1rem;
    margin-top: 0rem;
	text-align: center;
    font-size: 1.1rem;
	word-wrap: break-word;
	word-break: break-word
}

.swal2-checkbox,
.swal2-file,
.swal2-input,
.swal2-radio,
.swal2-select,
.swal2-textarea {
	margin: 1em 2em 3px
}

.swal2-file,
.swal2-input,
.swal2-textarea {
	box-sizing: border-box;
	width: auto;
	transition: border-color .1s, box-shadow .1s;
	border: 1px solid #d9d9d9;
	border-radius: .1875em;
	background: inherit;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .06), 0 0 0 3px transparent;
	color: inherit;
	font-size: 1.125em
}

.swal2-file.swal2-inputerror,
.swal2-input.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
	border-color: #f27474!important;
	box-shadow: 0 0 2px #f27474!important
}

.swal2-range {
	margin: 1em 2em 3px;
	background: #fff
}

.swal2-range input {
	width: 80%
}

.swal2-range output {
	width: 20%;
	color: inherit;
	font-weight: 600;
	text-align: center
}

.swal2-range input,
.swal2-range output {
	height: 2.625em;
	padding: 0;
	font-size: 1.125em;
	line-height: 2.625em
}

.swal2-input {
	height: 2.625em;
	padding: 0 .75em
}

.swal2-file {
	width: 75%;
	margin-right: auto;
	margin-left: auto;
	background: inherit;
	font-size: 1.125em
}

.swal2-textarea {
	height: 6.75em;
	padding: .75em
}

.swal2-select {
	min-width: 50%;
	max-width: 100%;
	padding: .375em .625em;
	background: inherit;
	color: inherit;
	font-size: 1.125em
}

.swal2-checkbox,
.swal2-radio {
	align-items: center;
	justify-content: center;
	background: #fff;
	color: inherit
}

.swal2-checkbox label,
.swal2-radio label {
	margin: 0 .6em;
	font-size: 1.125em
}

.swal2-checkbox input,
.swal2-radio input {
	flex-shrink: 0;
	margin: 0 .4em
}

.swal2-input-label {
	display: flex;
	justify-content: center;
	margin: 1em auto 0
}

.swal2-validation-message {
	align-items: center;
	justify-content: center;
	margin: 1em 0 0;
	padding: .625em;
	overflow: hidden;
	background: #f0f0f0;
	color: #545454;
	font-size: 1em;
	font-weight: 300
}

.swal2-validation-message::before {
	content: "!";
	display: inline-block;
	width: 1.5em;
	min-width: 1.5em;
	height: 1.5em;
	margin: 0 .625em;
	border-radius: 50%;
	background-color: #f27474;
	color: #fff;
	font-weight: 600;
	line-height: 1.5em;
	text-align: center
}

.swal2-icon {
	position: relative;
	box-sizing: content-box;
	justify-content: center;
	margin: 1.5em auto 0;
	border: .25em solid transparent;
	border-radius: 50%;
	border-color: #000;
	font-family: inherit;
	line-height: 5em;
	transform: scale(0.8);
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.swal2-icon .swal2-icon-content {
	display: flex;
	align-items: center;
	font-size: 2.75em
}

.swal2-icon.swal2-error {
	border-color: #f27474;
	color: #f27474
}

.swal2-icon.swal2-error .swal2-x-mark {
	position: relative;
	flex-grow: 1
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
	display: block;
	position: absolute;
	top: 2.3125em;
	width: 2.9375em;
	height: .3125em;
	border-radius: .125em;
	background-color: #f27474
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
	left: 1.0625em;
	transform: rotate(45deg)
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
	right: 1em;
	transform: rotate(-45deg)
}

.swal2-icon.swal2-error.swal2-icon-show {
	-webkit-animation: swal2-animate-error-icon .5s;
	animation: swal2-animate-error-icon .5s
}

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
	-webkit-animation: swal2-animate-error-x-mark .5s;
	animation: swal2-animate-error-x-mark .5s
}

.swal2-icon.swal2-warning {
	border-color: #ffa800;
	color: #ffa800
}

.swal2-icon.swal2-warning.swal2-icon-show {
	-webkit-animation: swal2-animate-error-icon .5s;
	animation: swal2-animate-error-icon .5s
}

.swal2-icon.swal2-warning.swal2-icon-show .swal2-icon-content {
	-webkit-animation: swal2-animate-i-mark .5s;
	animation: swal2-animate-i-mark .5s
}

.swal2-icon.swal2-info {
	border-color: #9de0f6;
	color: #3fc3ee
}

.swal2-icon.swal2-info.swal2-icon-show {
	-webkit-animation: swal2-animate-error-icon .5s;
	animation: swal2-animate-error-icon .5s
}

.swal2-icon.swal2-info.swal2-icon-show .swal2-icon-content {
	-webkit-animation: swal2-animate-i-mark .8s;
	animation: swal2-animate-i-mark .8s
}

.swal2-icon.swal2-question {
	border-color: #c9dae1;
	color: #87adbd
}

.swal2-icon.swal2-question.swal2-icon-show {
	-webkit-animation: swal2-animate-error-icon .5s;
	animation: swal2-animate-error-icon .5s
}

.swal2-icon.swal2-question.swal2-icon-show .swal2-icon-content {
	-webkit-animation: swal2-animate-question-mark .8s;
	animation: swal2-animate-question-mark .8s
}

.swal2-icon.swal2-success {
	border-color: #6bc259;
	color: #6bc259
}

.swal2-icon.swal2-success .swal2-success-fix {
	position: absolute;
	z-index: 1;
	top: .5em;
	left: 1.625em;
	width: .4375em;
	height: 5.625em;
	transform: rotate(-45deg)
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
	display: block;
	position: absolute;
	z-index: 2;
	height: .3125em;
	border-radius: .125em;
	background-color: #a5dc86
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
	top: 2.875em;
	left: .8125em;
	width: 1.5625em;
	transform: rotate(45deg)
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
	top: 2.375em;
	right: .5em;
	width: 2.9375em;
	transform: rotate(-45deg)
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
	-webkit-animation: swal2-animate-success-line-tip .75s;
	animation: swal2-animate-success-line-tip .75s
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
	-webkit-animation: swal2-animate-success-line-long .75s;
	animation: swal2-animate-success-line-long .75s
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
	-webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
	animation: swal2-rotate-success-circular-line 4.25s ease-in
}

.swal2-progress-steps {
	flex-wrap: wrap;
	align-items: center;
	max-width: 100%;
	margin: 1.25em auto;
	padding: 0;
	background: inherit;
	font-weight: 600
}

.swal2-progress-steps li {
	display: inline-block;
	position: relative
}

.swal2-progress-steps .swal2-progress-step {
	z-index: 20;
	flex-shrink: 0;
	width: 2em;
	height: 2em;
	border-radius: 2em;
	background: #2778c4;
	color: #fff;
	line-height: 2em;
	text-align: center
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
	background: #2778c4
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step {
	background: #add8e6;
	color: #fff
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step~.swal2-progress-step-line {
	background: #add8e6
}

.swal2-progress-steps .swal2-progress-step-line {
	z-index: 10;
	flex-shrink: 0;
	width: 2.5em;
	height: .4em;
	margin: 0 -1px;
	background: #2778c4
}

[class^=swal2] {
	-webkit-tap-highlight-color: transparent
}

.swal2-show {
	-webkit-animation: swal2-show .3s;
	animation: swal2-show .3s
}

.swal2-hide {
	-webkit-animation: swal2-hide .15s forwards;
	animation: swal2-hide .15s forwards
}

.swal2-noanimation {
	transition: none
}

.swal2-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll
}

.swal2-rtl .swal2-close {
	margin-right: initial;
	margin-left: 0
}

.swal2-rtl .swal2-timer-progress-bar {
	right: 0;
	left: auto
}


.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
	overflow: hidden
}

.swal2-height-auto {
	height: auto!important
}

.swal2-no-backdrop .swal2-container {
	background-color: transparent!important;
	pointer-events: none
}

.swal2-no-backdrop .swal2-container .swal2-popup {
	pointer-events: all
}

.swal2-no-backdrop .swal2-container .swal2-modal {
	box-shadow: 0 0 10px rgba(0, 0, 0, .4)
}

.swal2-toast-shown .swal2-container {
	box-sizing: border-box;
	width: 360px;
	max-width: 100%;
	background-color: transparent;
	pointer-events: none
}

.swal2-toast-shown .swal2-container.swal2-top {
	top: 0;
	right: auto;
	bottom: auto;
	left: 50%;
	transform: translateX(-50%)
}

.swal2-toast-shown .swal2-container.swal2-top-end,
.swal2-toast-shown .swal2-container.swal2-top-right {
	top: 0;
	right: 0;
	bottom: auto;
	left: auto
}

.swal2-toast-shown .swal2-container.swal2-top-left,
.swal2-toast-shown .swal2-container.swal2-top-start {
	top: 0;
	right: auto;
	bottom: auto;
	left: 0
}

.swal2-toast-shown .swal2-container.swal2-center-left,
.swal2-toast-shown .swal2-container.swal2-center-start {
	top: 50%;
	right: auto;
	bottom: auto;
	left: 0;
	transform: translateY(-50%)
}

.swal2-toast-shown .swal2-container.swal2-center {
	top: 50%;
	right: auto;
	bottom: auto;
	left: 50%;
	transform: translate(-50%, -50%)
}

.swal2-toast-shown .swal2-container.swal2-center-end,
.swal2-toast-shown .swal2-container.swal2-center-right {
	top: 50%;
	right: 0;
	bottom: auto;
	left: auto;
	transform: translateY(-50%)
}

.swal2-toast-shown .swal2-container.swal2-bottom-left,
.swal2-toast-shown .swal2-container.swal2-bottom-start {
	top: auto;
	right: auto;
	bottom: 0;
	left: 0
}

.swal2-toast-shown .swal2-container.swal2-bottom {
	top: auto;
	right: auto;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%)
}

.swal2-toast-shown .swal2-container.swal2-bottom-end,
.swal2-toast-shown .swal2-container.swal2-bottom-right {
	top: auto;
	right: 0;
	bottom: 0;
	left: auto
}

.swal_animate__animated {
	-webkit-animation-duration: .3s;
	animation-duration: .3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}

.swal_animate__fadeInDown {
	-webkit-animation-name: swal_fadeInDown;
	animation-name: swal_fadeInDown
}

.swal_animate__fadeOutUp {
	-webkit-animation-name: swal_fadeOutUp;
	animation-name: swal_fadeOutUp
}


.swal2-close {
    z-index: 2;
    align-items: center;
    justify-content: center;
    width: 1.2em;
    height: 1.2em;
    margin-top: 10px;
    margin-right: 10px;
    padding: 0;
    overflow: hidden;
    transition: color .1s, box-shadow .1s;
    border: none;
    border-radius: 5px;
    background: 0 0;
    color: #ccc;
    font-family: serif;
    font-size: 2em;
    cursor: pointer;
    right: 0;
    top: 0;
    justify-self: end;
    position: absolute;
}
}

@-webkit-keyframes swal_fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -50px, 0);
		transform: translate3d(0, -50px, 0)
	}
	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}
}

@keyframes swal_fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -50px, 0);
		transform: translate3d(0, -50px, 0)
	}
	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}
}


@-webkit-keyframes swal2-toast-show {
	0% {
		transform: translateY(-.625em) rotateZ(2deg)
	}
	33% {
		transform: translateY(0) rotateZ(-2deg)
	}
	66% {
		transform: translateY(.3125em) rotateZ(2deg)
	}
	100% {
		transform: translateY(0) rotateZ(0)
	}
}

@keyframes swal2-toast-show {
	0% {
		transform: translateY(-.625em) rotateZ(2deg)
	}
	33% {
		transform: translateY(0) rotateZ(-2deg)
	}
	66% {
		transform: translateY(.3125em) rotateZ(2deg)
	}
	100% {
		transform: translateY(0) rotateZ(0)
	}
}

@-webkit-keyframes swal2-toast-hide {
	100% {
		transform: rotateZ(1deg);
		opacity: 0
	}
}

@keyframes swal2-toast-hide {
	100% {
		transform: rotateZ(1deg);
		opacity: 0
	}
}

@-webkit-keyframes swal2-toast-animate-success-line-tip {
	0% {
		top: .5625em;
		left: .0625em;
		width: 0
	}
	54% {
		top: .125em;
		left: .125em;
		width: 0
	}
	70% {
		top: .625em;
		left: -.25em;
		width: 1.625em
	}
	84% {
		top: 1.0625em;
		left: .75em;
		width: .5em
	}
	100% {
		top: 1.125em;
		left: .1875em;
		width: .75em
	}
}

@keyframes swal2-toast-animate-success-line-tip {
	0% {
		top: .5625em;
		left: .0625em;
		width: 0
	}
	54% {
		top: .125em;
		left: .125em;
		width: 0
	}
	70% {
		top: .625em;
		left: -.25em;
		width: 1.625em
	}
	84% {
		top: 1.0625em;
		left: .75em;
		width: .5em
	}
	100% {
		top: 1.125em;
		left: .1875em;
		width: .75em
	}
}

@-webkit-keyframes swal2-toast-animate-success-line-long {
	0% {
		top: 1.625em;
		right: 1.375em;
		width: 0
	}
	65% {
		top: 1.25em;
		right: .9375em;
		width: 0
	}
	84% {
		top: .9375em;
		right: 0;
		width: 1.125em
	}
	100% {
		top: .9375em;
		right: .1875em;
		width: 1.375em
	}
}

@keyframes swal2-toast-animate-success-line-long {
	0% {
		top: 1.625em;
		right: 1.375em;
		width: 0
	}
	65% {
		top: 1.25em;
		right: .9375em;
		width: 0
	}
	84% {
		top: .9375em;
		right: 0;
		width: 1.125em
	}
	100% {
		top: .9375em;
		right: .1875em;
		width: 1.375em
	}
}

@-webkit-keyframes swal2-show {
	0% {
		transform: scale(.7)
	}
	45% {
		transform: scale(1.05)
	}
	80% {
		transform: scale(.95)
	}
	100% {
		transform: scale(1)
	}
}

@keyframes swal2-show {
	0% {
		transform: scale(.7)
	}
	45% {
		transform: scale(1.05)
	}
	80% {
		transform: scale(.95)
	}
	100% {
		transform: scale(1)
	}
}

@-webkit-keyframes swal2-hide {
	0% {
		transform: scale(1);
		opacity: 1
	}
	100% {
		transform: scale(.5);
		opacity: 0
	}
}

@keyframes swal2-hide {
	0% {
		transform: scale(1);
		opacity: 1
	}
	100% {
		transform: scale(.5);
		opacity: 0
	}
}

@-webkit-keyframes swal2-animate-success-line-tip {
	0% {
		top: 1.1875em;
		left: .0625em;
		width: 0
	}
	54% {
		top: 1.0625em;
		left: .125em;
		width: 0
	}
	70% {
		top: 2.1875em;
		left: -.375em;
		width: 3.125em
	}
	84% {
		top: 3em;
		left: 1.3125em;
		width: 1.0625em
	}
	100% {
		top: 2.8125em;
		left: .8125em;
		width: 1.5625em
	}
}

@keyframes swal2-animate-success-line-tip {
	0% {
		top: 1.1875em;
		left: .0625em;
		width: 0
	}
	54% {
		top: 1.0625em;
		left: .125em;
		width: 0
	}
	70% {
		top: 2.1875em;
		left: -.375em;
		width: 3.125em
	}
	84% {
		top: 3em;
		left: 1.3125em;
		width: 1.0625em
	}
	100% {
		top: 2.8125em;
		left: .8125em;
		width: 1.5625em
	}
}

@-webkit-keyframes swal2-animate-success-line-long {
	0% {
		top: 3.375em;
		right: 2.875em;
		width: 0
	}
	65% {
		top: 3.375em;
		right: 2.875em;
		width: 0
	}
	84% {
		top: 2.1875em;
		right: 0;
		width: 3.4375em
	}
	100% {
		top: 2.375em;
		right: .5em;
		width: 2.9375em
	}
}

@keyframes swal2-animate-success-line-long {
	0% {
		top: 3.375em;
		right: 2.875em;
		width: 0
	}
	65% {
		top: 3.375em;
		right: 2.875em;
		width: 0
	}
	84% {
		top: 2.1875em;
		right: 0;
		width: 3.4375em
	}
	100% {
		top: 2.375em;
		right: .5em;
		width: 2.9375em
	}
}

@-webkit-keyframes swal2-rotate-success-circular-line {
	0% {
		transform: rotate(-45deg)
	}
	5% {
		transform: rotate(-45deg)
	}
	12% {
		transform: rotate(-405deg)
	}
	100% {
		transform: rotate(-405deg)
	}
}

@keyframes swal2-rotate-success-circular-line {
	0% {
		transform: rotate(-45deg)
	}
	5% {
		transform: rotate(-45deg)
	}
	12% {
		transform: rotate(-405deg)
	}
	100% {
		transform: rotate(-405deg)
	}
}

@-webkit-keyframes swal2-animate-error-x-mark {
	0% {
		margin-top: 1.625em;
		transform: scale(.4);
		opacity: 0
	}
	50% {
		margin-top: 1.625em;
		transform: scale(.4);
		opacity: 0
	}
	80% {
		margin-top: -.375em;
		transform: scale(1.15)
	}
	100% {
		margin-top: 0;
		transform: scale(1);
		opacity: 1
	}
}

@keyframes swal2-animate-error-x-mark {
	0% {
		margin-top: 1.625em;
		transform: scale(.4);
		opacity: 0
	}
	50% {
		margin-top: 1.625em;
		transform: scale(.4);
		opacity: 0
	}
	80% {
		margin-top: -.375em;
		transform: scale(1.15)
	}
	100% {
		margin-top: 0;
		transform: scale(1);
		opacity: 1
	}
}

@-webkit-keyframes swal2-animate-error-icon {
	0% {
		transform: rotateX(100deg);
		opacity: 0
	}
	100% {
		transform: rotateX(0);
		opacity: 1
	}
}

@keyframes swal2-animate-error-icon {
	0% {
		transform: rotateX(100deg);
		opacity: 0
	}
	100% {
		transform: rotateX(0);
		opacity: 1
	}
}

@-webkit-keyframes swal2-rotate-loading {
	0% {
		transform: rotate(0)
	}
	100% {
		transform: rotate(360deg)
	}
}

@keyframes swal2-rotate-loading {
	0% {
		transform: rotate(0)
	}
	100% {
		transform: rotate(360deg)
	}
}

@-webkit-keyframes swal2-animate-question-mark {
	0% {
		transform: rotateY(-360deg)
	}
	100% {
		transform: rotateY(0)
	}
}

@keyframes swal2-animate-question-mark {
	0% {
		transform: rotateY(-360deg)
	}
	100% {
		transform: rotateY(0)
	}
}

@-webkit-keyframes swal2-animate-i-mark {
	0% {
		transform: rotateZ(45deg);
		opacity: 0
	}
	25% {
		transform: rotateZ(-25deg);
		opacity: .4
	}
	50% {
		transform: rotateZ(15deg);
		opacity: .8
	}
	75% {
		transform: rotateZ(-5deg);
		opacity: 1
	}
	100% {
		transform: rotateX(0);
		opacity: 1
	}
}

@keyframes swal2-animate-i-mark {
	0% {
		transform: rotateZ(45deg);
		opacity: 0
	}
	25% {
		transform: rotateZ(-25deg);
		opacity: .4
	}
	50% {
		transform: rotateZ(15deg);
		opacity: .8
	}
	75% {
		transform: rotateZ(-5deg);
		opacity: 1
	}
	100% {
		transform: rotateX(0);
		opacity: 1
	}
}
@-webkit-keyframes swal_fadeOutUp {
	from {
		opacity: 1
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -50px, 0);
		transform: translate3d(0, -50px, 0)
	}
}

@keyframes swal_fadeOutUp {
	from {
		opacity: 1
	}
	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -50px, 0);
		transform: translate3d(0, -50px, 0)
	}
}
