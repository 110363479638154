.ugc-modal.modal {
    top: 54px;
    width: calc(100% - 200px);
    left: 200px;
    z-index: 96;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}
.ugc-modal .modal-body {
    height: calc(100vh - 97px);
    overflow-y: auto;
}

.ugc_steps label {
    text-transform: capitalize;
}
.ugc-modal .modal-content{
    border-radius: 0;
}
.ugc-modal .modal-content .nav-link {
    padding: 15px 0;
    display: flex;
    align-items: center;
    margin: 0 auto;
    position: relative;
    width: 100%;
    justify-content: center;
}

.ugc-modal .modal-content .nav-link:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid transparent;
}
.ugc-modal .modal-content .nav-link.active:after,
.ugc-modal .modal-content .active_li .nav-link:after{
    border-bottom: 2px solid #ff4051;
}

.ugc-modal .nav.nav-tabs .nav-item {
    margin-right: 0;
    width: 33.33%;
}

.ugc-modal .modal-dialog{
    max-width: 100%;
    margin: 0;
}

.ugc_col_right {
    max-width: 500px;
    flex: 1 0 500px;
}

.ugc-modal .modal-header {
    height: 60px;
}
.aside-fixed.aside-minimize .ugc-modal.modal,
.aside-fixed.aside-minimize-hover .ugc-modal.modal {
    width: calc(100% - 70px);
    left: 70px;
}
.moderation_action .btn.getrights_btn{
    background-color: #1a2029;
    color: #fff;
    font-weight: 500;
}
.moderation_action .btn.getrights_btn span svg{
    height: 16px;
}
.moderation_action .filter-bar button span{
    margin-right: 5px;
}
.modSelectNo .label.label-xl{
    font-size: 12px;
}
.modal-dialog-scrollable.modal_100{
    max-height: 100%;
}
.m-w-100{
    max-width: 100% !important;
    width: 100%;
}
#sync1.carousel-theme,
.ugc_modal_pending_first_box .ugc_card_wrap{
    position: relative;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
}
.ugc_close.btn-close {
    padding: 20px;
    font-size: 18px;
    opacity: .7;
    margin-right: -24px;
}

.ugc_close.btn-close:focus, .ugc_close.btn-close:active, .ugc_close.btn-close:hover {
    outline: none;
}
#sync2{
    max-width: 470px;
    margin: 0px auto;
    overflow-x: auto;
    padding: 10px 0px;
    margin-bottom: 20px;
}
#sync2 .stage .item + .item{
    margin-left: 10px;
}
#sync2 .ugc-thumbnail-inn{
    width: 65px;
    height: 65px;
    position: relative;
}
#sync2 .ugc-thumbnail-inn img{
    width: 100%;
    border-radius: 5px;
    height: 100%;
    object-fit: cover;
}
/* .ugc-thumbnail-inn .remove-thumbnail{
    position: absolute;
    right: -5px;
    top: -5px;
    display: none;
} */
/* .ugc-thumbnail-inn:hover .remove-thumbnail{
    display: block;
} */
/* .ugc-thumbnail-inn .remove-thumbnail img{
    width: 20px !important;
} */
.m_media_holder{
    position: relative;
    z-index: 2;
    width: 100%;
    background: rgb(227, 227, 227);
}
.card_slider .m_card_img img.ex-coverimg {
    height: auto;
    max-width: 350px;
    object-fit: contain;
    object-position: center center;
}
#sync1.carousel-theme .carousel-control{
    z-index: 3;
    opacity: 1;
    background: rgb(242, 242, 242);
    border-radius: 50%;
    box-shadow: rgb(208 208 208) 0px 2px 5px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px !important;
    height: 50px !important;
    top: 50%;
    transform: translate(0%, -50%);
}
#sync1.carousel-theme .carousel-control-next{
    right: -70px;
}
#sync1.carousel-theme .carousel-control-prev{
    left: -70px;
}
#sync1.carousel-theme .carousel-control span{
    background-image: none;
}
#ugcstatusUGC{
    z-index: 999;
}
#ugcstatusUGC .nav-link img {
    height: 20px;
    opacity: 0.5;
    margin-right: 5px;
    filter: grayscale(100);
    /* padding-left: 15px; */
}

.dropdown-option .btn:hover span svg path {
    fill: #545454;
}

#ugcstatusUGC .nav-link.active img,
#ugcstatusUGC .nav-item:visited .nav-link img,
#ugcstatusUGC .active_li .nav-link span img {
    filter: grayscale(0);
    opacity: 1;
}

#ugcstatusUGC .cardStyleSec .nav-tabs .nav-item {
    margin: 0;
    width: 33.33%;
    text-align: center;
    position: relative;
}

#ugcstatusUGC .nav .nav-link.active {
    position: relative;
}

#ugcstatusUGC .nav .show>.nav-link,
#ugcstatusUGC .nav .nav-link:hover:not(.disabled),
#ugcstatusUGC .nav .nav-link.active {
    border-color: transparent;
    color: #545454;
    height: 100%;
}

#ugcstatusUGC .nav .nav-link {
    border-radius: 0;
}

#ugcstatusUGC .ugc-modal .nav .nav-link{
    border-color: transparent;
    color: #2b2b2b;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.ugc-modal.disabled .nav .nav-link{
    pointer-events: none;
}

#ugcstatusUGC .cardStyleSec .nav-tabs,
#ugcstatusUGC .cardStyleSec .tab-content {
    padding-left: 0px;
    padding-right: 0px;
    vertical-align: middle;
}
.ugc_right_col p button i{
    font-size: 12px;
}
.temp-logo-image .brand_logo_file img {
    /* width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center; */
     max-width: 150px;
    max-height: 80px;
    height: auto;
    width: auto;
}
.brand_logo_file .removelogoicon{
    position: absolute;
    right: 10px;
    /* top: 10px; */
    z-index: 1;
    cursor: pointer;
}
#ugcstatusUGC .thumbnail>img {
    margin-left: inherit;
    margin-right: inherit;
    max-width: 80px;
}
span.ugc-brandimg-name {
    padding: 5px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.clearterms {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    background: #F3F6F9;
    display: block;
    height: 30px;
    padding: 5px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: normal;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 130px);
    text-overflow: ellipsis;
}
.clearterms .removeterms {
    font-size: 14px;
    color: #666666;
    height: 30px;
    width: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #F3F6F9;
}

.ugc-tncdownload span img, .ugc-download img {
    width: 15px;
    margin-left: 10px;
}

.ugc-tncdownload a span:first-child {
    max-width: 250px;
    display: inline-block;
}
.ugc-templates .dropdown-menu li{
    display: flex;
    justify-content: space-between;
}
.ugc-templates .dropdown-menu li .dropdown-option{
    display: none;
}
.ugc-templates .dropdown-menu li:hover .dropdown-option{
    display: block;
}
.ugc-templates .dropdown-menu li span a i{
    font-size: 12px;
    color: #ccc;
}
.ugc-loggac-row {
    margin-bottom: 20px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}
.ugc-loggac-row .ugc-rowrap {
    padding: 15px 30px;
    border-radius: 4px;
    box-shadow: 0 0 5px #f2f2f2;
    border: 1px solid #ffffff;
}
.ugc-loog-left>span {
    margin-right: 10px;
    flex-shrink: 0;
}
.ugc-loog-left>span img {
    height: 34px;
    border-radius: 50%;
}
.ugc-loog-left h3 {
    font-size: 14px;
    margin: 0;
}
.ugc-loog-right img {
    height: 24px;
}
.ugc-loog-right{
    padding-left: 5px;
    flex-shrink: 0;
}
.modal_sec_right .disabled img {
    filter: grayscale(100);
    opacity: 0.7;
}

.ugc-pending-url .ugc-req-title {
    text-align: center;
    margin-bottom: 20px;
}
.ugc-pending-url .ugc-req-title h2 {
    color: #2b2b2b;
    font-size: 18px;
    margin: 0 0 10px;
}
.ugc-pending-url .ugc-req-title span {
    color: #a2a2a2;
    font-size: 12px;
    display: block;
}
.ugc-pending-url textarea {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
    background: #F3F6F9;
    width: 100%;
    border: 1px solid #e4e6ef;
    border-radius: 4px;
    resize: none;
    height: 110px;
    padding: 10px;
}
.ugc-stepstoproceed .ugc-togglesteps {
    color: #000;
}
.ugc-stepstoproceed .ugc-togglesteps i {
    font-size: 14px;
    margin-left: 5px;
    vertical-align: middle;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.ugc-arrowrotate i {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.ugc-stepstoproceed ul {
    padding: 15px;
    margin: 10px 0 0;
}
.ugc-stepstoproceed ul li {
    position: relative;
    padding-left: 20px;
}
.ugc-stepstoproceed ul li:not(:last-child) {
    margin-bottom: 15px;
}
.ugc-stepstoproceed ul li::before {
    content: "\f111";
    font-family: TaggboxSocialFonts!important;
    font-weight: 900;
    font-size: 8px;
    color: #ff4051;
    position: absolute;
    left: 0;
    top: 7px;
}
.text-success {
    color: #6ac259 !important;
}

.m_getugc_post {
    border:0px
}
.ugc-tncdownload {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.ugc-tncdownload a {
    color: #2b2b2b;
}

.ugc-tncdownload a span {
    display: inline-block;
    vertical-align: middle;
}
.ugc-information .ugc-deviceupdate img {
    height: 22px;
}
.m_getugc_post.ugc_status_moderation_panel_pending {
    background-color: #FF8100;
}
.m_getugc_post.ugc_status_moderation_panel_Reject{
    background-color: #DB4B20;
}
.play_icon_wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.play_icon_wrap {
    max-width: 55px;
    margin: 0 auto;
}
.card_slider .pay_icon {
    max-width: 60px !important;
}
.ugc-daysremain {
    position: relative;
}
.ugc-daysremain span {
    position: absolute;
    top: auto;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: inline-block;
    text-align: center;
    bottom: 40%;
    color: #959595;
    font-size: 18px;
}
#kt_mixed_widget_14_chart .apexcharts-datalabels-group text {
    fill: #000;
    font-weight: normal;
    font-size: 24px;
}
#socialAccountLogin{
    position: relative;
    z-index: 2;
}
#socialAccountLogin:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: #00000047;
}
.m_grid_get_rights,
.m_grid_get_rights:hover{
    background-color: #000 !important;
    border-radius: 4px !important;
    color: #fff !important;
}
.m_ugc_post{
    /* top: 0px; */
    display: flex;
    align-items: end;
    flex-wrap: wrap;
    overflow: hidden;
}

.brand_logo_file span {
    max-width: 115px;
    overflow: hidden;
    padding: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.remove-thumbnail {
    cursor: pointer;
    background-color: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    width: 16px;
    height: 16px;
    text-indent: 0;
    text-align: center;
    justify-content: center;
    position: absolute;
    right: -6px;
    top: -6px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.ugc-thumbnail-inn .remove-thumbnail i {
    font-size: 7px;
    color: #fff;
    margin: 0;
    padding: 0;
}

.ugc-thumbnail-inn:hover .remove-thumbnail {
    opacity: 1;
    visibility: visible;
}

.symbol.symbol-60>img {
    width: 100%;
    max-width: 100px;
    height: 100px;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #1e2125;
    background-color: #e9ecef;
}

.ugc_media_holder {
    position: relative;
}

.play_icon_wrap img {
    width: 100%;
}

.bootbox button.btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 15px;
    transition: all 0.3s;
    padding: 8px;
}

.btn.btn-success path {
    fill: #fff !important;
}

.removetermsicon {
    font-size: 11px !important;
    color: #545454;
    height: 20px;
    width: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #f3f6f9;
    margin-left: 5px;
}


@media (max-width: 767px) {
    .modal-dialog-scrollable .modal-body.ugc_steps{
        min-height: auto;
    }    
}
@media (max-width: 576px) {
    .moderation_action .btn.getrights_btn span svg{
        height: 12px;
    }
    #sync1.carousel-theme .carousel-control{
        width: 40px !important;
        height: 40px !important;
    }
    #sync1.carousel-theme .carousel-control-prev{
        left: 10px;
    }
    #sync1.carousel-theme .carousel-control-next{
        right: 10px;
    }
    .apexcharts-canvas{
        width: 100% !important;
    }
}


.ugc-previewcont {
    background: #F8F8F8;
    border-radius: 4px;
    position: relative;
    min-height: 150px;
}

.click_ugc_account{
    background: #F8F8F8;
}
.ugc-previewcont img {
    object-fit: contain;
    object-position: center;
}

.popover_content_body textarea {
    min-height: 160px;
}

.dropdown-menu.ugc-popover {
    width: 500px;
}

.dropdown-toggle.btn.ugc-reject-btn:after, .dropdown-toggle.btn.ugc-approve-btn:after{
    display: none;
}

.btn.btn-danger.focus:not(.btn-text), .btn.btn-danger:focus:not(.btn-text), .btn.btn-danger:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #fff;
    background-color: #c43d05;
    border-color: #c43d05;
}

.text-danger .svg-icon svg g [fill]{
    fill: #f64e60;
}
.text-success .svg-icon svg g [fill]{
    fill: #6ac259;
}
/* .ugc-previewcont::after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 30px 14px 0;
    border-color: transparent #f2f2f2 transparent transparent;
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
} */

.ugc_card_preview .m_card_img {
    height: auto;
    max-height: 100%;
}

.ugc-reqcard.card {
    border: 2px solid transparent;
    border-radius: 18px;
    box-shadow: 0px 5px 10px #e5e5e5;
}
.previewcont p{
    font-size: 1.35rem;
}
.ugc-reqcard.card.ugc-req-approved {
    border-color: #6ac259;
}

.ugc-reqcard.card.ugc-req-rejected {
    border-color: #df613b;
}
.ugc-reqwrap {
    max-width: 1024px;
    margin: 0 auto;
}

.ugc_social_icon i {
    font-size: 26px;
}

.ugc-btn-reject, .ugc-btn-approve {
    min-width: 125px;
}
.ugc_card_preview .m_post_title {
    max-width: 100%;
}

.ugc_card_preview .m_grid_author span {
    max-width: 45%;
}

.ugc_card_preview .m_user_content {
    max-width: 90%;
    flex-grow: 1;
}

.ugc_card_preview .m_grid_author {
    flex-grow: 1;
}

.ugc_card_preview .m_grid_userinfo{
    max-width: 100%;
}

.card_slider .m_social_icon i {
    font-size: 32px;
}



#ugcPostSlider {
    max-width: 650px;
    margin: 0 auto;
    width: 100%;
}



.ugc_post_title,
.ugc_list_post_title {
    font-size: 16px;
    color: #2b2b2b;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 215px;
}

.ugc_grid_userinfo{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 215px;
}

.ugc_user_content {
    max-width: 90%;
}

.ugc_post_title:hover,
.ugc_list_post_title:hover {
    color: #2b2b2b;
}
.ugc_grid_author span {
    line-height: 14px;
    font-size: 12px;
    color: #959595;
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
}

span.sepratedot {
    height: 4px;
    width: 4px;
    background-color: #959595;
    border-radius: 50%;
    margin: 0 5px;
    display: table;
    align-self: center;
}

.ugc_card_cont .symbol.symbol-circle {
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: 22px;
    padding: 5px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #535353;
    color: #fff;
    display: block;
}


.carousel-inner {
    max-width: 500px;
    margin: 0 auto;
}

.ugc_container {
    max-width: 780px;
    margin: 0 auto;
}

.terms-condition-box {
    max-height: 360px;
    overflow-y: auto;
}
.card_slider .m_card_img img.ex-coverimg {
    height: auto;
    object-fit: contain;
    object-position: center center;
    max-height: 400px;
    max-width: 400px;
}
@media screen and (max-width: 1600px) and (min-width: 1367px) {
    .add-feed-modal .col-xxl-8 {
        max-width: 80%;
        flex: 0 0 80%;
    }
    .add-feed-modal .add-feed-modal-body-inner {
        max-width: 520px;
    }
}

@media (max-width: 1440px) {
    #ugcPostSlider {
        max-width: 500px;
    }
    .carousel-inner {
        max-width: 400px;
    }
    
    .card_slider .m_card_img img.ex-coverimg {
        max-height: 400px;
        max-width: 400px;
    }

}
@media (max-width: 991px) {
    .ugc-modal.modal {
        height: calc(100vh - 55px);
        width: 100%;
        left: 0;
        top: 55px;
    }
    .ugc_modal_pending_second_box{
        height: auto;
        overflow: hidden;
    }
    .pay_details{
        height: calc(100vh - 105px);
        overflow-y: auto;
    }
    .walls_screen, .account_info_wrap {
        height: calc(100vh - 55px);
        overflow-y: auto;
    }
    .add-feed-modal .add-feed-modal-body {
        height: calc(100vh - 112px);
    }
    .add-feed-modal .themesNowThumbs ul li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .dual_subheader_content.no_subheader_archive {
        padding-top: 12.5px;
    }
    ul.networks li a {
        height: 120px;
        width: 120px;
        padding: 15px 0 0 0;
    }
    ul.networks li a img {
        width: 60px;
    }
    ul.networks li a span {
        line-height: 16px;
    }
    ul.networks {
        padding: 0;
        text-align: center;
    }
    ul.networks li button {
        height: 100px;
        width: 100px;
    }
    ul.networks li button img {
        width: 42px;
    }
    ul.networks li button span {
        font-size: 12px;
    }
    .dynamic-popup .cardStyleSec .modal-footer {
        margin-left: -24px;
        margin-right: -24px;
        position: relative;
        width: calc(100% + 48px);
        bottom: 0;
        right: 0;
        left: 0;
    }
    .hashtag_preview {
        margin-top: 0px;
    }
}

@media (max-width: 767px) {

    .ugc-previewcont, .click_ugc_account {
        flex-direction: column;
    }

    .ugc_info img{
        margin-bottom: 15px;
    }
    .click_ugc_account span{
        margin-bottom: 15px;
        text-align: center;
    }
    .add-feed-modal .themesNowThumbs ul li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .ugc-btn-reject, .ugc-btn-approve {
        min-width: 100px;
        padding: 8px 0;
    }
    .add-feed-modal .add-feed-modal-body-inner {
        padding: 30px 0;
    }
    .previewcont p{
        font-size: 1.1rem;
    }
    .ugc-previewcont::after {
        border-width: 8px 20px 8px 0;
    }
}

@media (max-width: 576px) {
    .add-feed-modal .themesNowThumbs ul li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}


/* add feed modal css ends here */

@media (max-width: 480px) {
    #ugcstatusUGC .cardStyleSec .nav-tabs .nav-item {
        padding: 0 10px;
        margin: 0;
        width: 100%;
        text-align: left;
    }
    #ugcstatusUGC .ugc-modal .nav .nav-link {
        justify-content: start;
    }
    
}

.slider_thumb img {
    height: 70px;
    width: 70px;
    display: flex;
    object-fit: cover;
    object-position: center;
}

.slider_thumb {
    border-radius: 4px;
    overflow: hidden;
}

.slide_thumbs_btn .btn{
    opacity: .6;
}

.slide_thumbs_btn .btn:hover{
    opacity: 1;
}

.slide_thumbs_btn .btn:hover .remove-thumbnail{
    visibility: visible;
    opacity: 1;
}

.slide_thumbs_btn .btn.active{
    opacity: 1;
}

.remove-thumbnail {
    cursor: pointer;
    background-color: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    width: 16px;
    height: 16px;
    text-indent: 0;
    text-align: center;
    justify-content: center;
    position: absolute;
    right: -6px;
    top: -6px;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.btn span.remove-thumbnail i {
    font-size: 7px;
    color: #fff;
    margin: 0;
    padding: 0;
}

.ugc_arrow_ico i {
    font-size: 24px;
    color: #545454;
}

.ugc_arrow_btn{
    width: 40px;
    height: 40px;
    z-index: 3;
    opacity: 1;
    border-radius: 50%;
    top:calc(50% - 20px);
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 2px 4px rgb(0 0 0 / 4%), 0 5px 14px rgb(0 0 0 / 2%);
    box-shadow: 0 1px 2px rgb(0 0 0 / 6%), 0 2px 4px rgb(0 0 0 / 4%), 0 5px 14px rgb(0 0 0 / 2%);
}


.carousel-indicators {
    position: relative;
    margin-right: 0;
    margin-left: 0;
}

.carousel-indicators .btn {
    box-sizing: content-box;
    flex: inherit;
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: none;
    border-bottom: none;
    opacity: .5;
    transition: opacity .6s ease;
}

.ugc-thumbnail-inn .remove-thumbnail{
    position: absolute;
    right: -5px;
    top: -5px;
    display: none;
}
.ugc-thumbnail-inn:hover .remove-thumbnail{
    display: block;
}

.ugc_steps{
    padding: 15px 25px;
    min-height: calc(100vh - 238px);
}

.ugc_steps.ugc_extention_modal{
    min-height: calc(100vh - 197px);
}
/* =====new css========= */
.ugc_steps .comment-box i{
    font-size: 12px;
}
.ugc_steps .comment-box span.p_tooltip:after{
    min-width: 26em;
    font-size: 12px;
}
.select-handle-row{
    overflow: auto;
    max-height: 185px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    scrollbar-color: white white;
    scrollbar-width: none;
}

.conect-user-inner svg {
    margin-right: 5px;
    width: 50px;
    flex-shrink: 0;
    height: 50px;
}

.ugc-disabled .ugc_steps, .ugc-disabled .modal-footer .btn-secondary, .ugc-disabled .modal-footer .btn-primary {
    opacity: .6;
    pointer-events: none;
}

.select-handle-row.ugc_account_full_view{
    max-height: 100%;
}

.select-handle-row.ugc_account_full_view + .scroller-down-link svg{
    transform: rotate(180deg);
}
.select-handle-row .ugc-loggac-row{
    margin: 0 auto;
    max-width: 100%;
    width: 50%;
    margin-bottom: 0px;
    margin: 5px;
}
.select-handle-row .ugc-loggac-row .ugc-rowrap{
    padding: 5px 15px;
    border: 1px solid #e4e6ef;
    box-shadow: none;
}
.select-handle-row .ugc-loggac-row .select-row-input{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}
.select-handle-row .ugc-loggac-row .radio-label svg{
    display: none;
    position: absolute;
    top: -5px;
    left: -5px;
    transform: scale(0.84);
    height: 24px;
    width: 24px;
}

.select-handle-row .ugc-loggac-row .radio-label svg path{
    fill:#61b136;
}
.select-handle-row .ugc-loggac-row .select-row-input ~ .ugc-rowrap .radio-label{
    border: 1px solid #404040;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0;
    position: relative;
}
.select-handle-row .ugc-loggac-row .select-row-input:checked ~ .ugc-rowrap .radio-label svg{
    display: block;
}
.select-handle-row .ugc-loggac-row .select-row-input:checked ~ .ugc-rowrap{
    border-color: #56b3468a;
}
.select-handle-row .ugc-loggac-row .select-row-input:checked ~ .ugc-row-disabled .ugc-loog-right img{
    filter: inherit;
    opacity: 1;
}
.select-handle-row .ugc-loog-left span img{
    height: 38px;
}
#contact-md .ugc-pending-url textarea{
    height: 150px;
}
.ugc-loggac-box{
    margin: 0px -5px;
    flex-wrap: wrap;
}
.ugc-loggac-box .ugc-loggac-row{
    max-width: 100%;
    width: 100%;
    padding: 5px;
    margin-bottom: 0px;
}
.ugc-loggac-box .ugc-loggac-row .ugc-rowrap{
    border-color: #DCDDDF;
    padding: 10px 30px;
}
.add-new-handle{
    display: block;
    width: 100%;
    padding: 5px;
}
.add-new-handle .add-new{
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border: 1px solid #DCDDDF;
    border-radius: 5px;
}
.add-new-handle .add-new span{
    font-size: 30px;
    height: 24px;
    display: inline-block;
    line-height: 18px;
    vertical-align: middle;
}
.select-handle-scroll .scroller-down-link{
    text-align: center;
    cursor: pointer;
    position: relative;
    display: none;
}
.select-handle-scroll.overflow .scroller-down-link{
    display: block;
}
.ugc-rowrap .d-flex {
    width: calc(100% - 25px);
}
.select-handle-scroll .scroller-down-link svg{
    width: 20px;
    height: 20px;
    position: relative;
}
#selectHandleList::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #fff;
}

#selectHandleList::-webkit-scrollbar
{
    width: 0px;
    background-color: #fff;
}

#selectHandleList::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #fff;
}
.extension-error{
    padding: 1.75rem;
    margin: 0px;
    padding-bottom: 0px;
}

.extension-error .conect-user{
    padding: 15px;
    border: 1px solid #ebedf3;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #e3e3e3;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.extension-error .conect-user .conect-user-inner{
    display: flex;
    align-items: center;
}
.conect-user .btn-extension{
    margin-left: 15px;
}
.ugc-loog-left p{
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}
@media (max-width: 1600px){
    .select-handle-row .ugc-loggac-row,
    .ugc-loggac-box .ugc-loggac-row{
        width: 100%;
    }
    .ugc-loggac-box .ugc-loggac-row .ugc-rowrap{
        padding: 10px 15px;
    }
    .select-handle-row .ugc-loog-left span img {
        height: 34px;
    }
    .select-handle-row .ugc-loggac-row .ugc-rowrap {
        padding: 8px 15px;
    }
    .ugc-loog-right img {
        height: 18px;
    }
    #ugcModal .nav-link img {
        margin-right: 2px;
    }
}

@media (max-width: 1200px){
    .ugc_col_right {
        max-width: 100%;
        flex: 1 0 100%;
    }
    .ugc-loog-left p {
        max-width: 100%;
    }
    .ugc-body-inner{
        flex-direction: column;
    }
    .ugc_steps, .ugc_steps.ugc_extention_modal{
        min-height: auto;
    }
}
@media (max-width: 991px){
    .ugc-modal .modal-header {
        height: 50px;
    }
    .ugc_close.btn-close {
        padding: 16px;
        font-size: 16px;
        opacity: .7;
        margin-right: -24px;
    }
    .ugc-modal.modal .svg-icon-col svg {
        width: 28px;
        height: 28px;
    }
    .ugc-modal .modal-body {
        height: calc(100vh - 105px);
    }
    .ugc_steps,
    .modal_sec_right .modal-footer {
        padding: 15px 15px;
    }
}
@media (max-width: 580px){
    .ugc_steps .comment-box span.p_tooltip:after{
        min-width: 14em;
    }
    .select-handle-row .ugc-loggac-row,
    .ugc-loggac-box .ugc-loggac-row,
    .select-handle-row .ugc-loggac-row{
        width: 100%;
    }
    .extension-error{
        padding: 1rem;
    }
    .ugc-peingdone .video_support_link span {
        display: none;
    }
    .extension-error .conect-user .conect-user-inner {
        flex-direction: column;
        justify-content: center;
    }
    .extension-error .conect-user {
        flex-direction: column;
    }
    .conect-user-inner p {
        text-align: center;
    }

    .conect-user .btn-extension {
        margin-left: 0;
        margin-top: 15px;
    }
    .ugc-modal .modal-content .nav-link {
        padding: 15px 15px;
        justify-content: start;
    }
    .ugc-modal .nav.nav-tabs .nav-item {
        width: 100%;
    }
}
